/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MessageIcon } from 'src/assets/icons/messages.svg';
import { useEmit } from 'src/shared/useEmit';
import { EventBusEvent } from 'src/components/EventBus/events';
import { SecondaryButton } from 'src/components/Buttons/SecondaryButton';

export const ChatButton = ({ userId, skipIcon = false }) => {
	const { t } = useTranslation();

	const openChatConversation = useEmit(EventBusEvent.ChatOpen, {
		userId,
	});

	return (
		<SecondaryButton darker data-test="chat-button" onClick={openChatConversation}>
			{!skipIcon && <MessageIcon />}
			{t('message')}
		</SecondaryButton>
	);
};
