/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled, { css } from 'styled-components';
import { Tab as SemanticTab } from 'semantic-ui-react';
import { media } from 'src/_helpers/media-queries';
import { H700, h500, h600, h700, h800, t100 } from 'src/components/Typography';
import { sideSection } from 'src/containers/UserNetwork/styled';
import { Link } from 'src/components/Link/Link';
import { TooltipContent } from 'src/components/Tooltip/Tooltip';
import { ReactComponent as BadgeSVG } from 'src/assets/icons/badge_icon.svg';

const topCover = css`
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 123px;
`;

const modal = css`
	width: unset;
	height: inherit;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 24px 32px;

	${media.xl`
		padding: 32px 40px;
	`}
`;

export const ProfileCard = styled.div`
	position: relative;
	overflow: hidden;
	padding: 20px 16px 16px;
	background: var(--neutral-900);
	border-radius: var(--bigger-border-radius);
	isolation: isolate;
	${sideSection}

	&.modal {
		${modal}
	}

	${media.ll`
		padding: 24px;
	`}

	${media.xl`
		padding: 43px 40px 40px;
	`}

	&::after {
		${topCover}
		background: linear-gradient(0, var(--neutral-900) 0%, transparent 727.64%);
		z-index: -1;
	}

	&::before {
		${topCover}
		background: ${props => props.$color};
		z-index: -1;
	}
`;

export const ProfileHeader = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const DetailsHeader = styled(H700)`
	color: var(--text-color-default);
	padding: 32px 0;
	border-top: 1px solid var(--neutral-700);
`;

export const AvatarWrapper = styled.div`
	.large {
		> div {
			width: 12px;
			height: 12px;
			box-shadow: 0 0 0 2px var(--neutral-900);
		}

		${media.ll`
			width: 60px;
			height: 60px;
			line-height: 60px;
			font-size: 20px;

			> div {
				width: 16px;
				height: 16px;
			}
		`}

		${media.xl`
			width: 80px;
			height: 80px;
			line-height: 80px;
			font-size: 26px;

			> div {
				width: 20px;
				height: 20px;
			}
		`}
	}

	.modal & .large {
		width: 60px;
		height: 60px;
		line-height: 60px;
		font-size: 20px;

		${media.xl`
			width: 80px;
			height: 80px;
			line-height: 80px;
			font-size: 26px;
		`}
	}
`;

export const UserName = styled.h2`
	color: var(--text-color-default);
	font-weight: 400;
	${h700}
	margin: 16px 0 4px;
	display: flex;
	align-items: center;

	span {
		white-space: nowrap;
		max-width: 300px;
	}

	${media.ll`
		margin: 24px 0 8px;
		font-size: 20px;
		line-height: 23px;
	`}

	${media.xl`
		margin: 26px 0 8px;
		${h800}
	`}

	.modal & {
		margin: 20px 0 8px;

		${media.xl`
			margin: 26px 0 8px;
		`}
	}
`;

export const UserCompany = styled.span`
	margin: 0;
	font-size: 12px;
	line-height: 18px;
	display: -webkit-box;
	line-clamp: 2;
	-webkit-line-clamp: 2;
	box-orient: vertical;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-align: center;

	${media.ll`
		font-size: 14px;
		line-height: 16px;
	`}

	${media.xl`
		font-size: 15px;
		line-height: 18px;
	`}
`;

export const UserCompanyName = styled.span`
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
`;

export const UserInfo = styled.div`
	color: var(--neutral-300);
	padding-bottom: 16px;
	overflow: hidden;
	display: flex;
	flex-direction: ${({ isOverflowed }) => (isOverflowed ? 'column' : 'row')};
	align-items: center;
	justify-content: center;

	& [data-test='user-info-company-name'] {
		${({ $hardBreak }) =>
			$hardBreak &&
			css`
				word-break: break-all;
			`})}
	}


	${media.ll`
		padding-bottom: 24px;
	`}

	${media.xl`
		padding-bottom: 32px;
	`}
`;

export const UserCompanyCountry = styled.span`
	display: inline-block;
	margin-left: 8px;
	&::before {
		content: '•';
		margin-right: 8px;
	}
`;

export const ProfileBody = styled.div`
	padding: 16px 0 40px;

	${media.ll`
		padding: 24px 0 29px;
	`}

	${media.xl`
		padding: 32px 0 36px;
	`}

	.modal & {
		flex: 1;
	}
`;

export const DetailsRow = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 8px;
	font-size: 12px;
	line-height: 18px;

	${media.ll`
		margin-bottom: 16px;
		font-size: 13px;
	`}

	${media.xl`
		margin-bottom: 16px;
		font-size: 14px;
		line-height: 21px;
	`}

	label {
		color: var(--text-color-secondary);
		display: flex;
		align-items: center;
		gap: 5px;
		width: 110px;

		.statistics & {
			width: 50%;
		}
	}

	div {
		text-align: right;
		color: var(--text-color-default);
		width: calc(100% - 115px);

		.statistics & {
			width: 50%;
		}
	}

	.modal & {
		margin-bottom: 16px;
		font-size: 13px;

		${media.xl`
			font-size: 14px;
		`}
	}
`;

export const Hidden = styled.div`
	filter: blur(5px);
	user-select: none;
	white-space: nowrap;
`;

const baseButton = css`
	flex: unset;
	height: 36px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	border-radius: 8px;
	font-size: 14px;
	line-height: 16px;
	color: var(--text-color-default);
	cursor: pointer;
	user-select: none;
	width: 122px;

	svg {
		color: var(--text-color-default);
	}

	&:hover {
		color: var(--text-color-default);
	}

	${media.ll`
		width: 127px;
		height: 40px;
	`}

	${media.xl`
		width: 172px;
	`}
`;

const otcButton = css`
	${baseButton}
	background: var(--neutral-600);

	&:hover {
		background: var(--neutral-500);
		color: var(--white);
	}

	&:active,
	&.active,
	&:focus {
		background: var(--neutral-500);
		color: var(--white);
	}
`;

const disabledButton = css`
	${otcButton}
	color: var(--neutral-300);
	pointer-events: none;
`;

export const OTCButton = styled.a`
	${otcButton}
`;

export const OTCLink = styled(Link)`
	${otcButton}
`;

export const OTCspan = styled.span`
	${disabledButton}
`;

export const InvitationSpan = styled(OTCspan)`
	width: 100%;
`;

export const ButtonsContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 16px;

	& svg {
		color: var(--white) !important;
	}

	& .disabled-otc-button,
	.pending-invitation-button {
		width: 100%;
	}

	& button,
	a {
		max-width: 100%;
		width: 100%;
		height: 40px;
		color: var(--text-color-default);
		border: 0;
		border-radius: 8px;
		font-size: 14px;
		line-height: 16px;
		color: var(--text-color-default);
		cursor: pointer;
		user-select: none;

		&.message {
			background: var(--primary-400);
			&:hover {
				background: var(--primary-200);
			}

			&:active {
				background: var(--primary-600);
			}
		}
	}
`;

export const Actions = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

export const Tab = styled(SemanticTab)`
	.ui.tabular.menu {
		margin-bottom: 16px;

		${media.ll`
			margin-bottom: 24px;
		`}

		.modal & {
			margin-bottom: 24px;
		}

		.item {
			width: 50%;
			height: 38px;
			border-left: 0;
			border-right: 0;
			padding: 0;
			margin-right: 0;
			justify-content: center;
			${h600}
			font-size: 13px;

			.modal & {
				height: 48px;
				font-size: 15px;
			}

			${media.ll`
				height: 48px;
				font-size: 15px;
			`}

			&.active {
				&:after {
					content: '';
					position: absolute;
					inset: 0;
					width: 100%;
					height: 100%;
					background: linear-gradient(
						0,
						var(--neutral-900) 0%,
						var(--neutral-900) 0.01%,
						rgb(55 60 218 / 10%) 100%
					);
					z-index: -1;
				}
			}
		}
	}
`;

export const Statistics = styled.div`
	color: var(--text-color-secondary);
	${h500}
	line-height: 18px;
	display: none;

	${media.ll`
		display: block;
	`}

	.modal & {
		display: block;
	}
`;

export const Summary = styled.div`
	color: var(--primary-200);
	${h500}
	font-weight: 700;
	margin: 16px 0;
`;

export const NoData = styled.span`
	color: var(--neutral-400);
	text-transform: lowercase;
`;

export const TooltipContentComponent = styled(TooltipContent)`
	&.tooltip {
		padding: 12px;

		h3 {
			${h500}
			margin-bottom: 4px;
		}

		div {
			color: var(--text-color-secondary);
			font-size: 11px;
			line-height: 13px;

			${media.ll`
				font-size: 13px;
				line-height: 18px;
			`}

			strong {
				color: var(--text-color-default);
				font-weight: 400;
			}
		}
	}
`;

export const StatsContainer = styled.div`
	min-height: 124px;
`;

export const CountryAbbreviation = styled.span`
	margin-left: 8px;

	&::before {
		content: '•';
		margin-right: 8px;
	}
`;

export const ApprovedContactBadge = styled(BadgeSVG)`
	margin-left: 8px;
	width: 24px;
	height: 24px;
	flex-shrink: 0;

	path {
		fill: var(--white);
	}
`;

export const Description = styled.div`
	${t100}
	color: var(--neutral-300);
	text-align: center;
`;
