/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
	parseDateStringAsUTC,
	formatDateRangeInMonths,
	formatDateRangeShipmentDays,
	Dateformat,
	isFirstDayOfMonth,
	isLastDayOfMonth,
} from 'src/_helpers/date';
import { splitDateIntoLines } from './splitDateIntoLines';
import * as Styled from '../styled';

export const ShipmentPill = ({ order }) => {
	// useTranslation forces component to rerender when the locale changes
	useTranslation();

	const startDate = parseDateStringAsUTC(order.delivery_date_from);
	const endDate = parseDateStringAsUTC(order.delivery_date_to);

	const isMonthsPeriod =
		order.delivery_mode === Dateformat.Months ||
		(isFirstDayOfMonth(startDate) && isLastDayOfMonth(endDate));

	const deliveryDate = isMonthsPeriod
		? formatDateRangeInMonths(startDate, endDate)
		: formatDateRangeShipmentDays(startDate, endDate);

	const dateLines = isMonthsPeriod ? splitDateIntoLines(deliveryDate) : deliveryDate;

	return (
		<Styled.GrayPill data-test="shipment-pill">
			{dateLines.map((line, index) => (
				<span key={index}>{line}</span>
			))}
		</Styled.GrayPill>
	);
};
