/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import { loadableRetry } from '../loadableRetry';
import { ErrorBoundary } from '../ErrorBoundary';

const MyProfile = loadableRetry(() => import('src/containers/MyProfile/MyProfile'), {
	resolveComponent: components => components.MyProfile,
});

const UserProfileInfo = loadableRetry(() => import('src/containers/MyProfile/UserInfo/UserInfo'), {
	resolveComponent: components => components.UserInfo,
});

const UserProfileEdit = loadableRetry(
	() => import('src/containers/MyProfile/UserInfo/UserInfoEdit'),
	{
		resolveComponent: components => components.UserInfoEdit,
	}
);

const UserSettings = loadableRetry(() => import('src/containers/MyProfile/Settings/Settings'), {
	resolveComponent: components => components.Settings,
});

export const userProfileRoutes = {
	path: ROUTES.myProfile,
	element: (
		<ErrorBoundary>
			<MyProfile />
		</ErrorBoundary>
	),
	children: (
		<>
			<Route index element={<Navigate to={ROUTES.myProfileInfo} replace />} />
			<Route path={ROUTES.myProfileInfo} element={<UserProfileInfo />} />
			<Route path={ROUTES.myProfileEdit} element={<UserProfileEdit />} />
			<Route path={ROUTES.myProfileSettings} element={<UserSettings />} />
		</>
	),
};
