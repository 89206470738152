/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
	SectionContainer,
	SectionTitle,
	SectionGrid,
	SectionGridContent,
	SectionGridLabel,
	SectionGridText,
} from '../../styled';
import { IncoType, tEnvironment } from 'src/constants/contract';
import { tSpeciality } from 'src/constants/product';
import { OrderFile } from './OrderFile/OrderFile';
import { CUSTOM_CONTRACT_TERMS } from 'src/constants/contract';
import { OverflowText } from 'src/components/Tooltip/OverflowText';
import { CopyOrderNegotiationLink } from '../../components/CopyOrderNegotiationLink/CopyOrderNegotiationLink';
import * as Styled from './styled';

export const AdditionalInformationSection = ({ order, changes }) => {
	const { t } = useTranslation();

	const isCounter = !!changes;
	const isTraded = !!order.trade_id;

	const createdDate = isTraded
		? moment(order.updated_at).format('D MMMM YYYY, h:mm A zz')
		: order.edited_from
		? moment(order.original_counter_created_at).format('D MMMM YYYY, h:mm A zz')
		: moment(order.created_at).format('D MMMM YYYY, h:mm A zz');

	const updatedDate =
		!order.version || order.version === 1
			? null
			: moment(order.version_created_at).format('D MMMM YYYY, h:mm A zz');

	const optionalFields = [
		'environment',
		'harvest_year',
		'speciality',
		'performance_bond_percent',
		'terms_id',
		'contract_number',
		'_key',
	];
	const numberOfFieldsBeforeTheDate = optionalFields.reduce((sum, fieldName) => {
		if (!!order[fieldName]) {
			return sum + 1;
		}
		return sum;
	}, 0);

	const shouldOverflowDate =
		numberOfFieldsBeforeTheDate === 3 ||
		numberOfFieldsBeforeTheDate === 7 ||
		!!updatedDate ||
		order.edited_from;

	const shouldDisplayEditedDate = !isTraded && (!!updatedDate || order.edited_from);
	const editedDateValue = order.edited_from
		? moment(order.created_at).format('D MMMM YYYY, h:mm A zz')
		: updatedDate;

	const disableCopyingTradeLink = isTraded && order.inco_id === IncoType.RENDU;

	return (
		<SectionContainer data-test="additional-information-section">
			<SectionTitle>{t('additional_information')}</SectionTitle>
			<SectionGrid>
				{!isCounter && (
					<SectionGridContent>
						<SectionGridLabel>{t('environment')}</SectionGridLabel>
						<SectionGridText data-test="additional-information-section-environment">
							{tEnvironment(t, order.environment)}
						</SectionGridText>
					</SectionGridContent>
				)}
				{order.harvest_year && (
					<SectionGridContent className={clsx({ changed: changes?.harvest_year })}>
						<SectionGridLabel>{t('harvest')}</SectionGridLabel>
						<SectionGridText data-test="additional-information-section-harvest">
							{order.harvest_year}
						</SectionGridText>
					</SectionGridContent>
				)}
				{order.speciality && (
					<SectionGridContent className={clsx({ changed: changes?.speciality })}>
						<SectionGridLabel>{t('speciality')}</SectionGridLabel>
						<SectionGridText data-test="additional-information-section-speciality">
							{tSpeciality(t, order.speciality)}
						</SectionGridText>
					</SectionGridContent>
				)}
				{(order.performance_bond_percent || changes?.performance_bond_percent) && (
					<SectionGridContent
						className={clsx({ changed: changes?.performance_bond_percent })}
					>
						<SectionGridLabel>{t('performance_bond')}</SectionGridLabel>
						<SectionGridText data-test="additional-information-section-performance-bond">
							{order.performance_bond_percent
								? `${order.performance_bond_percent}%`
								: '---'}
						</SectionGridText>
					</SectionGridContent>
				)}
				{(order.terms_id || changes?.terms_id) && (
					<SectionGridContent className={clsx({ changed: changes?.terms_id })}>
						<SectionGridLabel>{t('contract_terms')}</SectionGridLabel>
						<SectionGridText data-test="additional-information-section-contract-format">
							{order.terms_id ? (
								<OverflowText>
									{order.terms_id === CUSTOM_CONTRACT_TERMS
										? t('custom')
										: order.terms.name}
								</OverflowText>
							) : (
								'---'
							)}
						</SectionGridText>
					</SectionGridContent>
				)}
				{(order.contract_number || changes?.contract_number) && (
					<SectionGridContent className={clsx({ changed: changes?.contract_number })}>
						<SectionGridLabel>{t('contract_number')}</SectionGridLabel>
						<SectionGridText data-test="additional-information-section-contract-number">
							<OverflowText>{order.contract_number || '---'}</OverflowText>
						</SectionGridText>
					</SectionGridContent>
				)}
				<CopyOrderNegotiationLink
					order={order}
					isCounter={isCounter}
					disableCopyingLink={disableCopyingTradeLink}
				/>
				<SectionGridContent>
					<SectionGridLabel>{isTraded ? t('trade_date') : t('created')}</SectionGridLabel>
					<Styled.CreatedAtText data-test="additional-information-section-created-at">
						{shouldOverflowDate ? (
							<OverflowText>{createdDate}</OverflowText>
						) : (
							createdDate
						)}
					</Styled.CreatedAtText>
				</SectionGridContent>
				{shouldDisplayEditedDate && (
					<SectionGridContent>
						<SectionGridLabel>{t('edited')}</SectionGridLabel>
						<Styled.CreatedAtText data-test="additional-information-section-updated_at">
							<OverflowText>{editedDateValue}</OverflowText>
						</Styled.CreatedAtText>
					</SectionGridContent>
				)}
			</SectionGrid>
			{order.file_contract && (
				<SectionGrid $fullWidth>
					<SectionGridContent>
						<SectionGridLabel></SectionGridLabel>
						<SectionGridText
							data-test="additional-information-section-contract-document"
							$alignSelf="end"
						>
							<OrderFile
								file={order.file_contract}
								key={order.file_contract._id}
								dataTest={`additional-information-document-${order.file_contract._key}`}
								className={clsx({ changed: changes?.file_contract })}
							/>
						</SectionGridText>
					</SectionGridContent>
				</SectionGrid>
			)}
			{!isCounter && order.comment_order && (
				<SectionGrid $fullWidth>
					<SectionGridContent>
						<SectionGridLabel>{t('comment')}</SectionGridLabel>
						<Styled.Comment data-test="additional-information-section-comment">
							{order.comment_order}
						</Styled.Comment>
					</SectionGridContent>
				</SectionGrid>
			)}
		</SectionContainer>
	);
};
