/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { adminMergeTradesIntoString } from 'src/_api/trades.api';
import { useToast } from 'src/components/Toast';
import { TextInput } from 'src/designSystem/Form/TextInput/TextInput';
import { mapIdsToArray } from './helpers';
import * as Styled from './styled';

export const AdminPaperNoString = ({ tradeId }) => {
	const [tradeIds, setTradeIds] = useState(`${tradeId},`);

	const { addToast } = useToast();
	const queryClient = useQueryClient();

	const handleInputChange = e => setTradeIds(e.target.value);

	const { mutate } = useMutation(adminMergeTradesIntoString, {
		onSuccess: async () => {
			await queryClient.invalidateQueries(['trade', tradeId]);
			await queryClient.invalidateQueries(['trade-string', tradeId]);
		},
		onError: error => {
			addToast({
				kind: 'error',
				message: error.message,
			});
		},
	});

	return (
		<div>
			<Styled.Header>Merge trades into a string</Styled.Header>
			<Styled.Info>
				Type list of comma separated trade ids (no spaces, no extra characters)
			</Styled.Info>
			<TextInput value={tradeIds} onChange={handleInputChange} />
			<Styled.PrimaryButton
				fixedPadding
				data-test="button-move-no-string"
				onClick={() => {
					const tradeIdsStrings = mapIdsToArray(tradeIds);

					mutate(tradeIdsStrings);
				}}
			>
				Run
			</Styled.PrimaryButton>
		</div>
	);
};
