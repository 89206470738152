/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useRef } from 'react';
import { Tooltip, TooltipContent, TooltipTrigger } from 'src/components/Tooltip/Tooltip';
import { useIsOverflowed } from 'src/_helpers/useIsOverflowed';
import * as Styled from './styled';

export const OverflowText = ({ children, tooltipTestId, contentText, placement = 'bottom' }) => {
	const textElementRef = useRef();
	const tooltipContent = contentText || children;
	const isOverflowed = useIsOverflowed(textElementRef, tooltipContent);

	return isOverflowed ? (
		<Tooltip placement={placement}>
			<TooltipTrigger>
				<Styled.Container ref={textElementRef} $isOverflowed>
					{children}
				</Styled.Container>
			</TooltipTrigger>
			<TooltipContent data-test={tooltipTestId}>{tooltipContent}</TooltipContent>
		</Tooltip>
	) : (
		<Styled.Container ref={textElementRef} $isOverflowed={isOverflowed}>
			{children}
		</Styled.Container>
	);
};

export const OverflowHeaderCell = ({ headerCellRef, tooltipContent, children }) => {
	const isOverflowed = useIsOverflowed(headerCellRef, children);

	if (!isOverflowed) {
		return children;
	}

	return (
		<Tooltip>
			<TooltipTrigger>{children}</TooltipTrigger>
			<TooltipContent>{tooltipContent}</TooltipContent>
		</Tooltip>
	);
};

export const OverflowArray = ({ texts, tooltipTestId }) => {
	const textElementRef = useRef();
	const isOverflowed = useIsOverflowed(textElementRef, texts[0]);
	const label = texts.length > 1 ? ` +${texts.length - 1}` : '';
	const shouldDisableTooltip = !isOverflowed && !label;

	const tooltipTrigger = (
		<Styled.OverflowArrayWrapper>
			<Styled.Container ref={textElementRef} $isOverflowed={isOverflowed}>
				{texts[0]}
			</Styled.Container>
			<Styled.HighlightLabel $isContentOverflowed={isOverflowed}>
				{label}
			</Styled.HighlightLabel>
		</Styled.OverflowArrayWrapper>
	);

	if (shouldDisableTooltip) {
		return tooltipTrigger;
	}

	return (
		<Tooltip placement="bottom-start">
			<TooltipTrigger>{tooltipTrigger}</TooltipTrigger>
			<TooltipContent tooltipTestId={tooltipTestId}>
				{label ? texts.join(', ') : texts[0]}
			</TooltipContent>
		</Tooltip>
	);
};
