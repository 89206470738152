/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from 'src/assets/icons/remove_item.svg';
import { ReactComponent as ChevronIcon } from 'src/assets/icons/chevron.svg';
import { media } from 'src/_helpers';
import { h400large, h300, H700Bold } from 'src/components/Typography';

export const DrawerHeaderButtonContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 16px;
`;

export const IconWrapper = styled.div`
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 24px;
	height: 24px;
	cursor: pointer;

	& svg {
		color: ${({ $dark }) => ($dark ? 'inherit' : '#fff')};
	}

	&:hover svg {
		color: ${({ $dark }) => ($dark ? 'var(--neutral-500)' : 'var(--neutral-100)')};
	}
`;

export const CloseButton = styled(CloseIcon)`
	width: 14px;
	height: 12px;
	cursor: pointer;
	flex-shrink: 0;
`;

export const MinimizeButton = styled(ChevronIcon)`
	color: white;
	width: 16px;
	height: 16px;
	cursor: pointer;
	flex-shrink: 0;
	animation: fadein 300ms;

	${({ $isMinimized }) => $isMinimized && `transform: rotate(180deg);`}
`;

export const RefreshButton = styled.div`
	background-color: var(--white);
	color: var(--neutral-950);
	${h400large}
	width: 103px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
	border-radius: var(--large-border-radius);
	margin-right: 32px;
	cursor: pointer;

	&:hover {
		background-color: #dcdcdc;
	}
`;

export const DrawerHeader = styled.div`
	padding: 0 20px 0 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: ${({ theme }) => theme.background};
	color: ${({ theme }) => theme.color};
	animation: fadein 300ms;
	position: relative;
	border-top-left-radius: 20px;

	${({ $isMinimized }) =>
		$isMinimized &&
		`
		cursor: pointer;
		height: var(--_minimizedHeight) !important;
	`}

	${media.ll`
		padding: 0 20px;
		border-top-left-radius: 32px;
	`}

	${media.xl`
		padding: 0 30px 0 24px;
	`}


	&.temporary-drawer-header .drawer-header-subtitle {
		color: white !important;
	}
`;

export const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2px;

	${media.ll`
		gap: 4px;
	`}
`;

export const Subtitle = styled.div`
	${h300}
	font-size: 10px;
	color: var(--text-color-secondary);
	max-width: 100%;

	${media.xl`
		font-size: 13px;
	`}
`;

export const Title = styled(H700Bold)`
	display: flex;
	align-items: center;
	gap: 8px;
`;
