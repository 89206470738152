/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import { useEmit } from 'src/shared/useEmit';
import { useWebsocket } from 'src/websockets/useWebsocket';
import { WebsocketChannel, EventBusEvent } from './events';

export const EventBus = () => {
	const emit = useEmit();

	useWebsocket(WebsocketChannel.Private, EventBusEvent.ContactRemoved, message =>
		emit(EventBusEvent.ContactRemoved, message.data.data)
	);

	useWebsocket(WebsocketChannel.Private, EventBusEvent.ContactAdded, message =>
		emit(EventBusEvent.ContactAdded, message.data.data)
	);

	useWebsocket(WebsocketChannel.Private, EventBusEvent.ChatNewMessage, message =>
		emit(EventBusEvent.ChatNewMessage, message.data.data)
	);

	useWebsocket(WebsocketChannel.Private, EventBusEvent.NewNotification, message =>
		emit(EventBusEvent.NewNotification, message.data.data)
	);

	useWebsocket(WebsocketChannel.Private, EventBusEvent.NotificationsRead, message =>
		emit(EventBusEvent.NotificationsRead, message.data.data)
	);

	const orderWithdrawnCallback = message => {
		const { negotiations_withdrawn: negotiationsWithdrawn } = message.data.data;

		if (negotiationsWithdrawn) {
			emit(EventBusEvent.WithdrawnOrderWithNegotiations, message.data.data);
			emit(EventBusEvent.NegotiationUpdated, message.data.data);
		} else {
			emit(EventBusEvent.WithdrawnOrder, message.data.data);
		}

		emit(EventBusEvent.UpdateDashboard, message.data.data);
		emit(EventBusEvent.OrderUpdated, message.data.data);
	};

	useWebsocket(WebsocketChannel.Private, EventBusEvent.WithdrawnOrder, orderWithdrawnCallback);

	useWebsocket(
		WebsocketChannel.ExchangeUpdates,
		EventBusEvent.WithdrawnOrder,
		orderWithdrawnCallback
	);

	useWebsocket(WebsocketChannel.Private, EventBusEvent.NewCounter, message => {
		emit(EventBusEvent.NewCounter, message.data.data);
		emit(EventBusEvent.NegotiationUpdated, message.data.data);
		emit(EventBusEvent.NewSidebarActivities, message.data.data);
		emit(EventBusEvent.MyTabsUpdated, message.data.data);
	});

	useWebsocket(WebsocketChannel.Private, EventBusEvent.NewTrade, message => {
		emit(EventBusEvent.NewTrade, message.data.data);
		emit(EventBusEvent.NegotiationUpdated, message.data.data);
		emit(EventBusEvent.NewSidebarActivities, message.data.data);
		emit(EventBusEvent.MyTabsUpdated, message.data.data);
	});

	useWebsocket(WebsocketChannel.Private, EventBusEvent.EditedOTCOrder, message => {
		emit(EventBusEvent.EditedOrder, message.data.data);
		emit(EventBusEvent.UpdateDashboard, { ...message.data.data, order_edited: true });
		emit(EventBusEvent.OrderUpdated, message.data.data);
	});

	useWebsocket(WebsocketChannel.ExchangeUpdates, EventBusEvent.EditedExchangeOrder, message => {
		emit(EventBusEvent.EditedOrder, message.data.data);
		emit(EventBusEvent.UpdateDashboard, { ...message.data.data, order_edited: true });
		emit(EventBusEvent.OrderUpdated, message.data.data);
	});

	useWebsocket(WebsocketChannel.Private, EventBusEvent.NewOtcOrder, message => {
		emit(EventBusEvent.UpdateDashboard, message.data.data);
		emit(EventBusEvent.MyOrdersUpdated, message.data.data);
	});

	useWebsocket(WebsocketChannel.NewExchangeOrder, EventBusEvent.NewExchangeOrder, message => {
		emit(EventBusEvent.UpdateDashboard, message.data.data);
		emit(EventBusEvent.MyOrdersUpdated, message.data.data);
	});

	useWebsocket(WebsocketChannel.ExchangeUpdates, EventBusEvent.NewTradePrice, message => {
		emit(EventBusEvent.UpdateChart, message.data.data);
	});

	return null;
};
