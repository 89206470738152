/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { tOrderType, OrderType, Role, PaperInstruments } from 'src/constants/contract';
import { getUserId } from 'src/_store/selectors';
import { usePhysicalCounterForm } from './helpers/usePhysicalCounterForm';
import { usePaperCounterForm } from './helpers/paper/usePaperCounterForm';
import { getCounterType } from './helpers/getCounterType';
import { getCounterpartyLabel } from 'src/_helpers/order';
import { CounterPhysicalForm } from './CounterPhysicalForm';
import { CounterPaperForm } from './CounterPaperForm';
import { Market } from 'src/constants/contract';
import { CounterFormHeader } from './components/CounterFormHeader/CounterFormHeader';

export const CounterForm = ({ setCounterForm, lastCounter, order }) => {
	const { t } = useTranslation();
	const userId = useSelector(getUserId);

	const counterType = getCounterType(lastCounter, userId);

	// TODO: Make this hook common for paper and physical
	const {
		formMethods: physicalFormMethods,
		onSubmit: physicalOnSubmit,
		counterPreviewData: counterPhysicalPreviewData,
		isSubmitting: isCounteringPhysical,
	} = usePhysicalCounterForm({
		order,
		lastCounter,
		firstCounterId: lastCounter.first_counter_id,
		setCounterForm,
	});

	const {
		formMethods: paperFormMethods,
		onSubmit: paperOnSubmit,
		counterPreviewData: counterPaperPreviewData,
		isCountering: isCounteringPaper,
	} = usePaperCounterForm({
		order,
		lastCounter,
		negotiationId: lastCounter.first_counter_id,
		setCounterForm,
	});

	const isPreviewMode = !!counterPhysicalPreviewData || !!counterPaperPreviewData;
	const isEditMode = lastCounter.is_editing;
	const isSpread = order.instrument === PaperInstruments.Spread;

	const headerMessage = isPreviewMode
		? isSpread
			? t('calendar_spread_preview')
			: counterType === OrderType.Buy
			? t('preview_counter_bid')
			: t('preview_counter_offer')
		: isEditMode
		? t('edit_counter')
		: t('create_counter_bid_offer', {
				counterType: tOrderType(t, counterType, order.instrument).toLowerCase(),
		  });

	const counterpartyLabel =
		order.role === Role.Broker ? t('broker') : getCounterpartyLabel(t, order);

	const isMyTeamOrder = !order.is_my_order && order.is_my_team_order;

	const counterIndex = lastCounter.counterIndex || 0;
	const shouldDisplayHiddenInfo =
		order.hidden &&
		!order.is_identity_revealed &&
		!lastCounter.is_identity_revealed &&
		!isMyTeamOrder &&
		counterIndex < 2;

	const hiddenInfoSubtitle =
		shouldDisplayHiddenInfo && isPreviewMode
			? order.is_my_order && !order.is_identity_revealed
				? t('counter_submission_hidden_subtitle_order_owner')
				: t('counter_submission_hidden_subtitle', {
						counterparty: counterpartyLabel,
				  })
			: undefined;

	return (
		<>
			<CounterFormHeader
				headerMessage={headerMessage}
				hiddenInfoSubtitle={hiddenInfoSubtitle}
				setCounterForm={setCounterForm}
				order={order}
				lastCounter={lastCounter}
			/>
			{lastCounter.market === Market.Physical ? (
				<CounterPhysicalForm
					setCounterForm={setCounterForm}
					lastCounter={lastCounter}
					isPreviewMode={isPreviewMode}
					formMethods={physicalFormMethods}
					onSubmit={physicalOnSubmit}
					isSubmitting={isCounteringPhysical}
				/>
			) : (
				<CounterPaperForm
					setCounterForm={setCounterForm}
					lastCounter={lastCounter}
					isPreviewMode={isPreviewMode}
					formMethods={paperFormMethods}
					onSubmit={paperOnSubmit}
					isSubmitting={isCounteringPaper}
				/>
			)}
		</>
	);
};
