/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';

export const Wrapper = styled.div`
	.ui.selection.dropdown {
		width: 261px;
		position: relative;
	}

	.select-input label {
		background: var(--neutral-850);
	}

	& label.input-label {
		z-index: 15 !important;
	}
`;
