/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled, { css } from 'styled-components';
import { T100, H600 } from 'src/components/Typography/fonts';
import {
	ModalSubTitle as BaseModalSubTitle,
	ModalTitle as BaseModalTitle,
} from 'src/components/Modal';
import { PrimaryButton } from 'src/components/Buttons/PrimaryButton';

export const Label = styled(T100)`
	color: var(--text-color-secondary);
`;

export const OrderValueRow = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 16px;
`;

export const ModalContent = styled.div`
	position: relative;
	margin-bottom: 40px;
	margin-right: -24px;
	padding-right: 24px;
	max-height: 54vh;
	padding-top: 4px;
	overflow: auto;
`;

export const ModalSubTitle = styled(BaseModalSubTitle)`
	text-align: center;
	margin-bottom: 28px;
`;

export const OverflowedValue = styled(H600)`
	width: 250px;
	text-align: right;
`;

export const CompanyNameContainer = styled.div`
	width: 250px;
	text-align: right;
`;

export const ModalTitle = styled(BaseModalTitle)`
	margin-bottom: 16px;
`;

export const SectionName = styled.div`
	font-size: 17px;
	font-weight: 500;
	line-height: 19.92px;
	letter-spacing: 0.3px;
	margin: 24px 0;
	color: var(--text-color-default);
`;

const tooltipButton = css`
	background-color: var(--neutral-800);
	color: var(--neutral-300);
	box-shadow: none;
	cursor: default;
`;

export const InactiveTooltipAcceptButton = styled(PrimaryButton)`
	${tooltipButton}

	&:hover {
		${tooltipButton}
	}
`;
