/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled, { css } from 'styled-components';
import { ReactComponent as PointerSVG } from 'src/assets/icons/tour-guide-pointer.svg';
import { ReactComponent as CloseSVG } from 'src/assets/icons/close-small.svg';
import { ReactComponent as LogoSVG } from 'src/assets/images/logo-popup.svg';
import { media } from 'src/_helpers';
import { h300, H600, H700, t100 } from 'src/components/Typography';
import { PrimaryButton } from 'src/components/Buttons/PrimaryButton';
import { SlideAttachment } from './constants';

const zIndex = 5000;

const animated = css`
	transition: transform 400ms ease-out;
`;

const text = css`
	color: var(--text-color-default);
	padding: 0;
	margin: 0 0 12px 0;

	${media.xl`
		margin-bottom: 20px;
	`}
`;

const popup = css`
	width: 400px;

	${media.xl`
		width: 462px;
	`}
`;

export const Overlay = styled.div`
	position: absolute;
	inset: 0;
	overflow: hidden;
	z-index: ${zIndex};
	background-color: rgba(0, 0, 0, 0.6);
	mix-blend-mode: hard-light;

	&:has(+ #markets_tour) {
		> div {
			transition: none !important;
		}
	}
`;

export const Spotlight = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	background-color: gray;
	opacity: 1;
	${animated}
`;

export const Container = styled.div`
	--gradient-end-color: #3936ff;
	position: absolute;
	left: 0;
	top: 0;
	${popup}
	opacity: 1;
	border-radius: 8px;
	background: linear-gradient(180deg, var(--primary-200) 0%, var(--gradient-end-color) 100%);
	z-index: ${zIndex + 2};
	visibility: hidden;
	padding: 32px 20px 20px;
	${animated}

	&#markets_tour {
		transition: none;
	}

	${media.xl`
		padding: 40px 24px 24px;
	`}

	&.${SlideAttachment.BottomLeft} {
		.pointer {
			top: -15px;
			left: 24px;
		}
	}

	&.${SlideAttachment.BottomRight} {
		.pointer {
			top: -15px;
			right: 24px;
		}
	}

	&.${SlideAttachment.TopLeft} {
		.pointer {
			bottom: -15px;
			left: 24px;
			transform: rotate(180deg);

			path {
				fill: var(--gradient-end-color);
			}
		}
	}

	&.${SlideAttachment.Left} {
		.pointer {
			top: 24px;
			right: -15px;
			transform: rotate(90deg);
		}
	}

	&.${SlideAttachment.TopLeftInner} {
		.pointer {
			top: -15px;
			left: 24px;
		}
	}
`;

export const CloseButton = styled(CloseSVG)`
	position: absolute;
	top: 16px;
	right: 20px;
	width: 16px;
	height: 16px;
	z-index: ${zIndex + 1};
	opacity: 0.5;
	cursor: pointer;

	${media.xl`
		top: 24px;
		right: 24px;
	`}

	&:hover {
		opacity: 1;
	}
`;

export const Pointer = styled(PointerSVG).attrs({ className: 'pointer' })`
	position: absolute;
	width: 32px;
	height: 32px;
	z-index: ${zIndex + 1};
`;

export const Title = styled(H600)`
	font-weight: 700;
	${text}
`;

export const Text = styled.pre`
	font-family: var(--font-base);
	font-size: 11px;
	font-weight: 400;
	line-height: 18px;
	letter-spacing: 0.2px;
	text-align: left;
	white-space: pre-line;
	opacity: 0.8;
	${text}

	${media.xl`
		font-size: 12px;
	`}
`;

export const Actions = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	button {
		width: 128px;
		height: 36px;
		background: var(--primary-100);
		color: var(--text-color-default);
		border-radius: var(--large-border-radius);
		${h300}

		&:hover {
			background: #6272ff;
		}

		${media.xl`
			height: 40px;
		`}
	}
`;

export const Dots = styled.div`
	display: flex;
	align-items: center;
	gap: 6px;
`;

export const Dot = styled.div`
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background-color: var(--white);
	opacity: 0.5;

	&.current {
		opacity: 1;
	}
`;

export const WelcomePopup = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	opacity: 1;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const PopupContent = styled.div`
	position: relative;
	${popup}
	padding: 24px 20px 32px 20px;
	z-index: ${zIndex + 2};
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 16px;
	background-color: var(--neutral-850);
	background-image: linear-gradient(180deg, rgba(54, 60, 219, 0.2) 0%, rgba(33, 37, 70, 0) 100%),
		radial-gradient(
			77.22% 77.22% at 50% 0%,
			rgba(59, 56, 253, 0.6) 0%,
			rgba(59, 57, 252, 0) 100%
		);

	${media.xl`
		padding: 32px 32px 40px 32px;
	`}
`;

export const LogoImage = styled(LogoSVG)``;

export const Header = styled(H700)`
	font-weight: bold;
	color: var(--text-color-default);
	padding: 0;
	margin: 8px 0 16px;

	${media.xl`
		margin-bottom: 24px;
	`};
`;

export const Info = styled.div`
	color: var(--neutral-100);
	${t100}
	text-align: center;
	padding: 0 10px;
	margin-bottom: 32px;

	${media.xl`
		margin-bottom: 40px;
	`};
`;

export const Button = styled(PrimaryButton)`
	width: 192px;
`;
