/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Pill } from 'src/components/Pill/Pill';
import { isInactiveUser } from 'src/_helpers/isInactiveUser';
import { OverflowText } from 'src/components/Tooltip/OverflowText';
import { H400 } from 'src/components/Typography';
import { AvatarType } from 'src/shared/components/Avatar/Avatar';
import { useMediaQuery, screenSize } from 'src/_helpers/media-queries';
import { PrincipalCompanyAvatarSection } from 'src/components/PrincipalCompanyAvatarSection/PrincipalCompanyAvatarSection';
import { useBrokerPrincipal } from './useBrokerPrincipal';
import { UserProfileLink } from 'src/components/UserProfileModal/UserProfileLink';
import { ContentCell } from '../styled';
import { PaperInstruments, OrderType, Role } from 'src/constants/contract';
import { useOrderDetails } from 'src/_helpers/useOrderDetails';
import { getUserId } from 'src/_store/selectors';
import * as Styled from './styled';

export const OrderCreatorBox = ({ order, changes = {} }) => {
	const { t } = useTranslation();
	const currentUserID = useSelector(getUserId);

	const { isTraded } = useOrderDetails(order);

	const isLargeScreen = useMediaQuery({ 'min-width': screenSize.xl });
	const avatarSize = isLargeScreen ? AvatarType.Large : AvatarType.Medium;

	const { broker, principal, tradedCounterCounterpartySide } = useBrokerPrincipal(order);

	const assetType = tradedCounterCounterpartySide || order.counter_order_type || order.order_type;

	let userSide = assetType === OrderType.Sell ? 'seller' : 'buyer';

	if (order.instrument === PaperInstruments.Spread) {
		userSide = `first_leg_${userSide}`;
	}

	const isGivenUserPrincipal = principal.user?._key === currentUserID;
	const isMyOrderOrCounter = order.user_id === currentUserID;

	const shouldApplyApprovedContactBadge =
		!!principal.user?._key &&
		!isGivenUserPrincipal &&
		!isMyOrderOrCounter &&
		(!!order.is_my_approved_contact_order || !!order.is_my_approved_contact);

	const isPrincipalChanged = changes.order_owner_principal_id || changes.principalVisibility;

	return (
		<ContentCell data-test="order-creator-box">
			<header>{t(userSide)}</header>
			<section>
				<Styled.PrincipalContainer
					className={clsx({
						changed: isPrincipalChanged,
					})}
					$isTraded={isTraded}
				>
					<PrincipalCompanyAvatarSection
						principal={principal}
						broker={broker}
						isTraded={!!order.trade_id}
						size={avatarSize}
						withApprovedContactBadge={shouldApplyApprovedContactBadge}
					/>
				</Styled.PrincipalContainer>
				<Styled.BrokerContainer>
					{!broker ? (
						<Pill>
							<H400 as="span" data-test="order-creator-principal">
								{/* TODO: Hotfix to be handled properly in future; refer to useBrokerPrincipal:34 */}
								{order.role === Role.Broker && order.is_order
									? t('broker')
									: t('principal')}
							</H400>
						</Pill>
					) : (
						<UserProfileLink
							userId={broker.user._key}
							isInactive={isInactiveUser({ status: broker.user.status })}
							hasHoverEffect={false}
							className="full-width"
						>
							<Styled.BrokerUser>
								<label>{t('broker')}</label>
								<Styled.BrokerDetails>
									<div className="content">
										<OverflowText>{broker.company.name}</OverflowText>
									</div>
									<div className="separator">•</div>
									<div className="content">
										<OverflowText>{broker.user.name}</OverflowText>
									</div>
								</Styled.BrokerDetails>
							</Styled.BrokerUser>
						</UserProfileLink>
					)}
				</Styled.BrokerContainer>
			</section>
		</ContentCell>
	);
};
