/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
	getIsCheckingAuth,
	getUserId,
	userAuthLoggedIn,
	userAuthLoggingOut,
	userShowTermsAndConditions,
} from './_store/selectors';
import { Router } from './_routes/router';
import { HeaderMenuWrapper } from './components/Menus/HeaderMenuWrapper';
import { Loader } from 'semantic-ui-react';
import { Dimmer } from 'src/components/Dimmer/Dimmer';
import { SidebarProvider } from './components/PageSidebar/PageSidebar';
import { PersistStoreState } from './components/PersistStoreState/PersistStoreState';
import { FullscreenProvider } from './components/FullscreenImage/FullscreenImage';
import { isHeadlessRoute, isStandaloneRoute } from './constants/routes';
import { CurrentSettingsVersion, CurrentSettingsVersionKey } from './constants/storageKeys';
import { PermissionsContextProvider } from './containers/Permissions/PermissionsContext';
import { WebsocketPresenceWrapper } from './websockets/WebsocketPresenceWrapper';
import { UserStatusPresenceWrapper } from './websockets/UserStatusPresenceWrapper';
import { InviteViaEmailModal } from 'src/containers/UserNetwork/components/Invites/InviteViaEmailCard/InviteViaEmailModal';
import { WebsocketsProvider } from './websockets/WebsocketsProvider';
import { UserPresenceProvider } from './websockets/UserPresenceProvider';
import { MarketProvider } from './_routes/useActiveMarket';
import { ChatLoader } from './chat/hooks/useChatLoader';
import { TermsAndConditions } from './components/TermsAndConditions/TermsAndConditions';
import { MyContactsIdsContextProvider } from './websockets/MyContactsIdsProvider';
import { CompanyNameLoader } from './_helpers/useCompanyNameLoader';
import { DrawerProvider } from './components/Drawer/DrawerContext';
import { CreateOrderDrawer } from './components/CreateOrderDrawer/CreateOrderDrawer';
import { ViewOrderDrawer } from './components/ViewOrderDrawer/ViewOrderDrawer';
import { UnseenActivitiesProvider } from './containers/Dashboard/Footer/MyTabsContext';
import { EditOrdersProvider } from 'src/containers/Dashboard/DashboardGrid/EditOrders/EditOrdersProvider';
import { AppTour } from './containers/AppTour/AppTour';
import { TemporaryApprovedContactsProvider } from './_helpers/useTemporaryApprovedContact';
import { HighlightItemProvider } from './containers/HighlightItemProvider/HighlightItemProvider';
import { BlockNavigationProvider } from './containers/BlockNavigationProvider/BlockNavigationProvider';
import { useAllPaperPresets } from './components/SearchHeader/useAllPaperPresets';
import { EventBus } from './components/EventBus/EventBus';
import { useSettings } from './components/Menus/UserProfileDropdown/useSettings';

export const App = () => {
	const isLoggingOut = useSelector(userAuthLoggingOut);
	const isCheckingAuth = useSelector(getIsCheckingAuth);
	const isLoggedIn = useSelector(userAuthLoggedIn);

	// make sure the tour.json for the selected language is loaded
	useTranslation(['tour']);

	const { pathname } = useLocation();

	useEffect(() => {
		if (isLoggedIn) {
			document.body.classList.add('logged-in');
		} else {
			document.body.classList.remove('logged-in');
		}
	}, [isLoggedIn]);

	useEffect(() => {
		if (isStandaloneRoute(pathname)) {
			document.body.classList.add('standalone');
		} else {
			document.body.classList.remove('standalone');
		}
	});

	useEffect(() => {
		if (window.localStorage.getItem(CurrentSettingsVersionKey) !== CurrentSettingsVersion) {
			window.localStorage.clear();
			window.localStorage.setItem(CurrentSettingsVersionKey, CurrentSettingsVersion);
		}
	}, []);

	if (isLoggingOut || isCheckingAuth) {
		return (
			<Dimmer active fullOpacity>
				<Loader active />
			</Dimmer>
		);
	}

	return <PublicProviders />;
};

const PublicProviders = () => {
	const isLoggedIn = useSelector(userAuthLoggedIn);
	const shouldShowTermsAndConditions = useSelector(userShowTermsAndConditions);

	if (isLoggedIn) {
		return <PrivateProviders />;
	}

	if (shouldShowTermsAndConditions) {
		return <TermsAndConditions />;
	}

	return <Router />;
};

const PrivateProviders = () => {
	const sidebarRef = useRef();
	const fullscreenRef = useRef();
	const drawerRef = useRef();

	const { pathname } = useLocation();
	const userId = useSelector(getUserId);

	Sentry.setUser({
		id: userId,
	});

	// Load paper presets and settings
	useAllPaperPresets();
	const { defaultMarket } = useSettings();

	return (
		<MarketProvider defaultMarket={defaultMarket}>
			<PermissionsContextProvider>
				<WebsocketsProvider>
					<EventBus />
					<UserPresenceProvider>
						<MyContactsIdsContextProvider>
							<BlockNavigationProvider>
								<FullscreenProvider containerRef={fullscreenRef}>
									<HighlightItemProvider>
										<EditOrdersProvider>
											<DrawerProvider containerRef={drawerRef}>
												<SidebarProvider containerRef={sidebarRef}>
													<UnseenActivitiesProvider>
														{!isHeadlessRoute(pathname) && (
															<HeaderMenuWrapper />
														)}
														<ChatLoader />
														{/* TODO: Consider having company name in JWT along with company ID */}
														<CompanyNameLoader />
														<PersistStoreState />
														<InviteViaEmailModal />
														<WebsocketPresenceWrapper />
														<UserStatusPresenceWrapper />
														<TemporaryApprovedContactsProvider>
															<Router isLoggedIn />
														</TemporaryApprovedContactsProvider>
														<ViewOrderDrawer />
														<CreateOrderDrawer />
														<AppTour />
													</UnseenActivitiesProvider>
												</SidebarProvider>
											</DrawerProvider>
										</EditOrdersProvider>
									</HighlightItemProvider>
								</FullscreenProvider>
							</BlockNavigationProvider>
							<div ref={sidebarRef} data-test="sidebar" />
							<div ref={fullscreenRef} data-test="fullscreenImage" />
							<div ref={drawerRef} data-test="drawer" />
						</MyContactsIdsContextProvider>
					</UserPresenceProvider>
				</WebsocketsProvider>
			</PermissionsContextProvider>
		</MarketProvider>
	);
};
