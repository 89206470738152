/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateRange, parseDateStringAsUTC, getNumberOfMonths } from 'src/_helpers/date';
import { futuresContractLabel } from 'src/_helpers/futuresContracts';
import { getCounterpartyLabel, useSellerBuyerSummary } from 'src/_helpers/order';
import { formatPrice } from 'src/_helpers/price';
import { H600 } from 'src/components/Typography';
import { VolumeDisplay } from 'src/components/Volume';
import { tCurrency, PreAdvice, PaperInstruments, PaperPorts } from 'src/constants/contract';
import { tPriceUnit } from 'src/constants/product';
import { useOrderDetails } from 'src/_helpers/useOrderDetails';
import { useAllPaperPresets } from 'src/components/SearchHeader/useAllPaperPresets';

export const usePaperCounterSummary = ({ order, lastCounter, counterFormData, isFOBParanagua }) => {
	const { t } = useTranslation();

	const { isBasisPrice } = useOrderDetails(order);

	const counterpartyLabel = getCounterpartyLabel(t, order);

	const paperPresets = useAllPaperPresets();

	const orderPreset = paperPresets.find(preset => preset._key === order.preset_id);

	const counterData = counterFormData || lastCounter;

	const { seller, buyer, areBrokersFromTheSameCompany } = useSellerBuyerSummary(order, [
		lastCounter,
	]);

	const isParanaguaOutright =
		lastCounter.instrument === PaperInstruments.Outright &&
		lastCounter.primary_ports?.[0]._key === PaperPorts.PARANAGUA;
	const deliveryMonths = getNumberOfMonths(
		lastCounter.delivery_date_from,
		lastCounter.delivery_date_to
	);

	const volumeLabel =
		counterData.runs || (isParanaguaOutright && deliveryMonths > 1)
			? t('total_quantity')
			: t('quantity');

	const summaryData = [
		{
			title: t('seller'),
			value: seller.principal,
			dataTest: 'seller',
		},
		{
			title: t('buyer'),
			value: buyer.principal,
			dataTest: 'buyer',
		},
		seller.broker && {
			title: seller.broker_label,
			value: seller.broker,
			dataTest: 'seller-broker',
		},
		buyer.broker &&
			!areBrokersFromTheSameCompany && {
				title: buyer.broker_label,
				value: buyer.broker,
				dataTest: 'buyer-broker',
			},
		{
			title: t('contract'),
			value: orderPreset.name,
		},
		{
			title: volumeLabel,
			value: (
				<VolumeDisplay
					as={H600}
					value={isFOBParanagua ? counterData.quantity : counterData.volume}
				/>
			),
			dataTest: 'quantity',
		},
		isFOBParanagua && {
			title: t('total_quantity'),
			value: <VolumeDisplay as={H600} value={counterData.volume} />,
			dataTest: 'total-quantity',
		},
		isBasisPrice && {
			title: t('futures_contract'),
			value: `${futuresContractLabel(
				t,
				order.product.futures_contracts,
				order.futures_contract
			)} ${moment(order.futures_contract_date).utc().format('MMM YYYY')}`,
			dataTest: 'futures',
		},
		{
			title: tPriceUnit(t, order.price_unit),
			value: formatPrice(counterData.price, {
				currency: tCurrency(t, order.currency),
			}),
			dataTest: 'price-currency',
		},
		{
			title: t('shipment'),
			value: formatDateRange({
				format: order.delivery_mode,
				startDate: parseDateStringAsUTC(order.delivery_date_from),
				endDate: parseDateStringAsUTC(order.delivery_date_to),
			}),
			dataTest: 'shipment',
		},
		order.pre_advice === PreAdvice.Short && {
			title: t('pre_advice'),
			value: `${t(`pre_advice_${order.pre_advice}`)} ${t('days', {
				count: order.pre_advice_days,
			})}`,
		},
		order.pre_advice === PreAdvice.ToSuitVessel && {
			title: t('pre_advice_to_suit_mv'),
			value: order.pre_advice_vessel_name,
		},
	].filter(Boolean);

	return { summaryData, counterpartyLabel };
};
