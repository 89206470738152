/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useState } from 'react';
import { ReactComponent as NotificationsBellSvg } from 'src/assets/icons/notification_bell_button.svg';
import { useQuery, useQueryClient } from 'react-query';
import { notificationsCount } from 'src/_api';
import { MenuItemWithIndicator } from './MenuItemWithIndicator';
import { defaultNotificationsSettings } from 'src/constants/notifications';
import { useSubscribe } from 'src/shared/useSubscribe';
import { EventBusEvent } from 'src/components/EventBus/events';

export const notificationsCountQuery = ({ queryKey: [key, filters] }) => {
	return notificationsCount(filters);
};

export const NotificationsIndicator = () => {
	const [count, setCount] = useState(0);
	const queryClient = useQueryClient();

	const { isLoading } = useQuery(
		['notifications', { read: false, count: true }],
		notificationsCountQuery,
		{
			...defaultNotificationsSettings,
			onSuccess: count => {
				setCount(count);
			},
		}
	);

	useSubscribe(EventBusEvent.NotificationsRead, message => {
		const readCount = parseInt(message.notifications_read_count);
		setCount(count => count - readCount);
		queryClient.invalidateQueries(['notifications', { read: false }]);
	});

	useSubscribe(EventBusEvent.NewNotification, () => setCount(count => count + 1));

	return (
		<MenuItemWithIndicator
			count={count}
			isLoading={isLoading}
			IconComponent={NotificationsBellSvg}
		/>
	);
};
