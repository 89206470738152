/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createContext, useContext } from 'react';
import { useTogglable } from 'src/_helpers/useTogglable';
import { useEmit } from 'src/shared/useEmit';
import { EventBusEvent } from '../EventBus/events';

export const DrawerContextKeys = {
	viewDrawer: 'viewDrawerState',
	createDrawer: 'createDrawerState',
};

const TemporaryHeaderTimeout = 3000;

export const DrawerContext = createContext({
	containerRef: null,
	myOrderIds: [],
	addMyOrderId: () => {},
	[DrawerContextKeys.viewDrawer]: {
		visibility: [false, {}],
		minimize: [false, {}],
		currentOrderInfo: { orderId: null, environment: null },
		setCurrentOrderInfo: () => {},
		counterPreviewData: null,
		setCounterPreviewData: () => {},
		counterFormEnabled: false,
		enableCounterForm: () => {},
		temporaryHeader: null,
		setViewOrderTemporaryHeader: () => {},
		isCounterConfirmationVisible: false,
		setCounterConfirmationVisible: () => {},
		isCounterFormDirty: false,
		setIsCounterFormDirty: () => {},
	},
	[DrawerContextKeys.createDrawer]: {
		visibility: [false, {}],
		minimize: [false, {}],
		temporaryHeader: null,
		setCreateOrderTemporaryHeader: () => {},
		copyParams: null,
		orderPreviewData: null,
		setOrderPreviewData: () => {},
		orderPreviewIndex: 0,
		setOrderPreviewIndex: () => {},
		otcRecipients: [],
		setOtcRecipients: () => {},
		isConfirmationVisible: false,
		setConfirmationVisible: () => {},
		isMarketChanging: false,
		setIsMarketChanging: () => {},
		isFormDirty: false,
		setIsFormDirty: () => {},
	},
});

export const useDrawerContext = () => useContext(DrawerContext);

const useDrawerState = (openDrawerCallback, closeDrawerCallback) => {
	const visibility = useTogglable();
	const minimize = useTogglable();

	const [temporaryHeader, setTemporaryHeader] = useState(null);
	const [isFormDirty, setIsFormDirty] = useState(false);

	const setDrawerTemporaryHeader = useCallback(header => setTemporaryHeader(header), []);

	useEffect(() => {
		if (temporaryHeader) {
			const clearTemporaryHeader = setTimeout(() => {
				setTemporaryHeader(null);
			}, TemporaryHeaderTimeout);

			return () => {
				clearTimeout(clearTemporaryHeader);
			};
		}
	}, [temporaryHeader]);

	const openDrawer = useCallback(
		params => {
			openDrawerCallback?.(params);
			visibility[1].open();
		},
		[openDrawerCallback, visibility]
	);

	const closeDrawer = useCallback(() => {
		closeDrawerCallback?.();
		visibility[1].close();
	}, [closeDrawerCallback, visibility]);

	return {
		visibility: [visibility[0], { open: openDrawer, close: closeDrawer }],
		minimize,
		temporaryHeader,
		setTemporaryHeader: setDrawerTemporaryHeader,
		isFormDirty,
		setIsFormDirty,
	};
};

export const DrawerProvider = ({ containerRef, children }) => {
	const [
		currentOrderInfo = { orderId: null, environment: null, negotiationId: null },
		setCurrentOrderInfo,
	] = useState(null);
	const [copyParams, setCopyParams] = useState();
	const [orderPreviewData, setOrderPreviewData] = useState();
	const [counterPreviewData, setCounterPreviewData] = useState();
	const [orderPreviewIndex, setOrderPreviewIndex] = useState(0);
	const [isConfirmationVisible, setConfirmationVisible] = useState(false);
	const [isCounterConfirmationVisible, setCounterConfirmationVisible] = useState(false);
	const [isMarketChanging, setIsMarketChanging] = useState(false);
	const [counterFormEnabled, enableCounterForm] = useState(false);
	const [otcRecipients, setOtcRecipients] = useState([]);
	const [isFormDirty, setIsFormDirty] = useState(false);
	const [isCounterFormDirty, setIsCounterFormDirty] = useState(false);

	const [myOrderIds, setMyOrderIds] = useState([]);
	const addMyOrderId = useCallback(id => setMyOrderIds(oldIds => [...oldIds, id]), []);

	const closeChat = useEmit(EventBusEvent.ChatClose);

	// overwrite drawer opening methods to automatically close chat
	const openViewDrawerCallback = useCallback(() => {
		closeChat();
		enableCounterForm(false);
	}, [closeChat]);

	const closeViewDrawerCallback = useCallback(() => {
		setCounterPreviewData(undefined);
		setIsCounterFormDirty(false);
	}, []);

	const openCreateDrawerCallback = useCallback(
		params => {
			closeChat();
			setCopyParams(params);
		},
		[closeChat]
	);

	// when the drawer was minimized and is being closed - restore the state to not minimized
	const closeCreateDrawerCallback = useCallback(() => {
		setOtcRecipients([]);
		setOrderPreviewIndex(0);
		setIsFormDirty(false);
	}, []);

	const { setTemporaryHeader: setViewOrderTemporaryHeader, ...viewDrawerProps } = useDrawerState(
		openViewDrawerCallback,
		closeViewDrawerCallback
	);

	const {
		setTemporaryHeader: setCreateOrderTemporaryHeader,
		...createDrawerProps
	} = useDrawerState(openCreateDrawerCallback, closeCreateDrawerCallback);

	// Unset preview and copy data in a reactive way to prevent the preview from being displayed when during closing the drawer
	useEffect(() => {
		if (!createDrawerProps.visibility[0]) {
			setOrderPreviewData(undefined);
			setCopyParams(null);
		}
	}, [createDrawerProps.visibility]);

	const value = useMemo(
		() => ({
			containerRef,
			myOrderIds,
			addMyOrderId,
			[DrawerContextKeys.viewDrawer]: {
				...viewDrawerProps,
				setViewOrderTemporaryHeader,
				currentOrderInfo,
				setCurrentOrderInfo,
				counterPreviewData,
				setCounterPreviewData,
				counterFormEnabled,
				enableCounterForm,
				isCounterConfirmationVisible,
				setCounterConfirmationVisible,
				isCounterFormDirty,
				setIsCounterFormDirty,
			},
			[DrawerContextKeys.createDrawer]: {
				...createDrawerProps,
				setCreateOrderTemporaryHeader,
				copyParams,
				orderPreviewData,
				setOrderPreviewData,
				orderPreviewIndex,
				setOrderPreviewIndex,
				otcRecipients,
				setOtcRecipients,
				isConfirmationVisible,
				setConfirmationVisible,
				isMarketChanging,
				setIsMarketChanging,
				isFormDirty,
				setIsFormDirty,
			},
		}),
		[
			addMyOrderId,
			containerRef,
			copyParams,
			counterFormEnabled,
			counterPreviewData,
			createDrawerProps,
			currentOrderInfo,
			isConfirmationVisible,
			isCounterConfirmationVisible,
			isCounterFormDirty,
			isFormDirty,
			isMarketChanging,
			myOrderIds,
			orderPreviewData,
			orderPreviewIndex,
			otcRecipients,
			setCreateOrderTemporaryHeader,
			setViewOrderTemporaryHeader,
			viewDrawerProps,
		]
	);

	return <DrawerContext.Provider value={value}>{children}</DrawerContext.Provider>;
};
