/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { Environment, PaperInstruments } from 'src/constants/contract';
import { CopyOrderButton } from 'src/components/CreateOrderDrawer/components/CopyOrderButton/CopyOrderButton';
import { ChatButton } from '../components/ChatButton/ChatButton';
import { useMyContactIds } from 'src/websockets/MyContactsIdsProvider';
import { useOrderDetails } from 'src/_helpers/useOrderDetails';
import { CopyButton } from '../components/common';
import { useMediaQuery, screenSize } from 'src/_helpers';
import { MultiExecutionButton } from '../components/MultiExecutionButton/MultiExecutionButton';

export const TradedCounterButtons = ({ order, counter, negotiation }) => {
	const { isMyContact } = useMyContactIds();
	const { isMyOrder } = useOrderDetails(order);

	const isSmallScreen = useMediaQuery({ 'max-width': screenSize.xl - 1 });

	const counterpartyId = isMyOrder ? negotiation.counter_order_user_id : order.user_id;

	const shouldHideCopyButton = order.instrument === PaperInstruments.Spread;

	return (
		<>
			{isMyContact(counterpartyId) && (
				<ChatButton userId={counterpartyId} skipIcon={isSmallScreen} />
			)}
			<CopyOrderButton
				Component={CopyButton}
				tradeId={counter.trade_id}
				market={order.market}
				hidden={shouldHideCopyButton}
				recipients={
					order.environment === Environment.OTC
						? [counter.order_user_id, counter.counter_order_user_id]
						: ['']
				}
			/>
			<MultiExecutionButton counter={counter} />
		</>
	);
};
