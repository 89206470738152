/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useSelector } from 'react-redux';
import { getUserId } from 'src/_store/selectors';
import { ModalSize } from 'src/constants/modal';
import { UserType } from 'src/constants/userNetwork';
import { useUserDetailsQuery } from 'src/_api/queries';
import { useTraderStatistics } from 'src/containers/UserNetwork/Contacts/helpers/useTraderStatistics';
import { UserProfile } from 'src/containers/UserNetwork/components/Contacts/UserProfile/UserProfile';
import { mapUserDetails } from 'src/containers/UserNetwork/Contacts/helpers/mapUserDetails';
import * as Styled from './styled';

export const UserProfileModal = ({ userId, close, onButtonClick }) => {
	const loggedUserId = useSelector(getUserId);
	const { data: userData = {} } = useUserDetailsQuery(userId);

	const user = mapUserDetails(userData);

	const { statistics = {} } = useTraderStatistics(userId, user.showActivity);

	const userType =
		userId === loggedUserId
			? UserType.Me
			: user.isMyContact
			? UserType.Contacts
			: UserType.AllUsers;

	return (
		<Styled.UserProfileModal
			size={ModalSize.Tiny}
			isOpen
			data-test="user-profile-modal"
			close={close}
			withCloseIcon
		>
			{user.id ? (
				<UserProfile
					user={user}
					type={userType}
					modal
					onButtonClick={onButtonClick}
					close={close}
					statistics={statistics}
				/>
			) : (
				<div className="ui loader" />
			)}
		</Styled.UserProfileModal>
	);
};
