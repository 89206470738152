/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { ContainerSpacing, SlideAttachment } from './constants';

export const setStyle = (ref, name, value) => {
	if (ref.current && typeof value !== 'undefined') {
		ref.current.style[name] = `${value}px`;
	}
};

export const setPosition = (ref, { left, top, width, height }) => {
	ref.current.style.transform = `translate(${left}px, ${top}px)`;

	if (width) {
		setStyle(ref, 'width', width);
	}
	if (height) {
		setStyle(ref, 'height', height);
	}
};

export const showElement = ref => {
	if (ref.current) {
		ref.current.style.visibility = 'visible';
	}
};
export const hideElement = ref => {
	if (ref.current) {
		ref.current.style.visibility = 'hidden';
	}
};

export const getContainerPositions = ({ rect, containerWidth, containerHeight, attachment }) => {
	let containerPosition;

	switch (attachment) {
		case SlideAttachment.BottomLeft:
			containerPosition = {
				left: rect.left,
				top: rect.bottom + ContainerSpacing.Top,
			};
			break;

		case SlideAttachment.BottomRight:
			containerPosition = {
				left: rect.right - containerWidth,
				top: rect.bottom + ContainerSpacing.Top,
			};
			break;

		case SlideAttachment.TopLeft:
			containerPosition = {
				left: rect.left,
				top: rect.top - containerHeight - ContainerSpacing.Bottom,
			};
			break;

		case SlideAttachment.Left:
			containerPosition = {
				left: rect.left - containerWidth - ContainerSpacing.Right,
				top: rect.top,
			};
			break;

		case SlideAttachment.TopLeftInner:
			containerPosition = {
				left: rect.left + ContainerSpacing.Left,
				top: rect.top + ContainerSpacing.InnerTop,
			};
			break;

		default:
			break;
	}

	return containerPosition;
};
