/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import qs from 'querystring';
import { getApiUrl } from 'src/chat/api/getApiUrl';
import { escapeHref } from 'src/chat/helpers/escapeHref';
import { withCSRFTokenHeader } from 'src/_api';
import { ROUTES } from 'src/constants/routes';

export const ServicePrefix = {
	Exchange: '',
	Chat: '/chat',
};

export const API_URL = getApiUrl(document.location);

const isStatusSuccess = statusCode => {
	return statusCode >= 200 && statusCode < 300;
};

const parseSuccessResponse = async response => {
	let parsedResponse = {};

	try {
		parsedResponse = await response.json();
	} catch (e) {
		// Request response received but could not be parsed correctly
		const errorDetails = {
			status: response.status,
			type: 'Invalid JSON',
			message: 'Error while parsing response',
		};

		throw errorDetails;
	}

	return parsedResponse;
};

const parseFailedResponse = async response => {
	let parsedResponse = {};

	try {
		parsedResponse = await response.json();
	} catch (e) {
		// Request response received but could not be parsed correctly
		parsedResponse.error = {
			type: 'Invalid JSON',
			message: 'Error while parsing response',
		};
	}

	const errorDetails = {
		status: response.status,
		type: parsedResponse.error?.type || 'Unknown error type',
		message: parsedResponse.error?.message || 'Unknown error message',
	};

	throw errorDetails;
};

const handleResponse = (response = {}, mapStatusToResult) => {
	const statusCode = response.status;

	// user session expired so redirect to the login page
	if (statusCode === 401) {
		document.location.href = escapeHref(ROUTES.login);
		return;
	}

	// Legacy part: some 404s might mean the request actually succeeded
	// but data were not returned for some reason so we need to map such request using passed mapper
	if (statusCode === 404 && mapStatusToResult) {
		return mapStatusToResult(statusCode);
	}

	// All 2xx are always considered as success
	if (isStatusSuccess(statusCode)) {
		return parseSuccessResponse(response);
	}

	// handle all other requests
	return parseFailedResponse(response);
};

export const fetchFromApi = async (
	url,
	{ query, mapStatusToResult, body, urlServicePrefix = ServicePrefix.Exchange, ...params }
) => {
	const apiUrl = getApiUrl(document.location, urlServicePrefix);
	const baseUrl = `${apiUrl}${urlServicePrefix}/v1`;

	const requestUrl = `${baseUrl}/${url}${query ? `?${qs.encode(query)}` : ''}`;

	const response = await fetch(requestUrl, {
		credentials: 'include',
		...(body && { body: formatBody(body) }),
		...params,
		headers: withCSRFTokenHeader(params.headers),
	});

	return await handleResponse(response, mapStatusToResult);
};

const formatBody = body => {
	if (body instanceof FormData) {
		return body;
	}

	return JSON.stringify(body);
};

export const statusMap = {
	200: 'OK',
	400: 'Bad request',
	401: 'Unauthorized',
	402: 'Payment required, account was not approved',
	403: 'Forbidden',
	404: 'Not found',
	418: 'ValidationError',
	500: 'Internal Server Error',
	503: 'Service Unavailable',
};

export function map404ToEmptyList(status) {
	if (status === 404) {
		return [];
	}
}

export function map404ToEmptyObject(status) {
	if (status === 404) {
		return {};
	}
}

export function map404ToNull(status) {
	if (status === 404) {
		return null;
	}
}

export const mapFalsyResponseToEmptyArray = res => res || [];
