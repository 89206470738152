/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
	FoldableSection,
	IconType,
} from 'src/components/CreateOrderDrawer/components/FoldableSection/FoldableSection';
import { OverflowText } from 'src/components/Tooltip/OverflowText';
import { tSpecDirection } from 'src/constants/product';
import * as Styled from './styled';

export const PaperSpecification = ({ specs }) => {
	const { t } = useTranslation();

	const specsList = specs.map(item => ({
		...item.spec,
		value: item.value,
	}));

	return (
		<Styled.Wrapper>
			<FoldableSection
				title={t('specifications')}
				showAll={false}
				iconType={IconType.chevron}
			>
				<Styled.SpecsListContent fullWidth data-test="specifications-section">
					<Styled.SpecsList>
						{specsList.map((spec, index) => (
							<div key={spec._key}>
								<Styled.Label>
									<OverflowText>{spec.name}</OverflowText>
								</Styled.Label>
								<Styled.Spec>
									<span>{tSpecDirection(t, spec)}</span>
									<Styled.Value>
										{spec.value}
										{spec.unit}{' '}
									</Styled.Value>
								</Styled.Spec>
							</div>
						))}
					</Styled.SpecsList>
				</Styled.SpecsListContent>
			</FoldableSection>
		</Styled.Wrapper>
	);
};
