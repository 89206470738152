/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import clsx from 'clsx';
import { Label } from '../Label/Label';
import { MultiSelect } from 'src/designSystem/Form/MultiSelect/MultiSelect';
import * as Styled from './styled';

export const MaterialMultiSelectInput = React.forwardRef(
	(
		{
			label,
			id,
			required,
			searchIcon,
			controlledState = false,
			hasChanged = false,
			selectComponent: SelectComponent = MultiSelect,
			...props
		},
		ref
	) => {
		const hasValue = !!props.value?.length;
		const filledControlledState = controlledState === true && hasChanged === false && hasValue;
		const filledState = controlledState ? hasChanged === true : hasValue;

		return (
			<Styled.InputContainer
				className={clsx(
					'select-input',
					'multi-select',
					props.search && 'search',
					filledState && 'input-filled',
					filledControlledState && 'input-filled-controlled',
					props.disabled && 'input-disabled'
				)}
				ref={ref}
			>
				<SelectComponent id={id} required={required} {...props} />
				<Label htmlFor={id} className="input-label">
					{label}
					{required && <span className="asterisk">*</span>}
				</Label>
				{hasValue && !props.disabled && <Styled.ValidCheckIcon />}
			</Styled.InputContainer>
		);
	}
);
