/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import moment from 'moment';

export const calculateClosestFuturesMonth = ({ startDate, endDate }, futuresMonthOptions) => {
	if (!startDate || !endDate || !futuresMonthOptions) {
		return;
	}

	const date =
		moment(startDate).utc().month() !== moment(endDate).utc().month() ? endDate : startDate;

	const ts = moment(date).utc().startOf('month').valueOf();

	return futuresMonthOptions.find(({ value }) => value >= ts);
};
