/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DrawerFooter } from '../Drawer/Drawer';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { isBeforeToday } from 'src/_helpers/date';
import { useWatchPaperFields } from './paper/useWatchPaperFields';
import { PaperInstruments, OrderType, QuoteType } from 'src/constants/contract';
import {
	DefaultValidityOptions,
	translateTimeRangeOptions,
	isTimeInMinutes,
} from 'src/_helpers/timeRange';
import * as Styled from './styled';
import { getDefaultValidity } from './paper/helpers/getDefaultValidity';

export const CreatePaperOrderDrawerFooter = ({
	progress = 0,
	onSubmit,
	previewAlwaysAvailable = false,
	onCancel,
	isSubmitting,
	selectedPreset,
}) => {
	const { t } = useTranslation();

	const {
		formState: { errors },
		setValue,
	} = useFormContext();

	const {
		isEditing,
		instrumentValue,
		validityValue,
		validityChangedValue,
	} = useWatchPaperFields();
	const ordersCount = useWatch({ name: 'contractPricing' })?.length || 1;

	const validityOptions = selectedPreset?.validity_options
		? {
				options: translateTimeRangeOptions(selectedPreset.validity_options, t),
				tradingSessions: selectedPreset.trading_sessions
					? {
							header: `trading_session_${selectedPreset.inco_id}_${selectedPreset.product_id}`.toLowerCase(),
							items: selectedPreset.trading_sessions.map(({ end }) => end) || [],
					  }
					: null,
		  }
		: { options: translateTimeRangeOptions(DefaultValidityOptions, t) };

	useEffect(() => {
		// if the product has been changed and the validity was previously set - check if the validity is still on the list
		if (validityValue?.time && validityChangedValue) {
			if (isTimeInMinutes(validityValue.time)) {
				const selectedValidityExistOnList = validityOptions.options.find(
					item => item.key === validityValue.time
				);

				if (!selectedValidityExistOnList) {
					setValue('validity', getDefaultValidity(QuoteType.Indicative));
				}
			} else if (!validityOptions.tradingSessions) {
				setValue('validity', getDefaultValidity(QuoteType.Indicative));
			}
		}
	}, [
		setValue,
		validityChangedValue,
		validityOptions.options,
		validityOptions.tradingSessions,
		validityValue.time,
	]);

	const typeValue = useWatch({ name: 'contractPricing.0.type' });

	const singleOutrightLabel = useMemo(() => {
		if (typeValue === OrderType.Buy) {
			return t('preview_bid');
		}
		if (typeValue === OrderType.Sell) {
			return t('preview_offer');
		}
		return t('preview_order');
	}, [typeValue, t]);

	return (
		<DrawerFooter progress={progress}>
			<Controller
				name="validity"
				render={({ field }) => (
					<Styled.ValidityInput
						required
						label={t('validity')}
						id="validity"
						error={!!errors[field.name]}
						popupHeaderTitle={t('validity')}
						readOnly
						filterDate={date => !isBeforeToday(date)}
						renderDateToggleButton
						withEllipsis
						isTimeDefault
						validityOptions={validityOptions}
						{...field}
						onChange={value => {
							field.onChange(value);
							setValue('validityChanged', true);
						}}
					/>
				)}
			/>
			{isEditing ? (
				<Styled.Buttons>
					<Styled.CancelButton $isEditing onClick={onCancel}>
						{t('cancel')}
					</Styled.CancelButton>
					<Styled.PreviewButton
						onClick={onSubmit}
						disabled={!previewAlwaysAvailable}
						loading={isSubmitting}
						$isEditing
					>
						{t('save')}
					</Styled.PreviewButton>
				</Styled.Buttons>
			) : (
				<Styled.PreviewButton onClick={onSubmit} disabled={!previewAlwaysAvailable}>
					{instrumentValue === PaperInstruments.Spread && ordersCount === 1 ? (
						t('preview_spread')
					) : ordersCount === 1 ? (
						singleOutrightLabel
					) : (
						<>
							{t('preview_order', { count: ordersCount })}{' '}
							{ordersCount > 1 && `(${ordersCount})`}
						</>
					)}
				</Styled.PreviewButton>
			)}
		</DrawerFooter>
	);
};
