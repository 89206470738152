/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useSelector } from 'react-redux';
import { getDefaultValuesFromApi } from './physical/model';
import { getPaperDefaultValuesFromApi } from './paper/model';
import { CreatePhysicalOrderDrawerContent } from './physical/CreatePhysicalOrderDrawerContent';
import { CreatePaperOrderDrawerContent } from './paper/CreatePaperOrderDrawerContent';
import { Market } from 'src/constants/contract';
import { getUserId } from 'src/_store/selectors';
import { useDefaultValidities } from './paper/helpers/useDefaultValidities';
import { useOnce } from 'src/_helpers/useOnce';

export const CopyOrder = ({
	isEditing,
	environment,
	orderId,
	negotiationId,
	tradeId,
	market,
	recipients = [],
	contentKey,
	copyData,
	onPresetChange,
	...forcedOrderParams
}) => {
	const userId = useSelector(getUserId);
	const defaultPresetValidites = useDefaultValidities(copyData.preset_id);

	const isPhysical = market === Market.Physical;

	const filteredRecipients = recipients.filter(id => id !== userId);
	copyData.recipients_list = (copyData.recipients_list || []).filter(
		user => user._key !== userId
	);

	const isCreatingFromTemplate = Object.keys(forcedOrderParams).length > 0;

	const formValues = isPhysical
		? getDefaultValuesFromApi(copyData, filteredRecipients, userId, isEditing, true)
		: getPaperDefaultValuesFromApi(
				copyData,
				filteredRecipients,
				isEditing,
				forcedOrderParams?.quickCreation ? true : !isCreatingFromTemplate,
				defaultPresetValidites
		  );

	useOnce(() => {
		if (!isPhysical) {
			onPresetChange(copyData.product_id);
		}
	});

	return isPhysical ? (
		<CreatePhysicalOrderDrawerContent key={contentKey} formValues={formValues} />
	) : (
		<CreatePaperOrderDrawerContent
			key={contentKey}
			formValues={formValues}
			onPresetChange={onPresetChange}
		/>
	);
};
