/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { HeaderType } from 'src/components/Drawer/constants';
import { DrawerHeader } from 'src/components/Drawer/DrawerHeader/DrawerHeader';
import { useViewOrderDrawerContext } from 'src/components/ViewOrderDrawer/ViewOrderDrawerProvider';

export const CounterFormHeader = ({
	headerMessage,
	hiddenInfoSubtitle,
	order,
	setCounterForm,
	lastCounter,
}) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	const {
		newOrderActivities,
		newNegotiationActivities,
		setOrderAndNegotiationUpToDate,
	} = useViewOrderDrawerContext();

	if (newOrderActivities) {
		return (
			<DrawerHeader
				type={HeaderType.Info}
				message={t('order_was_updated')}
				onRefresh={() => {
					setOrderAndNegotiationUpToDate();
					queryClient.invalidateQueries(['order', order._key]);
					setCounterForm(false, null);
				}}
			/>
		);
	}

	if (newNegotiationActivities) {
		return (
			<DrawerHeader
				type={HeaderType.Info}
				message={t('negotiation_was_updated')}
				onRefresh={() => {
					setOrderAndNegotiationUpToDate();
					queryClient.invalidateQueries(['order', order._key]);
					queryClient.invalidateQueries([
						'order_with_counters',
						order._key,
						lastCounter.first_counter_id,
					]);
					setCounterForm(false, null);
				}}
			/>
		);
	}

	return (
		<DrawerHeader
			type={HeaderType.MyOrder}
			message={headerMessage}
			subtitle={hiddenInfoSubtitle}
		/>
	);
};
