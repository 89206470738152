/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import qs from 'querystring';
import { getApiUrl } from 'src/_api/getApiUrl';
import { ROUTES } from 'src/constants/routes';
import { escapeHref } from 'src/_helpers/escapeHref';

export const API_URL = getApiUrl(document.location);

export const isDevEnvironment = API_URL.includes('dev') || API_URL.includes('cloud');

export let CSRFToken;

export const setCSRFToken = t => {
	CSRFToken = t;
};

export const withCSRFTokenHeader = headers => {
	return {
		...headers,
		...(CSRFToken && { 'X-CSRF-Token': CSRFToken }),
	};
};

const isStatusSuccess = statusCode => {
	return statusCode >= 200 && statusCode < 300;
};

const parseSuccessResponse = async response => {
	let parsedResponse = {};

	try {
		parsedResponse = await response.json();
	} catch (e) {
		// Some requests does not return nothing even tho they suceeded
		// In such case we are returning empty object to indicate succesful parsing of empty response, not throwing the exception
		return parsedResponse;
	}

	return parsedResponse;
};

const parseFailedResponse = async response => {
	let parsedResponse = {};

	try {
		parsedResponse = await response.json();
	} catch (e) {
		// Failed request response received but could not be parsed correctly for some reason
		parsedResponse.error = {
			status: response.status,
			type: 'Invalid JSON',
			message: 'Error while parsing response',
		};
	}

	const errorDetails = {
		status: response.status,
		type: parsedResponse.error?.type || 'Unknown error type',
		message: parsedResponse.error?.message || 'Unknown error message',
	};

	throw errorDetails;
};

const handleResponse = (response = {}, mapStatusToResult) => {
	const statusCode = response.status;

	// user session expired so redirect to the login page
	if (statusCode === 401) {
		document.location.href = escapeHref(ROUTES.login);
		return;
	}

	// Legacy part: some 404s might mean the request succeeded but data were not returned for some reason so we need to map such request
	// In such case if the mapStatusToResult was passed, it means we want to use it
	if (statusCode === 404 && mapStatusToResult) {
		return mapStatusToResult(statusCode);
	}

	// All 2xx are considered as success
	if (isStatusSuccess(statusCode)) {
		return parseSuccessResponse(response);
	}

	// handle all other requests
	return parseFailedResponse(response);
};

export const fetchFromExchangeApi = async (url, { query, mapStatusToResult, body, ...params }) => {
	const requestUrl = `${API_URL}/v1/${url}${query ? `?${qs.encode(query)}` : ''}`;

	const response = await fetch(requestUrl, {
		credentials: 'include',
		...(body && { body: formatBody(body) }),
		...params,
		headers: withCSRFTokenHeader(params.headers),
	});

	return handleResponse(response, mapStatusToResult);
};

export const fetchFromServerSide = async (url, { query, mapStatusToResult, body, ...params }) => {
	const requestUrl = `/api/${url}${query ? `?${qs.encode(query)}` : ''}`;

	const response = await fetch(requestUrl, {
		credentials: 'include',
		...(body && { body: formatBody(body) }),
		...params,
		headers: withCSRFTokenHeader(params.headers),
	});

	return handleResponse(response, mapStatusToResult);
};

// FOR RUNNING LOCALLY AND TESTING VALUES AGAINST THE MOCK.VOSBOR.DEV ENDPOINT
export const fetchFromMockApi = async (url, { query, mapStatusToResult, body, ...params }) => {
	const requestUrl = `https://mock.vosbor.dev/v1/${url}${query ? `?${qs.encode(query)}` : ''}`;

	const response = await fetch(requestUrl, {
		...(body && { body: formatBody(body) }),
		...params,
		headers: withCSRFTokenHeader(params.headers),
	});

	return await handleResponse(response, mapStatusToResult);
};

const formatBody = body => {
	if (body instanceof FormData) {
		return body;
	}

	return JSON.stringify(body);
};

export const statusMap = {
	200: 'OK',
	400: 'Bad request',
	401: 'Unauthorized',
	402: 'Payment required, account was not approved',
	403: 'Forbidden',
	404: 'Not found',
	418: 'ValidationError',
	500: 'Internal Server Error',
	503: 'Service Unavailable',
};

export const map404ToEmptyList = status => {
	if (status === 404) {
		return [];
	}
};

export const map404ToEmptyObject = status => {
	if (status === 404) {
		return {};
	}
};

export const map404ToNull = status => {
	if (status === 404) {
		return null;
	}
};

export const mapFalsyResponseToEmptyArray = res => res || [];

export const Timeout = time => {
	let controller = new AbortController();
	setTimeout(() => controller.abort(), time * 1000);
	return controller;
};
