/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { getUserId } from 'src/_store/selectors';
import { Avatar } from 'src/chat/components/GroupsList/styled';
import { formatDistance } from 'src/_helpers/date';
import { OrderStatus } from 'src/constants/orderStatus';
import { OverflowText } from 'src/components/Tooltip/OverflowText';
import { useMyRowsActivity } from 'src/components/Datatable/useMyRowsActivity';
import { useUpdateAtTime } from 'src/_helpers/useUpdateAtTime';
import { CounterStatus, validityStatusCheck } from 'src/constants/counterStatus';
import { isThreeDigitPrice } from 'src/_helpers/price';
import { SpreadPriceFormat, OrderType, tCalendarSpreadPayment } from 'src/constants/contract';
import { calculatePayCashDirection } from 'src/_helpers/spread';
import * as Styled from './styled';

export const SingleNegotiationRow = ({
	negotiation,
	onNegotiationSelect,
	isActive,
	originalOrder = false,
	isOrderTraded,
	isExpanded,
	transitionCompleted,
}) => {
	const { t } = useTranslation();
	const currentUserID = useSelector(getUserId);

	const isTraded = !!negotiation.trade_id;
	const counterType = isTraded
		? t('traded')
		: negotiation.user_id === currentUserID
		? t('sent')
		: t('received');

	const hasJustExpired = useUpdateAtTime(negotiation.validity);

	const isCounterExpired =
		negotiation.status === CounterStatus.Expired ||
		validityStatusCheck(negotiation.validity, negotiation.status);

	const expiredTime = formatDistance({
		t,
		startDate: new Date(),
		endDate: negotiation.validity,
		fullText: true,
		ago: true,
	});

	const validityLabel =
		isCounterExpired || hasJustExpired
			? `${t('expired')} ${expiredTime.toLowerCase()}`
			: `${t('valid_for')} ${formatDistance({
					t,
					startDate: new Date(),
					endDate: negotiation.validity,
					fullText: true,
			  }).toLowerCase()}`;

	const isOtherNegotiationTraded =
		!originalOrder && isOrderTraded && negotiation.status === CounterStatus.NotAvailable;
	const { shouldHighlight } = useMyRowsActivity(negotiation, isOtherNegotiationTraded);

	const handleNegotiationClick = () => {
		onNegotiationSelect(originalOrder ? negotiation._key : negotiation.first_counter_id);
	};

	const company = negotiation.counter_order_user_company || negotiation.company;

	const shouldHideValidity =
		negotiation.status === OrderStatus.Terminated ||
		negotiation.status === OrderStatus.NotAvailable ||
		isTraded;

	const decimalScale = isThreeDigitPrice(negotiation.price) ? 3 : 2;

	const orderType = negotiation.order_type;

	const isBuyer = orderType === OrderType.Buy;

	const payCashSpreadDirection = calculatePayCashDirection(isBuyer, negotiation.price);

	const isPayCashSpread = negotiation.spread_details?.price_format === SpreadPriceFormat.PayCash;

	const shouldDisplayPayCashLabel = originalOrder && isPayCashSpread;
	const shouldShowPriceWithSign =
		negotiation.spread_details?.price_format === SpreadPriceFormat.Spread;

	const negotiationPrice = negotiation.price;

	const negotiationAbsolutePrice = Math.abs(negotiationPrice);

	// Counters can't be 'No price'
	const hasPrice = originalOrder ? negotiation.has_price : true;

	return (
		<Styled.SingleCounterRow
			onClick={handleNegotiationClick}
			className={clsx(
				isActive && 'active',
				isExpanded && transitionCompleted ? 'expanded' : 'collapsed',
				!originalOrder && shouldHighlight && 'new-activities'
			)}
		>
			{isExpanded && transitionCompleted ? (
				<>
					{/* TODO: Use common circle avatar component */}
					<Avatar $color={company.avatar_color}>{company.name.charAt(0)}</Avatar>
					<Styled.NameValidity>
						<OverflowText>
							<Styled.Name>{company.name}</Styled.Name>
						</OverflowText>
						<Styled.Validity>
							{shouldHideValidity ? '---' : validityLabel}
						</Styled.Validity>
					</Styled.NameValidity>
					<Styled.Type>{!originalOrder && counterType}</Styled.Type>
					<Styled.Price>
						{hasPrice ? (
							<>
								{shouldDisplayPayCashLabel && (
									<span>{tCalendarSpreadPayment(t, payCashSpreadDirection)}</span>
								)}
								<NumberFormat
									value={
										isPayCashSpread
											? negotiationAbsolutePrice
											: negotiationPrice
									}
									displayType="text"
									thousandSeparator
									decimalScale={decimalScale}
									allowLeadingZeros
									fixedDecimalScale
									prefix={
										shouldShowPriceWithSign && negotiationPrice > 0 ? '+' : ''
									}
								/>
							</>
						) : (
							'---'
						)}
					</Styled.Price>
				</>
			) : (
				<Styled.PriceCollapsed>
					{hasPrice ? (
						<OverflowText>
							<NumberFormat
								value={
									isPayCashSpread ? negotiationAbsolutePrice : negotiationPrice
								}
								displayType="text"
								thousandSeparator
								decimalScale={decimalScale}
								allowLeadingZeros
								fixedDecimalScale
								prefix={shouldShowPriceWithSign && negotiationPrice > 0 ? '+' : ''}
							/>
						</OverflowText>
					) : (
						'---'
					)}
				</Styled.PriceCollapsed>
			)}
		</Styled.SingleCounterRow>
	);
};
