/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import {
	IconType,
	FoldableSection,
} from 'src/components/CreateOrderDrawer/components/FoldableSection/FoldableSection';
import { useTranslation } from 'react-i18next';
import { tSpecDirection } from 'src/constants/product';
import { OverflowText } from 'src/components/Tooltip/OverflowText';
import {
	SectionGrid,
	SectionGridContent,
	SectionGridLabel,
	SectionGridText,
} from '../../../../ViewOrderDrawer/styled';
import { SpecDirection } from './styled';

export const SpecificationsSection = ({ lastCounter }) => {
	const { t } = useTranslation();

	const grade = lastCounter.grade;

	return (
		<FoldableSection title={t('specifications')} iconType={IconType.chevron}>
			<SectionGrid>
				{grade.gradespecs.map(({ _key, spec, value }) => {
					return (
						<SectionGridContent key={_key}>
							<SectionGridLabel>
								<OverflowText>{spec.name}</OverflowText>
							</SectionGridLabel>
							<SectionGridText>
								<OverflowText>
									<SpecDirection>{tSpecDirection(t, spec)}</SpecDirection> {value}
									{spec.unit}
								</OverflowText>
							</SectionGridText>
						</SectionGridContent>
					);
				})}
			</SectionGrid>
		</FoldableSection>
	);
};
