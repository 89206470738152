/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
export const SEARCH_SET_MARKET = 'SEARCH_SET_MARKET';

export const SEARCH_SET_PRODUCT_VISIBLE = 'SEARCH_SET_PRODUCT_VISIBLE';
export const SEARCH_SET_GRADE_VISIBLE = 'SEARCH_SET_GRADE_VISIBLE';

export const SEARCH_SELECT_PRODUCT = 'SEARCH_SELECT_PRODUCT';
export const SEARCH_SELECT_ORIGIN = 'SEARCH_SELECT_ORIGIN';
export const SEARCH_SELECT_HARVEST_YEAR = 'SEARCH_SELECT_HARVEST_YEAR';
export const SEARCH_SELECT_SPECIALITY = 'SEARCH_SELECT_SPECIALITY';
export const SEARCH_SELECT_GRADE = 'SEARCH_SELECT_GRADE';
export const SEARCH_SET_SPEC_VALUE = 'SEARCH_SET_SPEC_VALUE';
export const SEARCH_RESET_PRODUCT_FILTERS = 'SEARCH_RESET_PRODUCT_FILTERS';

export const SEARCH_SELECT_INCO = 'SEARCH_SELECT_INCO';
export const SEARCH_SELECT_PRESET = 'SEARCH_SELECT_PRESET';
export const SEARCH_SELECT_PRICE_TYPE = 'SEARCH_SELECT_PRICE_TYPE';
export const SEARCH_SELECT_SHIPMENT_TYPE = 'SEARCH_SELECT_SHIPMENT_TYPE';
export const SEARCH_SELECT_PAYMENT = 'SEARCH_SELECT_PAYMENT';
export const SEARCH_SELECT_ROLE = 'SEARCH_SELECT_ROLE';
export const SEARCH_SELECT_VOLUME_RANGE = 'SEARCH_SELECT_VOLUME_RANGE';
export const SEARCH_RESET_CONTRACT_FILTERS = 'SEARCH_RESET_CONTRACT_FILTERS';

export const SEARCH_SET_MY_APPROVED_CONTACTS = 'SEARCH_SET_MY_APPROVED_CONTACTS';

export const SEARCH_SELECT_LOCATION = 'SEARCH_SELECT_LOCATION';
export const SEARCH_SELECT_LOCATION_PORT = 'SEARCH_SELECT_LOCATION_PORT';
export const SEARCH_RESET_LOCATION_FILTERS = 'SEARCH_RESET_LOCATION_FILTERS';

export const SEARCH_SELECT_SHIPMENT = 'SEARCH_SELECT_SHIPMENT';

export const SEARCH_CLEAR_FILTERS = 'SEARCH_CLEAR_FILTERS';

export const SEARCH_SELECT_ORDER_TYPE = 'SEARCH_SELECT_ORDER_TYPE';
export const SEARCH_SELECT_INSTRUMENT = 'SEARCH_SELECT_INSTRUMENT';

export const SEARCH_SELECT_UPDATE = 'SEARCH_SELECT_UPDATE';
export const SEARCH_ORDERS_SUCCESS = 'SEARCH_ORDERS_SUCCESS';
export const SEARCH_ORDERS_ERROR = 'SEARCH_ORDERS_ERROR';

export const SEARCH_SET_CLOSED = 'SEARCH_SET_CLOSED';

export const searchSetMarket = payload => ({ type: SEARCH_SET_MARKET, payload });
export const searchSelectUpdate = payload => ({ type: SEARCH_SELECT_UPDATE, payload });

export const searchOrdersSuccess = payload => ({
	type: SEARCH_ORDERS_SUCCESS,
	payload,
});

export const searchOrdersError = payload => ({
	type: SEARCH_ORDERS_ERROR,
	payload,
});

export const searchSelectProduct = payload => ({
	type: SEARCH_SELECT_PRODUCT,
	payload,
});

export const searchSetProductVisible = payload => ({
	type: SEARCH_SET_PRODUCT_VISIBLE,
	payload,
});

export const searchSetGradeVisible = payload => ({
	type: SEARCH_SET_GRADE_VISIBLE,
	payload,
});

export const searchSelectOrigin = payload => ({
	type: SEARCH_SELECT_ORIGIN,
	payload,
});

export const searchSelectHarvestYear = payload => ({
	type: SEARCH_SELECT_HARVEST_YEAR,
	payload,
});

export const searchSelectGrade = payload => ({
	type: SEARCH_SELECT_GRADE,
	payload,
});

export const searchSelectSpeciality = payload => ({
	type: SEARCH_SELECT_SPECIALITY,
	payload,
});

export const searchSetSpecValue = payload => ({
	type: SEARCH_SET_SPEC_VALUE,
	payload,
});

export const searchResetProductFilters = () => ({
	type: SEARCH_RESET_PRODUCT_FILTERS,
});

export const searchSelectInco = payload => ({
	type: SEARCH_SELECT_INCO,
	payload,
});

export const searchSelectPresetId = payload => ({
	type: SEARCH_SELECT_PRESET,
	payload,
});

export const searchSelectPriceType = payload => ({
	type: SEARCH_SELECT_PRICE_TYPE,
	payload,
});

export const searchSelectShipmentType = payload => ({
	type: SEARCH_SELECT_SHIPMENT_TYPE,
	payload,
});

export const searchSelectPayment = payload => ({
	type: SEARCH_SELECT_PAYMENT,
	payload,
});

export const searchSelectRole = payload => ({
	type: SEARCH_SELECT_ROLE,
	payload,
});

export const searchSelectVolumeRange = payload => ({
	type: SEARCH_SELECT_VOLUME_RANGE,
	payload,
});

export const searchResetContractFilters = () => ({
	type: SEARCH_RESET_CONTRACT_FILTERS,
});

export const searchSelectLocationPort = payload => ({
	type: SEARCH_SELECT_LOCATION_PORT,
	payload,
});

export const searchSelectShipment = payload => ({
	type: SEARCH_SELECT_SHIPMENT,
	payload,
});

export const searchSelectLocation = payload => ({
	type: SEARCH_SELECT_LOCATION,
	payload,
});

export const searchResetLocationFilters = () => ({
	type: SEARCH_RESET_LOCATION_FILTERS,
});

export const searchClearFilters = payload => ({
	type: SEARCH_CLEAR_FILTERS,
	payload,
});

export const searchSelectOrderType = payload => ({
	type: SEARCH_SELECT_ORDER_TYPE,
	payload,
});

export const searchSelectInstrument = payload => ({
	type: SEARCH_SELECT_INSTRUMENT,
	payload,
});

export const searchSelectMyContacts = payload => ({
	type: SEARCH_SET_MY_APPROVED_CONTACTS,
	payload,
});

export const searchSetClosed = payload => ({
	type: SEARCH_SET_CLOSED,
	payload,
});

export const searchActionTypes = [
	SEARCH_SELECT_PRODUCT,
	SEARCH_SELECT_INSTRUMENT,
	SEARCH_SELECT_ORDER_TYPE,
	SEARCH_SELECT_ORIGIN,
	SEARCH_SELECT_HARVEST_YEAR,
	SEARCH_SELECT_SPECIALITY,
	SEARCH_SELECT_GRADE,
	SEARCH_SET_SPEC_VALUE,
	SEARCH_SELECT_INCO,
	SEARCH_SELECT_PRICE_TYPE,
	SEARCH_SELECT_SHIPMENT_TYPE,
	SEARCH_SELECT_PAYMENT,
	SEARCH_SELECT_ROLE,
	SEARCH_SELECT_VOLUME_RANGE,
	SEARCH_SELECT_LOCATION_PORT,
	SEARCH_SELECT_LOCATION,
	SEARCH_SELECT_SHIPMENT,
	SEARCH_RESET_LOCATION_FILTERS,
	SEARCH_RESET_CONTRACT_FILTERS,
	SEARCH_RESET_PRODUCT_FILTERS,
	SEARCH_CLEAR_FILTERS,
	SEARCH_SET_CLOSED,
	SEARCH_SET_MARKET,
	SEARCH_SET_MY_APPROVED_CONTACTS,
];
