/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { toPath } from 'src/_helpers';
import { MaterialDateInput } from 'src/designSystem/Form/MaterialInput/MaterialDateInput';
import { getShipmentCutOffDate, isNextMonthOrAfter, isAfter } from 'src/_helpers/date';
import { defaultContractPricingRowValue } from 'src/components/CreateOrderDrawer/paper/model';
import { useWatchPaperFields } from 'src/components/CreateOrderDrawer/paper/useWatchPaperFields';
import * as Styled from './styled';

const SecondLegMonth = ({ path, t }) => {
	const { setValue } = useFormContext();
	const { firstLegMonthValue, secondLegMonthValue } = useWatchPaperFields(path);

	useEffect(() => {
		if (
			firstLegMonthValue?.endDate &&
			secondLegMonthValue?.startDate &&
			!isAfter(secondLegMonthValue.startDate, firstLegMonthValue?.endDate)
		) {
			setValue(toPath(path, 'secondLegMonth'), defaultContractPricingRowValue.secondLegMonth);
		}
	}, [firstLegMonthValue, path, secondLegMonthValue, setValue]);

	return (
		<Controller
			mandatory
			name={toPath(path, 'secondLegMonth')}
			defaultValue={null}
			render={({ field, fieldState: { error } }) => (
				<Styled.CalendarWrapper>
					<MaterialDateInput
						{...field}
						required
						id={field.name}
						dataTest={field.name}
						label={t('second_leg_shipment')}
						error={!!error}
						cutOffDate={getShipmentCutOffDate()}
						isMonthDisabled={month =>
							!isNextMonthOrAfter(month, firstLegMonthValue.endDate)
						}
						onChange={value => {
							field.onChange(value);
							setValue(toPath(path, 'secondLegShipmentChanged'), true);
						}}
					/>
				</Styled.CalendarWrapper>
			)}
		/>
	);
};

export const secondLegMonth = (path, t) => <SecondLegMonth path={path} t={t} />;
