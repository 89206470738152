/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { MaterialDateInput } from 'src/designSystem/Form/MaterialInput/MaterialDateInput';
import { MaterialSelectInput } from 'src/designSystem/Form/MaterialInput/MaterialSelectInput';
import { getShipmentCutOffDate, getNumberOfMonths } from 'src/_helpers/date';
import { MaterialNumberInput } from 'src/designSystem/Form/MaterialInput/MaterialNumberInput';
import {
	DrawerPriceType,
	tCurrency,
	PaperPorts,
	QuoteType,
	PreAdvice,
} from 'src/constants/contract';
import { useContractAndPricingCounterFields } from '../useContractAndPricingCounterFields';
import { useWatchPaperCounterFields } from '../../../../helpers/paper/useWatchPaperCounterFields';
import { FieldContainer } from 'src/components/CreateOrderDrawer/components/FoldableSection/styled';
import { toPath } from 'src/_helpers';
import { TotalQuantity } from 'src/components/CreateOrderDrawer/components/TotalQuantity/TotalQuantity';
import {
	Label,
	Value,
	Wrapper,
} from 'src/components/CreateOrderDrawer/components/TotalQuantity/styled';
import { OverflowText } from 'src/components/Tooltip/OverflowText';
import { Terms } from 'src/components/Terms/Terms';
import * as Styled from 'src/components/CreateOrderDrawer/paper/sections/ContractAndPricingSection/styled';

export const OutrightRow = ({ path, lastCounter }) => {
	const { t } = useTranslation();

	const { setValue } = useFormContext();

	const {
		orderType,
		priceType,
		futuresMonth,
		currency,
		unit,
		price,
		volume,
		shipment,
		selectedProductPresetValue,
		principal,
		runs,
		principalVisibility,
	} = useContractAndPricingCounterFields({ path, lastCounter });

	const {
		priceTypeValue,
		orderTypeValue,
		runsValue,
		numberOfDeliveryMonthsValue,
		preAdviceValue,
		preAdviceDaysValue,
		preAdviceVesselNameValue,
	} = useWatchPaperCounterFields(path);

	const hasVolumeDefined = selectedProductPresetValue?.volume;
	const hasRunsSupport = selectedProductPresetValue?.runs;
	const hasFuturesMonths = futuresMonth.options.length > 0;
	const isFOBParanagua = selectedProductPresetValue?.loading_port?._key === PaperPorts.PARANAGUA;
	const deliveryMonths = getNumberOfMonths(
		lastCounter.delivery_date_from,
		lastCounter.delivery_date_to
	);

	const shouldDisplayPriceType = selectedProductPresetValue && priceType.options.length > 1;
	const shouldDisplayQuantityField =
		selectedProductPresetValue && !hasRunsSupport && !hasVolumeDefined;

	const shouldDisplayTotalQuantity =
		hasRunsSupport && (runsValue > 1 || numberOfDeliveryMonthsValue > 1);

	const isBasisPriceType = priceTypeValue !== DrawerPriceType.Flat;

	const canClearPrincipalField = !lastCounter.order_owner_principal.company;

	const formattedCurrencyUnit = (
		<>
			<Styled.CurrencyUnitSeparator />
			<span>{`${tCurrency(t, currency)}/${t(`price_unit_short_${unit}`, {
				lng: 'en',
			})}`}</span>
		</>
	);

	const volumeLabel = isFOBParanagua && deliveryMonths > 1 ? t('total_quantity') : t('quantity');

	return (
		<Styled.OutrightRow>
			<FieldContainer>
				<Controller
					name={toPath(path, 'orderType')}
					mandatory
					render={({ field, fieldState: { error } }) => (
						<MaterialSelectInput
							error={!!error}
							data-test={field.name}
							label={t('counter_type')}
							options={orderType.options}
							required
							controlledState
							hasChanged={orderType.hasChanged}
							{...field}
							onChange={val => {
								field.onChange(val);
								setValue('orderTypeChanged', true);
							}}
						/>
					)}
				/>
				<Controller
					name={toPath(path, 'price')}
					mandatory
					render={({ field, fieldState: { error } }) => (
						<MaterialNumberInput
							error={!!error}
							data-test="priceCurrencyUnit"
							label={t('price')}
							required
							allowNegative={isBasisPriceType}
							parameters={formattedCurrencyUnit}
							decimalScale={isBasisPriceType ? 3 : 2}
							controlledState
							hasChanged={price.hasChanged}
							{...field}
							value={field.value.toString()}
						/>
					)}
				/>
				{shouldDisplayQuantityField && (
					<Controller
						mandatory={orderTypeValue === QuoteType.Firm}
						name={toPath(path, 'volume')}
						render={({ field, fieldState: { error } }) => (
							<MaterialNumberInput
								required={orderTypeValue === QuoteType.Firm}
								error={!!error}
								data-test={field.name}
								id="volume"
								label={volumeLabel}
								fixedDecimalScale={false}
								parameters={t('price_unit_short_metric_ton')}
								controlledState
								hasChanged={volume.hasChanged}
								isAllowed={value => value.floatValue !== 0}
								{...field}
							/>
						)}
					/>
				)}

				{shouldDisplayPriceType && (
					<Controller
						name={toPath(path, 'priceType')}
						mandatory
						render={({ field, fieldState: { error } }) => (
							<MaterialSelectInput
								error={!!error}
								data-test={field.name}
								label={t('price_type')}
								options={priceType.options}
								disabled={!priceType.counterable}
								required
								{...field}
							/>
						)}
					/>
				)}

				{isBasisPriceType && hasFuturesMonths && (
					<Controller
						name={toPath(path, 'futuresMonth')}
						mandatory
						render={({ field, fieldState: { error } }) => (
							<MaterialSelectInput
								required
								error={!!error}
								data-test={field.name}
								label={t('futures_month')}
								options={futuresMonth.options}
								disabled={!futuresMonth.counterable}
								{...field}
							/>
						)}
					/>
				)}
				{hasRunsSupport && (
					<Controller
						mandatory={orderTypeValue === QuoteType.Firm}
						name={toPath(path, 'runs')}
						defaultValue={null}
						render={({ field, fieldState: { error } }) => (
							<MaterialNumberInput
								{...field}
								required={orderTypeValue === QuoteType.Firm}
								id={field.name}
								data-test={field.name}
								label={t('runs')}
								error={orderTypeValue === QuoteType.Firm ? !!error : false}
								fixedDecimalScale
								decimalScale={0}
								allowNegative={false}
								isAllowed={({ formattedValue }) => formattedValue !== '0'}
								controlledState
								hasChanged={runs.hasChanged}
							/>
						)}
					/>
				)}
				<Controller
					mandatory
					name={toPath(path, 'delivery')}
					defaultValue={null}
					render={({ field, fieldState: { error } }) => (
						<Styled.CalendarWrapper>
							<MaterialDateInput
								{...field}
								required
								id={field.name}
								data-test={field.name}
								label={t('shipment')}
								error={!!error}
								cutOffDate={getShipmentCutOffDate()}
								disabled={!shipment.counterable}
							/>
						</Styled.CalendarWrapper>
					)}
				/>
				{principal.visible && (
					<>
						<Controller
							fullWidth
							mandatory={principal.hasValue}
							name={toPath(path, 'principalId')}
							render={({ field, fieldState: { error } }) => (
								<Styled.WideField>
									<MaterialSelectInput
										key={`principal-dropdown${
											principal.ready ? '' : '-loading'
										}`}
										error={!!error}
										data-test={field.name}
										label={t('principal')}
										options={principal.options}
										disabled={!principal.ready}
										controlledState
										hasChanged={principal.hasChanged}
										upward
										search
										clearable={canClearPrincipalField}
										searchIcon
										{...field}
									/>
								</Styled.WideField>
							)}
						/>
						<Controller
							mandatory
							name={toPath(path, 'principalVisibility')}
							render={({ field, fieldState: { error } }) => (
								<MaterialSelectInput
									required
									error={!!error}
									data-test={field.name}
									label={t('principal_name')}
									controlledState
									options={principalVisibility.options}
									hasChanged={principalVisibility.hasChanged}
									disabled={!principalVisibility.ready}
									{...field}
								/>
							)}
						/>
					</>
				)}
				<TotalQuantity path={path} shouldRender={shouldDisplayTotalQuantity} />
				{preAdviceValue === PreAdvice.Short && (
					<Wrapper>
						<Label>{t('pre_advice')}</Label>
						<Value>
							{t(`pre_advice_${preAdviceValue}`)}{' '}
							{t('days', {
								count: preAdviceDaysValue,
							})}
						</Value>
					</Wrapper>
				)}
				{preAdviceValue === PreAdvice.ToSuitVessel && (
					<Wrapper>
						<Label>{t('pre_advice_to_suit_mv')}</Label>
						<Value>
							<OverflowText>{preAdviceVesselNameValue}</OverflowText>
						</Value>
					</Wrapper>
				)}
				<Wrapper>
					<Terms
						label={t('additional_terms')}
						terms={lastCounter.additional_terms?.[0]}
					/>
				</Wrapper>
			</FieldContainer>
		</Styled.OutrightRow>
	);
};
