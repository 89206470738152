/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useRef, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Dateformat } from 'src/_helpers/date';
import styled from 'styled-components';
import { DateRange } from './DateRange';
import { MonthRange } from './MonthRange/MonthRange';
import { ContinuousRange } from './ContinuousRange';
import { useOnClickOutside } from 'src/_helpers/useOnClickOutside';
import { h500 } from 'src/components/Typography/fonts';
import { TypeSelector } from 'src/components/TypeSelector/TypeSelector';
import { QuartersRange } from './MonthRange/QuartersRange';

export const DateSelectorPopup = ({
	value = {},
	open,
	onClose = () => {},
	onChange = () => {},
	PopupArea = PopupAreaComponent,
	ResetIcon,
	isDateDisabled,
	isMonthDisabled,
	cutOffDate,
	initialVisibleMonth,
	popupHeaderTitle = 'shipment',
	enableDaysSelection,
	enableContinuousPeriodSelection,
	enableQuarterPeriodSelection,
	allowPastDates,
	allowMultipleMonths,
	disableClickOutside,
	shouldResetSelection,
	defaultDateFormat = Dateformat.Months,
}) => {
	const rootRef = useRef(null);
	const { t } = useTranslation();

	const [dateFormat, setDateFormat] = useState();

	useEffect(() => {
		setDateFormat(value.format || defaultDateFormat);
	}, [defaultDateFormat, value.format]);

	const handleClose = () => {
		setDateFormat(dateFormat);
		onClose?.();
	};

	const { startDay, endDay, startMonth, endMonth, continuousPeriod } = useMemo(() => {
		switch (dateFormat) {
			case Dateformat.Days:
				return {
					startDay: value.startDate,
					endDay: value.endDate,
				};
			case Dateformat.Continuous:
				return {
					continuousPeriod: value.continuousPeriod,
				};
			case Dateformat.Months:
			case Dateformat.Quarters:
			default:
				return {
					startMonth: value.startDate,
					endMonth: value.endDate,
				};
		}
	}, [dateFormat, value.continuousPeriod, value.endDate, value.startDate]);

	useOnClickOutside(rootRef, disableClickOutside ? undefined : handleClose);

	const toggleButtonOptions = useMemo(() => {
		let options = [{ value: Dateformat.Months, text: t('period') }];

		if (enableQuarterPeriodSelection) {
			options.push({ value: Dateformat.Quarters, text: t('quarter') });
		}

		if (enableContinuousPeriodSelection) {
			options.push({ value: Dateformat.Continuous, text: t('continuous') });
		}

		if (enableDaysSelection) {
			options.push({ value: Dateformat.Days, text: t('date') });
		}

		return options;
	}, [enableContinuousPeriodSelection, enableDaysSelection, enableQuarterPeriodSelection, t]);

	const typeSelectorOptions = useMemo(
		() =>
			toggleButtonOptions.map(({ value, text }) => ({
				text,
				onClick: () => setDateFormat(value),
				isActive: dateFormat === value,
			})),
		[toggleButtonOptions, dateFormat]
	);

	const renderDateToggleButton = typeSelectorOptions.length > 1;

	return (
		<>
			{!!open && (
				<PopupArea
					ref={rootRef}
					data-test="date-selector-popup"
					className="date-selector-popup"
				>
					<Header>
						<label>{t(popupHeaderTitle)}</label>
						{renderDateToggleButton && <TypeSelector options={typeSelectorOptions} />}
					</Header>
					{dateFormat === Dateformat.Months && (
						<MonthRange
							onDatesChange={({ startDate, endDate }) => {
								setDateFormat(dateFormat);
								onChange({
									format: dateFormat,
									startDate,
									endDate,
									...(enableContinuousPeriodSelection && {
										continuousPeriod:
											!startDate || !endDate ? value?.continuousPeriod : null,
									}),
								});
							}}
							startDate={value.format === dateFormat ? startMonth : undefined}
							endDate={value.format === dateFormat ? endMonth : undefined}
							cutOffDate={cutOffDate}
							allowPastDates={allowPastDates}
							allowMultipleMonths={allowMultipleMonths}
							isMonthDisabled={isMonthDisabled}
							ResetIcon={ResetIcon}
							shouldResetSelection={shouldResetSelection}
						/>
					)}
					{dateFormat === Dateformat.Quarters && (
						<QuartersRange
							startDate={value.format === dateFormat ? startMonth : undefined}
							endDate={value.format === dateFormat ? endMonth : undefined}
							onDatesChange={({ startDate, endDate }) => {
								setDateFormat(dateFormat);
								onChange({
									format: dateFormat,
									startDate,
									endDate,
									...(enableContinuousPeriodSelection && {
										continuousPeriod:
											!startDate || !endDate ? value?.continuousPeriod : null,
									}),
								});
							}}
							ResetIcon={ResetIcon}
							isQuarterDisabled={isMonthDisabled}
							shouldResetSelection={shouldResetSelection}
						/>
					)}
					{dateFormat === Dateformat.Days && (
						<DateRange
							isDateDisabled={isDateDisabled}
							onDatesChange={({ startDate, endDate }) => {
								setDateFormat(dateFormat);
								onChange({ format: dateFormat, startDate, endDate });
							}}
							startDate={value.format === dateFormat ? startDay : undefined}
							endDate={value.format === dateFormat ? endDay : undefined}
							cutOffDate={cutOffDate}
							initialVisibleMonth={initialVisibleMonth}
							allowPastDates={allowPastDates}
							ResetIcon={ResetIcon}
						/>
					)}
					{dateFormat === Dateformat.Continuous && (
						<ContinuousRange
							continuousPeriod={continuousPeriod}
							onContinuousPeriodChange={({ continuousPeriod }) => {
								setDateFormat(dateFormat);
								onChange({ format: dateFormat, continuousPeriod });
								onClose?.();
							}}
						/>
					)}
				</PopupArea>
			)}
		</>
	);
};

export const PopupAreaComponent = styled.div`
	z-index: 2;
	position: absolute;
	top: calc(100% + 15px);
	min-width: 324px;
	background: var(--date-picker-background);
	border-radius: var(--large-border-radius);

	&::before {
		content: ' ';
		position: absolute;
		display: block;
		width: 0;
		height: 0;
		border-width: 0 11px 11px 11px;
		border-style: solid;
		border-color: transparent transparent var(--date-picker-background);
		top: -10px;
		left: 16px;
	}

	& button.DayPickerKeyboardShortcuts_buttonReset {
		display: none;
	}

	& .ui.small.toggle.buttons .button {
		min-height: 28px;
	}
`;

const Header = styled.header`
	${h500};
	padding: 16px 24px 8px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: var(--date-picker-background);
	border-top-left-radius: var(--large-border-radius);
	border-top-right-radius: var(--large-border-radius);

	& > label {
		color: var(--text-color-default) !important;
	}
`;
