/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { media } from 'src/_helpers';
import { StrokeButton } from 'src/components/Buttons/StrokeButton';
import { OrderFile } from 'src/components/OrderFile/OrderFile';
import { withUpload } from 'src/containers/Order/PhysicalOrder/Groups/AdditionalInfo/UploadedFile';

export {
	SectionContainer,
	SectionTitle,
	FilesContainer,
} from 'src/components/ViewOrderDrawer/styled';

export const UploadButton = styled(StrokeButton)`
	position: absolute;
	top: 17px;
	right: 0;
	border-width: 1px;
	width: 190px;

	${media.ll`
        top: 21px;
    `}

	${media.xl`
        top: 26px;
	`}

	&:hover,
	&:focus {
		border-color: var(--neutral-200);
	}

	input {
		position: absolute;
		height: 100%;
		width: 100%;
		bottom: 0;
		left: 0;
		right: 0;
		top: 0;
		opacity: 0;
		z-index: 1;
		cursor: pointer;
	}

	svg {
		color: var(--primary-400);
		width: 16px;
		height: 16px;
	}
`;

export const UploadedFile = styled(withUpload(OrderFile))`
	display: flex;
	align-items: center;
	height: 21px;
	gap: 10px;

	svg,
	button,
	.spinner {
		width: 16px;
		height: 16px;
		margin-right: 0;
	}

	div:hover {
		h4 {
			cursor: pointer;
			text-decoration: underline;
		}
	}

	button {
		padding: 0;
		color: var(--neutral-300);

		&:hover {
			color: var(--white);
		}
	}

	h4 {
		max-width: 230px;

		${media.ll`
			max-width: 265px;
		`}

		${media.xl`
			max-width: 325px;
		`}
	}
`;
