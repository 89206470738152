/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled, { css, keyframes } from 'styled-components';
import { h400 } from 'src/components/Typography';
import { media } from 'src/_helpers';
import { Link } from 'src/components/Link/Link';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const buttonStyle = css`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	user-select: none;
	border-radius: 8px;
	width: 100%;
	height: 36px;
	color: var(--white);
	${h400}
	gap: 10px;

	${({ $fixedPadding }) =>
		$fixedPadding &&
		`
			width: auto;
			padding-left: 16px;
			padding-right: 16px;
		`}

	&.disabled,
	&:disabled {
		background: var(--neutral-800);
		color: var(--neutral-300);
		pointer-events: none;
	}

	&.loading {
		pointer-events: none;
		> svg {
			animation: ${spin} 0.6s linear;
			animation-iteration-count: infinite;
		}
	}

	&.large {
		height: 56px;
	}

	&.small {
		height: 36px;
	}

	${media.ll`
		${({ $fixedPadding }) =>
			$fixedPadding &&
			`
			padding-left: 24px;
			padding-right: 24px;
		`}
	`}

	${media.xl`
		height: 40px;
	`}
`;

export const Button = styled.button`
	${buttonStyle}
`;

export const StyledLink = styled(Link)`
	${buttonStyle}
`;

export const IconWrapper = styled.span`
	display: block;
	margin-right: 10px;

	svg {
		display: block;
		width: 16px;
		height: 16px;
	}
`;
