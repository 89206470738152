/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';

export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';

export const LOGOUT_USER_REQUEST = 'LOGOUT_USER_REQUEST';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_ERROR = 'LOGOUT_USER_ERROR';

export const LOGIN_USER_SHOW_2FA = 'LOGIN_USER_SHOW_2FA';
export const LOGIN_SET_SELECTED_2FA_METHOD = 'LOGIN_SET_SELECTED_2FA_METHOD';
export const LOGIN_2FA_INCORRECT_CODE = 'LOGIN_2FA_INCORRECT_CODE';

export const LOGIN_USER_SHOW_TERMS_AND_CONDITIONS = 'LOGIN_USER_SHOW_TERMS_AND_CONDITIONS';

export const RESET_USER_CONFIRM_REQUEST = 'RESET_USER_CONFIRM_REQUEST';
export const RESET_USER_CONFIRM_SUCCESS = 'RESET_USER_CONFIRM_SUCCESS';
export const RESET_USER_CONFIRM_ERROR = 'RESET_USER_CONFIRM_ERROR';

export const IS_USER_LOGGED_IN_REQUEST = 'IS_USER_LOGGED_IN_REQUEST';
export const USER_IS_LOGGED_IN = 'USER_IS_LOGGED_IN';
export const USER_IS_NOT_LOGGED_IN = 'USER_IS_NOT_LOGGED_IN';

export const RESET_USER_ERRORS = 'RESET_USER_ERRORS';
export const SET_AVATAR_COLOR = 'SET_AVATAR_COLOR';
export const SET_COMPANY_NAME = 'SET_COMPANY_NAME';
export const SET_COMPANY_AVATAR_COLOR = 'SET_COMPANY_AVATAR_COLOR';

export const UPDATE_USER_NAME = 'UPDATE_USER_NAME';
