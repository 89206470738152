/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { ReactComponent as MessageIcon } from 'src/assets/icons/messages.svg';
import { EventBusEvent } from 'src/components/EventBus/events';
import { useEmit } from 'src/shared/useEmit';
import { createStringContact } from 'src/_api';
import { useTradeLocation } from 'src/_helpers/useTradeLocation';
import * as Styled from './styled';

export const MessageButton = ({ userId, contacts }) => {
	const { t } = useTranslation();
	const { tradeId } = useTradeLocation();
	const queryClient = useQueryClient();

	const openChatConversation = useEmit(EventBusEvent.ChatOpen, {
		userId,
	});

	const isInitialUserContact = !!contacts.find(contact => contact?.contact_user_id === userId);

	const { mutate: createStringContactMutation } = useMutation(createStringContact, {
		onSuccess: () => {
			queryClient.invalidateQueries(['my-contacts']);
			openChatConversation();
		},
	});

	const messageButtonHandler = useCallback(() => {
		if (isInitialUserContact) {
			openChatConversation();
		} else {
			createStringContactMutation({ tradeId, contact_user_id: userId });
		}
	}, [isInitialUserContact, createStringContactMutation, userId, openChatConversation, tradeId]);

	return (
		<Styled.MessageButton fixedPadding onClick={messageButtonHandler}>
			<MessageIcon />
			<span>{t('message')}</span>
		</Styled.MessageButton>
	);
};
