/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useWatchPaperFields } from '../../paper/useWatchPaperFields';
import NumberFormat from 'react-number-format';
import * as Styled from './styled';

export const TotalQuantity = ({ path, shouldRender }) => {
	const { t } = useTranslation();
	const { totalVolumeValue } = useWatchPaperFields(path);

	if (!shouldRender) {
		return null;
	}

	return (
		<Styled.Wrapper>
			<Styled.Label>{t('total_quantity')}</Styled.Label>
			<Styled.Value data-test="total-quantity">
				{totalVolumeValue ? (
					<>
						<NumberFormat
							thousandSeparator
							displayType="text"
							value={totalVolumeValue}
						/>{' '}
						{t('price_unit_short_metric_ton')}
					</>
				) : (
					'---'
				)}
			</Styled.Value>
		</Styled.Wrapper>
	);
};
