/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { once } from 'src/_helpers/once';
import { FlagNames } from 'src/constants/flags';
import { isHeadlessRoute } from 'src/constants/routes';
import { useFeatureFlags } from 'src/featureFlags/FeatureFlagsContext';
import { useSettings } from 'src/components/Menus/UserProfileDropdown/useSettings';

const loadChat = async ({ featureFlagsContext, navigate, defaultMarket }) => {
	const { default: chat } = await import('../index');
	chat({ featureFlagsContext, navigate, defaultMarket });
};

const loadChatOnce = once(loadChat);

const useChatLoader = () => {
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const featureFlagsContext = useFeatureFlags();
	const { isFlagEnabled } = featureFlagsContext;
	const { defaultMarket } = useSettings();

	useEffect(() => {
		if (isFlagEnabled(FlagNames.Chat) && !isHeadlessRoute(pathname)) {
			(async () => {
				try {
					await loadChatOnce({ featureFlagsContext, navigate, defaultMarket });
				} catch (err) {
					console.error("Can't load chat", err);
				}
			})();
		}
	}, [featureFlagsContext, isFlagEnabled, pathname, navigate, defaultMarket]);
};

export const ChatLoader = () => {
	useChatLoader();
};
