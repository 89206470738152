/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
	Modal,
	ButtonsWrapper,
	ModalIconWrapper,
	ModalHeaderWrapper,
	ModalTitle,
	ModalSubTitle,
} from 'src/components/Modal';
import { ModalSize, ModalType } from 'src/constants/modal';
import { useShowCongratulations } from './useShowCongratulations';
import * as Styled from './styled';

export const CongratulationsModal = () => {
	const { t } = useTranslation();
	const { close, isOpen } = useShowCongratulations();

	return (
		<Modal
			data-test="congratulations-modal"
			size={ModalSize.Tiny}
			close={close}
			isOpen={isOpen}
		>
			<ModalHeaderWrapper>
				<ModalIconWrapper type={ModalType.Warning} />
				<ModalTitle>{t('congratulations')}</ModalTitle>
				<ModalSubTitle style={{ marginBottom: '40px' }}>
					{t('You_have_successfully_completed_this_trade')}
				</ModalSubTitle>
			</ModalHeaderWrapper>
			<ButtonsWrapper>
				<Styled.CloseButton onClick={close}>{t('close')}</Styled.CloseButton>
			</ButtonsWrapper>
		</Modal>
	);
};
