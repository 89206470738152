/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { FieldContainer } from 'src/components/CreateOrderDrawer/components/FoldableSection/styled';
import { h200, h400medium } from 'src/components/Typography';
import { media } from 'src/_helpers';

export const Wrapper = styled.div`
	padding-top: 12px;

	${media.xl`
		padding-top: 16px;
	`};
`;

export const SpecsListContent = styled.div`
	&:not(:empty) {
		margin-bottom: 12px;

		${media.xl`
			margin-bottom: 16px;
		`}
	}
`;

export const SpecsList = styled(FieldContainer)`
	grid-template-columns: repeat(4, minmax(0, 1fr));
	grid-gap: 8px;

	${media.xl`
		grid-gap: 8px 16px;
	`}
`;

export const Label = styled.div`
	${h200}
	font-size: 10px;
	color: var(--neutral-100);
	padding: 3px 0;
`;

export const Value = styled.div`
	color: var(--text-color-default);
	white-space: nowrap;
`;

export const Spec = styled.div`
	${h400medium}
	color: var(--neutral-200);
	display: flex;
	align-items: center;
	gap: 0.4em;
`;
