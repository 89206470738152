/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router';

export const useShowCongratulations = () => {
	const { state = {}, pathname } = useLocation();
	const navigate = useNavigate();

	const isOpen = state?.showCongratulations;

	const open = useCallback(() => {
		navigate(pathname, { replace: true, state: { showCongratulations: true } });
	}, [navigate, pathname]);

	const close = useCallback(() => {
		navigate(pathname, { replace: true, state: { showCongratulations: false } });
	}, [navigate, pathname]);

	return { isOpen, open, close };
};
