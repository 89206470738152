/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { OverflowText } from 'src/components/Tooltip/OverflowText';
import {
	SectionContainer,
	SectionTitleWithInfo,
	SectionTitleInfo,
	SectionGrid,
	SectionGridContent,
	SectionGridLabel,
	SectionGridText,
} from 'src/components/ViewOrderDrawer/styled';
import { isNil } from 'src/_helpers';

export const SpreadLegRow = ({ legType, data, isBrokerOrder }) => {
	const { t } = useTranslation();

	return (
		<SectionContainer data-test={`${legType}_section`}>
			<SectionTitleWithInfo>
				{t(legType)}
				{isBrokerOrder && (
					<SectionTitleInfo>{t('external_brokerage_fees_may_apply')}</SectionTitleInfo>
				)}
			</SectionTitleWithInfo>
			<SectionGrid>
				{data.map(({ label, value, wide, changed }) => (
					<SectionGridContent key={label} className={clsx({ changed, wide })}>
						<SectionGridLabel>{label}</SectionGridLabel>
						<SectionGridText
							data-test={`${legType}_${label.toLowerCase().replaceAll(' ', '-')}`}
						>
							{wide ? (
								value
							) : isNil(value) ? (
								t('not_provided')
							) : (
								<OverflowText>{value}</OverflowText>
							)}
						</SectionGridText>
					</SectionGridContent>
				))}
			</SectionGrid>
		</SectionContainer>
	);
};
