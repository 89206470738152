/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AcceptOrderModal as AcceptPhysicalOrderModal } from 'src/containers/OrderDetails/components/AcceptOrderModal';
import { AcceptOrderModal as AcceptPaperOrderModal } from 'src/containers/OrderDetails/sections/paper/AcceptOrder';
import { useOrderDetails } from 'src/_helpers/useOrderDetails';
import { useAcceptOrderAsBroker } from 'src/containers/OrderDetails/useAcceptOrderAsBroker';
import { Tooltip, TooltipTrigger, TooltipContent } from 'src/components/Tooltip/Tooltip';
import * as Styled from './styled';

export const AcceptOrderButton = ({ isVisible, order, hasOrderUpdated }) => {
	const { t } = useTranslation();
	const { userHasBrokerRole } = useAcceptOrderAsBroker(order, false);

	const {
		isPaperOrder,
		isIndicativeOrder,
		mutualContactApproval,
		orderOwnerPrincipal,
	} = useOrderDetails(order);

	const isOrderOwnerPrincipalNotProvided =
		orderOwnerPrincipal.hidden === undefined && !orderOwnerPrincipal.user;

	const canAcceptFirmOrder = (!isIndicativeOrder && mutualContactApproval) || userHasBrokerRole;

	// TODO:  Unify the AcceptOrderModal component so only the content varies based on order market
	const AcceptOrderModalComponent = isPaperOrder
		? AcceptPaperOrderModal
		: AcceptPhysicalOrderModal;

	if (!isVisible) {
		return null;
	}

	if (canAcceptFirmOrder) {
		return (
			<AcceptOrderModalComponent
				order={order}
				submitButtonId="confirm-accept-physical-order-button"
				trigger={
					<Styled.AcceptButton data-test="accept-order-button" disabled={hasOrderUpdated}>
						{t('accept')}
					</Styled.AcceptButton>
				}
			/>
		);
	}

	return (
		<Tooltip>
			<TooltipTrigger>
				<Styled.InactiveTooltipAcceptButton>
					{t('accept')}
				</Styled.InactiveTooltipAcceptButton>
			</TooltipTrigger>
			<TooltipContent>
				{isOrderOwnerPrincipalNotProvided
					? userHasBrokerRole
						? t('cannot_accept_order_without_principal_as_broker')
						: t('cannot_accept_order_without_principal')
					: t('firm_exchange_order_accept_button_info')}
			</TooltipContent>
		</Tooltip>
	);
};
