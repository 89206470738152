/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { SectionWrapper } from '../../../components/FoldableSection/styled';
import { SingleOrderRow } from './SingleOrderRow';
import { toPath } from 'src/_helpers';
import { AddAnotherOrderButton } from 'src/components/CreateOrderDrawer/components/AddAnotherOrderButton/AddAnotherOrderButton';
import { useWatchPaperFields } from '../../useWatchPaperFields';
import { contractPricingPath } from '../../model';
import { usePaperOrderContext } from '../../CreatePaperOrderDrawerContent';
import { PaperSpecification } from './PaperSpecification/PaperSpecification';
import * as Styled from './styled';

export const ContractAndPricingSection = ({
	path = contractPricingPath,
	onRowCountChange = () => {},
}) => {
	const { fields = [], append, remove } = useFieldArray({
		name: path,
	});

	const { isEditing } = useWatchPaperFields();
	const { selectedPreset } = usePaperOrderContext();

	const canCreateMultipleOrders = !isEditing;

	const handleAppendRow = params => {
		append(params);
		onRowCountChange(fields.length + 1);
	};

	const handleRemoveRow = index => {
		remove(index);
		onRowCountChange(fields.length - 1);
	};

	const specs = selectedPreset?.grade?.gradespecs || null;

	return (
		<SectionWrapper>
			{fields.map((field, index) => {
				return (
					<React.Fragment key={index}>
						{index > 0 && fields.length > 1 && <Styled.CreateOrderDrawerDivider />}
						<SingleOrderRow
							path={toPath(path, index)}
							field={field}
							showRowNumbers={fields.length > 1}
							rowNumber={index + 1}
							onRemove={handleRemoveRow}
							canRemove={fields.length > 1 && index !== 0}
						/>
						{index === 0 && specs && (
							<>
								<Styled.FieldsSeparator />
								<PaperSpecification specs={specs} />
							</>
						)}
					</React.Fragment>
				);
			})}
			{canCreateMultipleOrders && <AddAnotherOrderButton onAppend={handleAppendRow} />}
		</SectionWrapper>
	);
};
