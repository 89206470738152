/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useLayoutEffect, useRef } from 'react';
import clsx from 'clsx';
import { isNil } from 'src/_helpers';
import { Label } from '../Label/Label';
import { NumberInput } from '../NumberInput/NumberInput';
import * as Styled from './styled';

export const MaterialNumberInput = React.forwardRef(
	(
		{ label, id, required, controlledState = false, hasChanged = false, className, ...props },
		ref
	) => {
		const hasValue = !isNil(props.value);
		const filledControlledState = controlledState === true && hasChanged === false && hasValue;
		const filledState = controlledState ? hasChanged === true : hasValue;

		const wrapperRef = useRef();

		const labelClassName = `${id?.replaceAll('.', '')}_label`;

		useLayoutEffect(() => {
			if (props.parameters) {
				const parametersNode = wrapperRef.current.querySelector('input + label');
				if (parametersNode) {
					const width = parametersNode.offsetWidth;
					const label = wrapperRef.current.querySelector(`.${labelClassName}`);
					label.style.maxWidth = `calc(var(--_max_width_base) - ${width}px)`;
				}
			}
		}, [labelClassName, props.parameters]);

		const defaultInputProps = {
			thousandSeparator: true,
			fixedDecimalScale: true,
			decimalScale: 2,
			autoComplete: 'off',
			allowLeadingZero: true,
		};

		const shouldShowCheckmark = hasValue && !props.disabled;

		return (
			<Styled.InputContainer
				className={clsx(
					'number-input',
					filledState && 'input-filled',
					filledControlledState && 'input-filled-controlled',
					shouldShowCheckmark && 'with-checkmark',
					className
				)}
				ref={wrapperRef}
			>
				<NumberInput
					id={id}
					onValueChange={val => props.onChange(val.value)}
					{...defaultInputProps}
					{...props}
				/>
				<Label htmlFor={id} className={labelClassName}>
					<span>{label}</span>
					{required && <span className="asterisk">*</span>}
				</Label>
				{shouldShowCheckmark && <Styled.ValidCheckIcon />}
			</Styled.InputContainer>
		);
	}
);
