/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled, { css } from 'styled-components';
import { media } from 'src/_helpers';
import { PrimaryButton } from 'src/components/Buttons/PrimaryButton';
import { SecondaryButton } from 'src/components/Buttons/SecondaryButton';
import { DrawerFooter as BaseDrawerFooter } from 'src/components/Drawer/styled';
import { h200, h400 } from 'src/components/Typography';

const button = css`
	width: 142px;

	${media.ll`
		width: 160px;
	`}

	${media.xl`
		width: 190px;
	`}
`;

export const SubmitButton = styled(PrimaryButton)`
	${button}
	&.disabled {
		background: var(--neutral-700);
	}
`;

export const BackButton = styled(SecondaryButton)`
	${button}

	background: var(--neutral-600);
	&:hover {
		background: var(--neutral-500);
	}

	&.disabled {
		background: var(--neutral-700);
	}
`;

export const DrawerFooter = styled(BaseDrawerFooter)`
	justify-content: flex-end;
	gap: 12px;

	${media.ll`
		gap: 16px;
	`}
`;

export const Validity = styled.div`
	flex: 1;
	text-align: left;
	display: flex;
	flex-direction: column;
	gap: 2px;

	${media.ll`
		gap: 3px;
	`}

	${media.xl`
		gap: 4px;
	`}

	label {
		${h200}
		color: var(--neutral-100);
		max-width: 111px;
		min-height: 13px;

		${media.ll`
			max-width: 124px;
		`}

		${media.xl`
			max-width: 139px;
		`}
	}

	> div {
		${h400}
		color: var(--text-color-default);
	}
`;
