/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useEffect } from 'react';
import { useSettings } from 'src/components/Menus/UserProfileDropdown/useSettings';
import { usePermissionContext } from '../Permissions/PermissionsContext';
import { OrderCreationPermissions } from 'src/constants/permissions';

export const useAppTour = (version, shouldUpdateLastSeenVersion) => {
	const { lastTourVersionSeen, isLoading, saveLastSeenTourVerions, isError } = useSettings();

	const { hasPermission } = usePermissionContext();

	const hasAlreadySeenTour = lastTourVersionSeen >= version;

	useEffect(() => {
		if (!isLoading && !isError && !hasAlreadySeenTour && shouldUpdateLastSeenVersion) {
			saveLastSeenTourVerions(version);
		}
	}, [
		hasAlreadySeenTour,
		isLoading,
		saveLastSeenTourVerions,
		version,
		shouldUpdateLastSeenVersion,
		isError,
	]);

	return isLoading || isError
		? false
		: !hasAlreadySeenTour && hasPermission(OrderCreationPermissions);
};
