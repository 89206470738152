/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { createContext, useCallback, useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { useSubscribe } from 'src/shared/useSubscribe';
import { EventBusEvent } from 'src/components/EventBus/events';
import { getMyContactIds } from 'src/_api/my-account.api';

export const MyContactsIdsContext = createContext({
	isMyContact: id => false,
});

export const MyContactsIdsContextProvider = ({ children }) => {
	const [contacts, setContacts] = useState([]);

	useQuery('my-contact-ids', getMyContactIds, {
		select: data => data.contacts_ids,
		onSuccess: list => setContacts(list),
	});

	const isMyContact = useCallback(userId => contacts.includes(userId), [contacts]);

	const contactRemovedCallback = data => {
		const contactId = data.contact_user_id;
		setContacts(list => list.filter(id => id !== contactId));
	};

	useSubscribe(EventBusEvent.ContactRemoved, contactRemovedCallback);

	const contactAddedCallback = data => {
		const contactId = data.contact_user_id;
		setContacts(list => [...list, contactId]);
	};

	useSubscribe(EventBusEvent.ContactAdded, contactAddedCallback);

	return (
		<MyContactsIdsContext.Provider
			value={{
				isMyContact,
			}}
		>
			{children}
		</MyContactsIdsContext.Provider>
	);
};

export const useMyContactIds = () => useContext(MyContactsIdsContext);
