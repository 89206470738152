/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { toPath } from 'src/_helpers';
import { getShipmentCutOffDate, isNextMonthOrAfter, isAfter, Dateformat } from 'src/_helpers/date';
import { MaterialDateInput } from 'src/designSystem/Form/MaterialInput/MaterialDateInput';
import { defaultContractPricingRowValue } from 'src/components/CreateOrderDrawer/paper/model';
import { useWatchPaperFields } from 'src/components/CreateOrderDrawer/paper/useWatchPaperFields';
import * as Styled from '../../styled';

const SecondLegShipmentField = ({ path, t }) => {
	const { setValue } = useFormContext();
	const { deliveryValue, secondLegDeliveryValue } = useWatchPaperFields(path);

	useEffect(() => {
		if (
			deliveryValue?.endDate &&
			secondLegDeliveryValue?.startDate &&
			!isAfter(secondLegDeliveryValue.startDate, deliveryValue?.endDate)
		) {
			setValue(
				toPath(path, 'secondLegDelivery'),
				defaultContractPricingRowValue.secondLegDelivery
			);
		}
	}, [deliveryValue, path, secondLegDeliveryValue, setValue]);

	return (
		<Controller
			mandatory
			name={toPath(path, 'secondLegDelivery')}
			defaultValue={null}
			render={({ field, fieldState: { error } }) => (
				<Styled.CalendarWrapper>
					<MaterialDateInput
						{...field}
						required
						id={field.name}
						dataTest={field.name}
						label={t('second_leg_shipment')}
						error={!!error}
						cutOffDate={getShipmentCutOffDate()}
						isMonthDisabled={month =>
							!isNextMonthOrAfter(month, deliveryValue?.endDate)
						}
						enableQuarterPeriodSelection
						defaultDateFormat={Dateformat.Quarters}
					/>
				</Styled.CalendarWrapper>
			)}
		/>
	);
};

export const secondLegShipmentField = (path, t) => <SecondLegShipmentField path={path} t={t} />;
