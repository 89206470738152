/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled, { css } from 'styled-components';
import { media } from 'src/_helpers/media-queries';
import { encodeContentUrl } from 'src/_helpers/encodeContentUrl';
import { ReactComponent as CloseIcon } from 'src/assets/icons/close.svg';
import { h300, h400 } from 'src/components/Typography/fonts';

export const Wrapper = styled.div`
	width: 100%;

	&.clearable ~ svg {
		display: none;
	}
`;

export const Label = styled.label`
	color: var(--text-color-secondary);
	display: block;
	${h300}
	margin: 0 0 6px;
	text-transform: none;

	&.error {
		color: var(--input-error-label-text-color);
	}
`;

export const InputWrapper = styled.div`
	position: relative;

	&:hover {
		cursor: pointer !important;
	}

	button {
		position: absolute;
		inset: 0;
		z-index: 1;
		width: 100%;
		cursor: text;
	}

	label {
		position: absolute;
		inset: 0;
		padding: 10px 16px;
		padding-right: 36px;
		${h400};
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		display: flex;
		align-items: center;

		&:hover {
			cursor: pointer;
		}

		span {
			overflow: hidden;
			text-overflow: ellipsis;

			&.single {
				max-width: calc(100% - 20px);
			}

			&.multi:first-of-type {
				max-width: calc(100% - 45px);
				margin-right: 4px;
			}
		}

		i + span.single {
			max-width: calc(100% - 40px);
		}

		i + .multi:first-of-type {
			max-width: calc(100% - 65px);
		}
	}

	.search & {
		label {
			left: 28px;
		}

		input {
			margin-left: 24px;
		}
	}
`;

const icon = css`
	position: absolute;
	right: 16px;
	z-index: 2;
	cursor: pointer;
`;

export const ExpandIcon = styled.div`
	${icon}
	opacity: 0.8;
	pointer-events: none;
	top: 10px;

	${() =>
		encodeContentUrl(
			'<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.29289 5.29289C3.68342 4.90237 4.31658 4.90237 4.70711 5.29289L8 8.58579L11.2929 5.29289C11.6834 4.90237 12.3166 4.90237 12.7071 5.29289C13.0976 5.68342 13.0976 6.31658 12.7071 6.70711L8.70711 10.7071C8.31658 11.0976 7.68342 11.0976 7.29289 10.7071L3.29289 6.70711C2.90237 6.31658 2.90237 5.68342 3.29289 5.29289Z" fill="#757BA9"/></svg>'
		)};

	&.open {
		transform: rotate(180deg) !important;
	}

	${media.ll`
		top: 12px;
	`}
`;

export const ContentWrapper = styled.div`
	position: relative;
	width: 256px;
`;

export const OptionsWrapper = styled.div`
	position: absolute;
	display: flex;
	box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
	z-index: 11;
	right: 0;
	width: inherit;

	.options {
		max-height: 226px;
		min-width: 100%;
		flex-shrink: 1;
		overflow-x: hidden;
		overflow-y: auto;
		background: var(--neutral-700);
		border-radius: var(--large-border-radius);
		margin-top: 2px;
		margin-top: 6px;
	}
`;

export const OptionWrapper = styled.li`
	display: flex;
	cursor: pointer;
	height: 32px;
	display: flex;
	padding: 10px 16px 11px;
	padding-right: 36px;
	align-items: center;
	gap: 4px;
	min-width: 100%;

	&.disabled {
		pointer-events: none;
	}

	&:hover {
		background: var(--neutral-600);

		span {
			color: var(--text-color-default);
		}
	}

	span {
		${h300}
		font-size: 13px;
		color: var(--text-color-secondary);
		white-space: nowrap;
		flex: 1;
		user-select: none;
	}

	&.selected {
		background: var(--neutral-600);
		position: relative;

		span {
			color: var(--text-color-default);
			max-width: calc(100% - 70px);
			overflow: hidden;
			text-overflow: ellipsis;

			${media.xl`
				max-width: calc(100% - 50px);
			`}
		}

		&:after {
			position: absolute;
			right: 16px;
			margin-left: auto;

			${() =>
				encodeContentUrl(
					'<svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 4.6L3.93878 7L9 1" stroke="white" stroke-width="2" stroke-linecap="round" /></svg>'
				)};
		}
	}
`;

export const ClearIcon = styled(CloseIcon)`
	width: 10px;
	height: 10px;
	position: absolute;
	right: 13px;
	top: 13px;
	cursor: pointer;
	z-index: 20;

	${media.xl`
		right: 15px;
		top: 14px;
	`}
`;

export const DisabledItem = styled.div`
	${h300}
	font-size: 13px;
	color: var(--text-color-secondary);
	white-space: nowrap;
	flex: 1;
	user-select: none;
	padding: 16px;
`;
