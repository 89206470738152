/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useInRouterContext } from 'react-router';
import { OrderCreationPermissions } from 'src/constants/permissions';
import { ROUTES } from 'src/constants/routes';
import { Permission } from 'src/containers/Permissions/Permission';
import { useAppNavigate } from 'src/shared/useAppNavigate';
import { EventBusEvent } from 'src/components/EventBus/events';
import { useEmit } from 'src/shared/useEmit';
import { DrawerContextKeys, useDrawerContext } from 'src/components/Drawer/DrawerContext';
import * as Styled from './styled';

const Button = ({ userIds, groupId, disabled, onClick, dataTest }) => {
	const { t } = useTranslation();
	const isInRouterContext = useInRouterContext();
	const { navigate } = useAppNavigate();
	const closeChat = useEmit(EventBusEvent.ChatClose);

	const navigateState = {
		openCreateOrder: true,
		selectedContacts: userIds?.length ? userIds : undefined,
		selectedGroups: groupId ? [groupId] : undefined,
	};

	const handleClick = event => {
		event.preventDefault();
		onClick?.();
		closeChat();
		navigate(ROUTES.root, {
			state: navigateState,
		});
	};

	if (disabled) {
		return (
			<Styled.OTCspan data-test="disabled-otc-button" className="disabled-otc-button">
				{t('otc_button')}
			</Styled.OTCspan>
		);
	}

	const linkDataTest = dataTest || 'contact-otc-button';

	if (!isInRouterContext) {
		return (
			<Styled.OTCButton href={ROUTES.root} data-test={linkDataTest} onClick={handleClick}>
				{t('otc_button')}
			</Styled.OTCButton>
		);
	}

	return (
		<Styled.OTCLink
			to={ROUTES.root}
			state={navigateState}
			data-test={linkDataTest}
			onClick={onClick}
		>
			{t('otc_button')}
		</Styled.OTCLink>
	);
};

export const OTCButton = ({ close, ...props }) => {
	const {
		visibility: [, { close: closeDrawer }],
	} = useDrawerContext()[DrawerContextKeys.viewDrawer];

	return (
		<Permission
			name={OrderCreationPermissions}
			renderOn={() => (
				<Button
					{...props}
					onClick={() => {
						closeDrawer?.();
						close?.();
					}}
				/>
			)}
			renderOff={() => <Button {...props} disabled />}
		/>
	);
};
