/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useEmit } from 'src/shared/useEmit';
import { ConversationTopicType, ConversationType } from 'src/chat/constants';
import { EventBusEvent } from '../EventBus/events';
import { H700 } from 'src/components/Typography';
import * as Styled from './styled';
import { useParams } from 'react-router';
import { useConversationTopicsCountQuery } from 'src/chat/api/conversations';

const FooterContent = ({ type }) => {
	const { t } = useTranslation();
	const { tradeId, conversationId } = useParams();

	const openChat = useEmit(EventBusEvent.ChatOpen, {
		type,
		groupId: `${type}/${type === ConversationType.Execution ? tradeId : conversationId}`,
	});

	const { data } = useConversationTopicsCountQuery({
		tagId: type === ConversationType.Execution ? tradeId : conversationId,
		type: ConversationTopicType[type],
	});

	return (
		<>
			<H700>
				{t('total_topics', {
					count: data?.count || 0,
				})}
			</H700>
			<Styled.ShowChatButton disabled={!data?.count} onClick={openChat}>
				{t('show_chat')}
			</Styled.ShowChatButton>
		</>
	);
};

const withMessagesFooter = type => {
	const WrapperComponent =
		type === ConversationType.Execution ? Styled.ExecutionWrapper : Styled.KYCWrapper;

	return props => (
		<WrapperComponent {...props}>
			<FooterContent type={type} />
		</WrapperComponent>
	);
};

export const ExecutionMessagesFooter = withMessagesFooter(ConversationType.Execution);
export const KYCMessagesFooter = withMessagesFooter(ConversationType.KYC);
