/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useEmit } from 'src/shared/useEmit';
import { EventBusEvent } from '../EventBus/events';
import { OnboardingFormId, OnboardingFormWithLogo } from 'src/constants/user';
import { OnboardingHeader } from 'src/components/OnboardingHeader/OnboardingHeader';
import * as Styled from './styled';

export const OnboardingLayout = ({
	children,
	formTestId,
	smallLogo = false,
	onLogoClick,
	className = '',
}) => {
	const emit = useEmit();

	useEffect(() => {
		emit(EventBusEvent.ChatClose);
	}, [emit]);

	return (
		<>
			{formTestId === OnboardingFormId.Login ? (
				<OnboardingHeader />
			) : (
				<Styled.LanguageDropdown />
			)}
			<Styled.LayoutWrapper id="layout-wrapper">
				<Styled.WhiteBoxWrapper>
					<Styled.WhiteBox data-test={formTestId} className={className}>
						{OnboardingFormWithLogo.includes(formTestId) && (
							<Link to="/" onClick={onLogoClick}>
								<Styled.Logo className="onboarding-logo" $smallLogo={smallLogo} />
							</Link>
						)}
						{children}
					</Styled.WhiteBox>
				</Styled.WhiteBoxWrapper>
			</Styled.LayoutWrapper>
		</>
	);
};
