/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useCallback } from 'react';
import i18n from 'i18next';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useSettingsQuery } from 'src/_api/queries';
import { updateSettings, mapThemeToColors } from 'src/_api/settings.api';
import { useToast } from 'src/components/Toast';
import { LanguageThemes } from 'src/constants/settings';
import { getBuyColor, getSellColor, useAdjustedChartSettings } from 'src/_helpers/chart';
import { useChartState } from 'src/containers/Dashboard/Chart/useChartState';
import { useUserPresenceContext } from 'src/websockets/UserPresenceProvider';
import { useDispatch, useSelector } from 'react-redux';
import { getUserId } from 'src/_store/selectors';
import { captureError } from 'src/_helpers/error';
import { setUserAvatarColor } from 'src/_store/actions';
import { chartStateStorageKey } from 'src/constants/storageKeys';

const DefaultAvatarColor = 'var(--neutral-400)';

export const useSettings = () => {
	const { t } = useTranslation();
	const userId = useSelector(getUserId);
	const queryClient = useQueryClient();
	const { addToast } = useToast();
	const { chartState, saveChartState } = useChartState(chartStateStorageKey);
	const { setSingleUserPresence } = useUserPresenceContext();
	const dispatch = useDispatch();

	const {
		data: {
			theme: currentTheme,
			isOnline,
			avatarColor = DefaultAvatarColor,
			isChatInfoVisible,
			supportConversationId,
			defaultMarket,
			lastTourVersionSeen,
			defaultPaperContractID,
		} = {},
		isRefetching,
		isLoading,
		isError,
	} = useSettingsQuery({
		onSuccess: ({ avatarColor }) => dispatch(setUserAvatarColor(avatarColor)),
	});

	const { mutate } = useMutation(updateSettings, {
		onSuccess: async (res, req) => {
			if (req.body?.last_tour_version_seen) {
				return;
			}
			await queryClient.invalidateQueries('settings');
		},
		onError: () => {
			addToast({
				message: t('settings_update_failed'),
				kind: 'error',
			});
		},
	});

	const { adjustChartColors } = useAdjustedChartSettings(chartState, saveChartState);

	const updateTheme = useCallback(
		newTheme => {
			window?.tvWidget?.onChartReady(() => {
				const buyColor = getBuyColor(newTheme);
				const sellColor = getSellColor(newTheme);

				adjustChartColors(buyColor, sellColor);
			});

			mutate({ body: { ...mapThemeToColors[newTheme] } });
		},
		[mutate, adjustChartColors]
	);

	const hideVisibility = useCallback(
		hide => {
			mutate(
				{ body: { hide_visibility: hide } },
				{
					onSuccess: () => {
						setSingleUserPresence(userId, hide);
					},
				}
			);
		},
		[userId, mutate, setSingleUserPresence]
	);

	const switchToLanguage = language => () => {
		moment.locale(language.moment);
		i18n.changeLanguage(language.code, err => {
			if (err) captureError(err, 'warning');
		});

		const option = /colorblind/i.test(currentTheme) ? 'colorblind' : 'default';
		if (LanguageThemes[language.code] && LanguageThemes[language.code][option]) {
			updateTheme(LanguageThemes[language.code][option]);
		}
	};

	const hideChatInfo = useCallback(() => {
		mutate({
			body: {
				chat_hide_info_message: true,
			},
		});
	}, [mutate]);

	const saveLastSeenTourVerions = useCallback(
		version => {
			mutate({
				body: {
					last_tour_version_seen: version,
				},
			});
		},
		[mutate]
	);

	return {
		isRefetchingSettings: isRefetching,
		isLoading,
		defaultMarket,
		currentTheme,
		isOnline,
		avatarColor,
		updateTheme,
		hideVisibility,
		switchToLanguage,
		isChatInfoVisible,
		hideChatInfo,
		supportConversationId,
		lastTourVersionSeen,
		saveLastSeenTourVerions,
		defaultPaperContractID,
		isError,
	};
};
