/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { PaperInstruments, SpreadPriceFormat } from 'src/constants/contract';
import { PricePill } from './PricePrill/PricePill';
import { SpreadPricePill } from './PricePrill/SpreadPricePill';
import { IncoPortPill } from './IncoPortPill/incoPortPill';
import { ShipmentPill } from './ShipmentPill/ShipmentPill';
import { MonthSpreadPill } from './MonthSpreadPill/MonthSpreadPill';
import { TopBarInfo } from './OrderViews/TopBarInfo';
import * as Styled from './styled';

export const PaperKeyDetailsSection = ({ order, changes = {}, isLastCounter = false }) => {
	const isCounter = !!changes;
	const isTraded = !!order.trade_id;
	const isSpread = order.instrument === PaperInstruments.Spread;
	const isPayCashSpread = order.spread_details?.price_format === SpreadPriceFormat.PayCash;

	const isSpreadPricePillChanged = isPayCashSpread
		? changes.price || changes.spreadType
		: changes.price;

	return (
		<>
			<TopBarInfo
				order={order}
				isCounter={isCounter}
				isLastCounter={isLastCounter}
				isTraded={isTraded}
			/>
			<Styled.PaperContainer data-test="key-details-section">
				<Styled.TopPills $isSpread={isSpread}>
					<IncoPortPill order={order} />
					{isSpread ? (
						<>
							<MonthSpreadPill order={order} />
							<SpreadPricePill order={order} changed={isSpreadPricePillChanged} />
						</>
					) : (
						<>
							<ShipmentPill order={order} />
							<PricePill order={order} changed={changes.price} />
						</>
					)}
				</Styled.TopPills>
			</Styled.PaperContainer>
		</>
	);
};
