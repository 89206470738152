/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useState, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { userAuthLoggedIn, getUserId } from 'src/_store/selectors';
import { listMyNewTrades } from 'src/_api/trades.api';
import { MyNewTradesItems } from './MyNewTradesItems';
import { parseMyNewTrades } from './parseMyNewTrades';
import { useSubscribe } from 'src/shared/useSubscribe';
import { EventBusEvent } from 'src/components/EventBus/events';

const shouldIgnoreRoute = url => url.startsWith('/execution/trade/');

export const MyNewTrades = () => {
	const { pathname } = useLocation();
	const isLoggedIn = useSelector(userAuthLoggedIn);

	if (!isLoggedIn || shouldIgnoreRoute(pathname)) {
		return null;
	}

	return <MyNewTradesContent />;
};

const MyNewTradesContent = () => {
	const [hiddenItems, setHiddenItems] = useState([]);
	const userId = useSelector(getUserId);

	const { data = [], refetch: getMynewTrades } = useQuery(
		['list_my_new_trades', userId],
		listMyNewTrades
	);

	useSubscribe(EventBusEvent.NewTrade, () => {
		getMynewTrades();
	});

	const parsedConfirmations = useMemo(() => {
		return parseMyNewTrades(data).filter(({ url }) => !hiddenItems.includes(url));
	}, [data, hiddenItems]);

	const handleToastClick = useCallback(url => {
		setHiddenItems(prevItems => [...prevItems, url]);
	}, []);

	if (!data.length) {
		return <div data-test="my-new-trades-no-items" />;
	}

	return <MyNewTradesItems items={parsedConfirmations} onClick={handleToastClick} />;
};
