/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useEffect } from 'react';

/**
 * params [eventName]
 */
export const useSubscribe = (eventName, callback) => {
	useEffect(() => {
		if (typeof eventName !== 'string') {
			throw new Error('useSubscribe parameter "eventName" must be a string.');
		}

		const cbWrapper = ({ detail }) => callback(detail);
		document.addEventListener(eventName, cbWrapper);

		return () => document.removeEventListener(eventName, cbWrapper);
	}, [eventName, callback]);
};
