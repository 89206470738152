/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { searchSetMarket } from 'src/_store/actions';
import { Market } from 'src/constants/contract';
import { activeMarketKey } from 'src/constants/storageKeys';

export const MarketContext = createContext({
	market: null,
	setMarket: () => {},
});

export const MarketProvider = ({ children, defaultMarket }) => {
	const dispatch = useDispatch();

	const [market, setMarket] = useState(() => {
		const storedValue = window.localStorage.getItem(activeMarketKey);
		return storedValue;
	});

	const setValue = useCallback(
		value => {
			window.localStorage.setItem(activeMarketKey, value);

			setMarket(value);
			dispatch(searchSetMarket(value));
		},
		[dispatch]
	);

	useEffect(() => {
		const notValidMarket = !market || !Object.values(Market).includes(market);
		if (notValidMarket && defaultMarket) {
			setValue(defaultMarket);
		}
	}, [defaultMarket, market, setValue]);

	const handleStorageChange = useCallback(
		({ key, storageArea, newValue }) => {
			if (key !== activeMarketKey || storageArea !== window.localStorage) {
				return;
			}

			setValue(newValue);
		},
		[setValue]
	);

	useEffect(() => {
		window.addEventListener('storage', handleStorageChange);

		return () => window.removeEventListener('storage', handleStorageChange);
	}, [handleStorageChange]);

	if (!market) {
		return null;
	}

	return <MarketContext.Provider value={[market, setValue]}>{children}</MarketContext.Provider>;
};

export const useMarket = () => {
	const [market, setMarket] = useContext(MarketContext);

	return [market, setMarket];
};

export const useActiveMarket = () => {
	const [market] = useMarket();
	return market;
};
