/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { sortOrder } from 'src/_helpers/sort';
import { ROUTES } from 'src/constants/routes';

export const pageSize = 20;

export const groupLimit = 12;
export const teamLimit = 12;

export const contactsDefaultSort = {
	_sort: 'name',
	_order: sortOrder.asc,
};

export const commonSortOptions = [
	{
		text: 'sort_by_name_az',
		value: 'name.ASC',
	},
	{
		text: 'sort_by_name_za',
		value: 'name.DESC',
	},
];

export const approvedSortOptions = [...commonSortOptions];

export const contactSortOptions = [
	...commonSortOptions,
	{
		text: 'sort_by_newest',
		value: 'contact_created_at.DESC',
	},
	{
		text: 'sort_by_oldest',
		value: 'contact_created_at.ASC',
	},
];

export const allUsersSortOptions = [
	...commonSortOptions,
	{
		text: 'sort_by_newest',
		value: 'user_created_at.DESC',
	},
	{
		text: 'sort_by_oldest',
		value: 'user_created_at.ASC',
	},
];

export const UserType = {
	Contacts: 'my_contacts',
	AllUsers: 'all',
	ApprovedContacts: 'approved_contacts',
	Me: 'me',
};

export const fakeContactDetails = [
	{
		label: 'phone_number',
		value: '+XX XXX XXX XXX',
		hidden: true,
	},
	{
		label: 'email',
		value: 'xxxxx@xxxxxx.xxx',
		hidden: true,
	},
];

export const MenuItems = {
	Contacts: 'contacts',
	Groups: 'groups',
	Invites: 'invites',
	Teams: 'teams',
};

export const InfoBoxItems = {
	Approved: 'approved',
	Contacts: 'contacts',
	AllUsers: 'all-users',
	Groups: 'groups',
	Teams: 'teams',
};

export const ContactSection = {
	AllUsers: 'all_users',
	Approved: 'approved',
	Contacts: 'my_contacts',
};

export const UserNetworkContactsPathsMap = [
	{
		group: MenuItems.Contacts,
		id: ContactSection.Approved,
		path: ROUTES.userNetworkApprovedContacts,
		info: InfoBoxItems.Approved,
		text: 'status_approved',
		sortOptions: approvedSortOptions,
	},
	{
		group: MenuItems.Contacts,
		id: ContactSection.Contacts,
		path: ROUTES.userNetworkMyContacts,
		info: InfoBoxItems.Contacts,
		text: 'my_contacts',
		sortOptions: contactSortOptions,
	},
	{
		group: MenuItems.Contacts,
		id: ContactSection.AllUsers,
		path: ROUTES.userNetworkAllUsers,
		info: InfoBoxItems.AllUsers,
		text: 'all_users',
		sortOptions: allUsersSortOptions,
	},
];

export const UserNetworkPathsMap = [
	...UserNetworkContactsPathsMap,
	{
		group: MenuItems.Groups,
		path: `${ROUTES.userNetwork}/${ROUTES.userNetworkGroups}`,
		info: InfoBoxItems.Groups,
	},
	{
		group: MenuItems.Teams,
		path: `${ROUTES.userNetwork}/${ROUTES.userNetworkTeams}`,
		info: InfoBoxItems.Teams,
	},
	{
		group: MenuItems.Invites,
		path: `${ROUTES.userNetwork}/${ROUTES.userNetworkInvites}`,
		info: null,
	},
];
