/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

const emit = (eventName, data = {}) => {
	if (!eventName || eventName.length === 0 || /^\.+$/gi.test(eventName)) {
		throw new Error('Emitted event with wrong params. eventName has to be a notempty string.');
	}

	const event = new CustomEvent(eventName, {
		detail: data,
	});

	document.dispatchEvent(event);
};

/**
 * return emit
 */
export const useEmit = (...params) => {
	if (params.length === 0) {
		return emit;
	}

	return emit.bind(null, ...params);
};
