/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useCallback, useState, useMemo } from 'react';
import styled from 'styled-components';
import { DateSelectorPopup } from './popup/DateSelectorPopup';
import moment from 'moment';
import { formatDateRangeInMonths, Dateformat } from 'src/_helpers/date';

export const DateSelector = ({
	value,
	opener,
	onChange,
	disabled = false,
	PopupArea,
	isDateDisabled,
	isMonthDisabled,
	cutOffDate,
	initialVisibleMonth,
	renderDateToggleButton,
	popupHeaderTitle,
	enableContinuousPeriodSelection,
	enableDaysSelection,
	enableQuarterPeriodSelection,
	defaultDateFormat,
	allowPastDates = false,
	allowMultipleMonths,
	initiallyOpen = false,
	emptyValue = null,
}) => {
	const [open, setOpen] = useState(initiallyOpen);

	const parsedValue = useMemo(
		() => ({
			startDate: value?.startDate && moment.utc(value.startDate),
			endDate: value?.endDate && moment.utc(value.endDate),
			format: value?.format || defaultDateFormat,
			continuousPeriod: value?.continuousPeriod,
		}),
		[value, defaultDateFormat]
	);
	const { startDate, endDate, format, continuousPeriod } = parsedValue;

	const onChangeDatesCallback = useCallback(
		({ format, startDate, endDate, continuousPeriod }) => {
			onChange({
				startDate: startDate?.format(),
				endDate: endDate?.format(),
				format,
				continuousPeriod,
			});
		},
		[onChange]
	);

	const onClose = useCallback(() => {
		setOpen(false);
		const isDaysFormat = format === Dateformat.Days;
		const areBothDatesSelected = !!startDate && !!endDate;

		if (isDaysFormat && !areBothDatesSelected) {
			const val = emptyValue
				? emptyValue
				: { startDate: null, endDate: null, format, continuousPeriod };
			onChange(val);
		}
	}, [format, startDate, endDate, onChange, continuousPeriod, emptyValue]);

	const handleOpen = newOpen => {
		if (disabled) {
			return;
		}

		setOpen(newOpen);
	};

	const label = () => {
		if (!startDate || !endDate) {
			return '';
		} else if (format === Dateformat.Days) {
			return formatLabelInDays(startDate, endDate);
		} else if ([Dateformat.Months, Dateformat.Quarters].includes(format)) {
			return formatDateRangeInMonths(startDate, endDate);
		}
	};

	return (
		<Container>
			{opener({
				label: label(),
				startDate: startDate,
				endDate: endDate,
				format,
				onChange,
				setOpen: handleOpen,
				isOpen: open,
				disabled,
			})}

			<DateSelectorPopup
				PopupArea={PopupArea}
				open={open}
				onClose={onClose}
				value={parsedValue}
				onChange={onChangeDatesCallback}
				isDateDisabled={isDateDisabled}
				isMonthDisabled={isMonthDisabled}
				cutOffDate={cutOffDate}
				initialVisibleMonth={initialVisibleMonth}
				renderDateToggleButton={renderDateToggleButton}
				popupHeaderTitle={popupHeaderTitle}
				enableDaysSelection={enableDaysSelection}
				enableContinuousPeriodSelection={enableContinuousPeriodSelection}
				enableQuarterPeriodSelection={enableQuarterPeriodSelection}
				allowPastDates={allowPastDates}
				allowMultipleMonths={allowMultipleMonths}
				defaultDateFormat={defaultDateFormat}
			/>
		</Container>
	);
};

const Container = styled.div`
	position: relative;
`;

function formatLabelInDays(startDate, endDate) {
	const start = moment(startDate).format('L');
	const end = moment(endDate).format('L');
	return `${start} - ${end}`;
}
