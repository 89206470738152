/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getUserId } from 'src/_store/selectors';
import { EventBusEvent } from 'src/components/EventBus/events';
import { useSubscribe } from 'src/shared/useSubscribe';
import { CounterStatus } from 'src/constants/counterStatus';

export const useDrawerRealTimeUpdates = ({ order, negotiation, accepteeId, setAccepteeId }) => {
	const currentUserId = useSelector(getUserId);

	const [newOrderActivities, setnewOrderActivities] = useState(false);
	const [newNegotiationActivities, setnewNegotiationActivities] = useState(false);
	const [counterEdited, setCounterEdited] = useState(false);

	const setOrderAndNegotiationUpToDate = () => {
		setnewOrderActivities(false);
		setnewNegotiationActivities(false);
		setCounterEdited(false);
	};

	useSubscribe(EventBusEvent.OrderUpdated, data => {
		if (!order.is_my_order && (data._key === order._key || data.order_id === order._key)) {
			setnewOrderActivities(true);
		}
	});

	const orderWithdrawnCallback = useCallback(
		data => {
			if (
				negotiation &&
				data.order_id === order._key &&
				negotiation.status !== CounterStatus.Confirmed &&
				!order.is_my_order
			) {
				setnewNegotiationActivities(true);
			}
		},
		[negotiation, order._key, order.is_my_order]
	);

	const newCounterCallback = useCallback(
		data => {
			if (
				data.order_id === order._key &&
				data.user_id !== currentUserId &&
				negotiation &&
				negotiation.first_counter_id === data.first_counter_id
			) {
				if (data.edited_from) {
					setCounterEdited(true);
				} else {
					setnewNegotiationActivities(true);
				}
			}
		},
		[order._key, currentUserId, negotiation]
	);

	const tradeCreatedCallback = useCallback(
		data => {
			if (
				negotiation &&
				negotiation.first_counter_id === data.first_counter_id &&
				accepteeId !== currentUserId
			) {
				setnewNegotiationActivities(true);
				setAccepteeId(null);
			}
		},
		[accepteeId, currentUserId, negotiation, setAccepteeId]
	);

	useSubscribe(EventBusEvent.WithdrawnOrderWithNegotiations, orderWithdrawnCallback);

	useSubscribe(EventBusEvent.NewCounter, newCounterCallback);

	useSubscribe(EventBusEvent.NewTrade, tradeCreatedCallback);

	return {
		setOrderAndNegotiationUpToDate,
		newNegotiationActivities,
		newOrderActivities,
		counterEdited,
	};
};
