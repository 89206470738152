/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { toPath } from 'src/_helpers';
import { useCalendarSpreadFields } from '../../useCalendarSpreadFields';
import { Controller } from 'react-hook-form';
import { MaterialNumberInput } from 'src/designSystem/Form/MaterialInput/MaterialNumberInput';
import { useWatchPaperCounterFields } from 'src/components/ViewOrderDrawer/CounterForm/helpers/paper/useWatchPaperCounterFields';
import { QuoteType } from 'src/constants/contract';
import { getNumberOfMonths } from 'src/_helpers/date';

const VolumeField = ({ path, t, lastCounter }) => {
	const { volume } = useCalendarSpreadFields({
		path,
		lastCounter,
	});

	const { orderTypeValue, firstLegMonthValue, secondLegMonthValue } = useWatchPaperCounterFields(
		path
	);

	const firstLegMonths = firstLegMonthValue.startDate
		? getNumberOfMonths(firstLegMonthValue.startDate, firstLegMonthValue.endDate)
		: 1;
	const secondLegMonths = secondLegMonthValue.startDate
		? getNumberOfMonths(secondLegMonthValue.startDate, secondLegMonthValue.endDate)
		: 1;

	const label = firstLegMonths > 1 || secondLegMonths > 1 ? t('total_quantity') : t('quantity');

	return (
		<Controller
			mandatory={orderTypeValue === QuoteType.Firm}
			name={toPath(path, 'volume')}
			render={({ field, fieldState: { error } }) => (
				<MaterialNumberInput
					required={orderTypeValue === QuoteType.Firm}
					error={!!error}
					data-test={field.name}
					id="volume"
					label={label}
					fixedDecimalScale={false}
					parameters={t('price_unit_short_metric_ton')}
					controlledState
					hasChanged={volume.hasChanged}
					isAllowed={value => value.floatValue !== 0}
					{...field}
				/>
			)}
		/>
	);
};

export const counterVolumeField = props => <VolumeField {...props} />;
