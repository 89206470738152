/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { API_URL, fetchFromExchangeApi, map404ToEmptyList, map404ToEmptyObject } from './model';
import { orderMapper } from './orders.api';
import { uploadOrderFiles } from './products.api';
import { trackAnalytics } from './analytics.api';
import {
	shippingTenderCreateSchema,
	vesselNominationCreateSchema,
	shippingAdviceCreateSchema,
	etaDischargePortCreateSchema,
} from './model/model.trades';
import qs from 'querystring';

export const getChartData = async body => {
	return fetchFromExchangeApi('trading_chart', {
		method: 'POST',
		body,
		cache: 'no-store',
		mapStatusToResult: map404ToEmptyList,
	});
};

export const getTeamTrades = async ({ teamId, traders, ...paginationParams }) => {
	const query = {
		trader_id: traders,
		...paginationParams,
	};

	if (!teamId) {
		return { items: [] };
	}

	const result = await fetchFromExchangeApi(`teams/${teamId}/trades`, {
		query,
		mapStatusToResult: map404ToEmptyObject,
	});

	return {
		items: mapTeamTrades(result.data),
		cursor: result.meta?.next_page_cursor || '',
	};
};

const mapTeamTrades = trades =>
	trades.map(item => ({
		...item,
		origin: item.origin_countries || [],
		reactKey: `${item._key}_${item.position}`,
	}));

export const getMyTradebookData = async query => {
	const result = await fetchFromExchangeApi('trades_v2', {
		query,
		mapStatusToResult: map404ToEmptyObject,
	});

	return {
		items: result.data,
		cursor: result.meta?.next_page_cursor || '',
	};
};

export const getActivityLogs = async (query_id, { api }) => {
	const { queryParam } = api;
	const logs = await fetchActivityLogs({ [queryParam]: query_id });

	// Note: Logs and shipping history are tightly related on BE so this is a workaround to not show 2 logs when 2 fields have been changed
	const filteredLogs = logs.filter(log => log.type !== 'ETA_LOADING_PORT_UPDATED');

	return filteredLogs;
};

export const fetchActivityLogs = query => {
	return fetchFromExchangeApi('query/activity_log', {
		method: 'GET',
		query: {
			_order: 'DESC',
			_sort: 'created_at',
			...query,
		},
		mapStatusToResult: map404ToEmptyList,
	});
};

export const getTradeDetails = async ({ tradeId }) => {
	return fetchFromExchangeApi(`trade/${tradeId}`, {
		method: 'GET',
		mapStatusToResult: map404ToEmptyObject,
	});
};

export const processTrade = ({ tradeId, action, stepName }) => {
	const query = stepName ? { physical_step_name: stepName } : { action };

	return fetchFromExchangeApi(`process_trade/${tradeId}`, {
		method: 'POST',
		body: {},
		query,
	});
};

export const listMyTrades = async query => {
	const result = await fetchFromExchangeApi('list_my_trades_v2', {
		query,
		mapStatusToResult: map404ToEmptyObject,
	});

	return {
		items: result.data.map(tradeMapper),
		cursor: result.meta?.next_page_cursor || '',
	};
};

export const tradeMapper = trade => {
	return orderMapper(trade);
};

export const trackTradeAnalytics = async ({ user_id, trade_id, activity_logs }) => {
	const analyticsTradeQuery = { trade_id };

	const trackAnalyticsResponse = await trackAnalytics({
		user_id,
		...analyticsTradeQuery,
		...(activity_logs.length && {
			activity_logs,
		}),
	});

	return trackAnalyticsResponse;
};

export const uploadMultipleDocuments = async ({
	files,
	tradeId,
	tradeStep,
	isShippingDocument,
	folderId = 0,
}) => {
	const uploadedFiles = await Promise.all(
		files.map(file => uploadOrderFiles({ file: file.file }))
	);

	if (isShippingDocument) {
		return fetchFromExchangeApi(`trade/${tradeId}/bl_document_folder/${folderId}/document`, {
			method: 'POST',
			body: {
				documents: uploadedFiles.map((file, index) => ({
					document_id: file._key,
					document_type: files[index].type,
				})),
			},
		});
	} else {
		return fetchFromExchangeApi(`trade/${tradeId}/document_v2`, {
			method: 'POST',
			body: {
				documents: uploadedFiles.map((file, index) => ({
					document_id: file._key,
					document_type: files[index].type,
					trade_step: tradeStep,
				})),
			},
		});
	}
};

export const uploadShippingDocument = async ({
	tradeId,
	file,
	document_type,
	notes,
	folderId,
	api: { path },
}) => {
	const uploadedFile = await uploadOrderFiles({ file });

	return fetchFromExchangeApi(`${path}/${tradeId}/bl_document_folder/${folderId}/document`, {
		method: 'POST',
		body: {
			documents: [
				{
					document_id: uploadedFile._key,
					document_type,
					notes,
				},
			],
		},
	});
};

export const addTradeDocument = async ({
	tradeId,
	tradeStep,
	file,
	document_type,
	notes,
	api: { path },
}) => {
	const uploadedFile = await uploadOrderFiles({ file });

	return fetchFromExchangeApi(`${path}/${tradeId}/document`, {
		method: 'POST',
		body: {
			document_id: uploadedFile._key,
			document_type,
			trade_step: tradeStep,
			notes,
		},
	});
};

export const requestDocumentTypes = async ({ tradeId, types, notes }) => {
	return await fetchFromExchangeApi(`trade/${tradeId}/instructions`, {
		method: 'POST',
		body: {
			types,
			notes,
		},
	});
};

export const getStringData = async ({ trade_id }) => {
	const stringData = await fetchStringData({ trade_id });
	return stringData;
};

export const fetchStringData = ({ trade_id }) => {
	return fetchFromExchangeApi(`trade/${trade_id}/string`, {
		method: 'GET',
		mapStatusToResult: map404ToEmptyList,
	});
};

export const createTradeDocumentsBypass = async ({ tradeId, documentsBypass }) => {
	return fetchFromExchangeApi(`trade/${tradeId}/document_bypass`, {
		method: 'POST',
		body: {
			document_bypass: documentsBypass === 'true',
		},
	});
};

export const createBLFolder = async tradeId => {
	return fetchFromExchangeApi(`trade/${tradeId}/bl_document_folder`, {
		method: 'POST',
	});
};

export const createVesselNomination = async ({ tradeId, body, isShippingAdvice = false }) => {
	try {
		if (isShippingAdvice) {
			await shippingAdviceCreateSchema.validate(body);
		} else {
			await vesselNominationCreateSchema.validate(body);
		}
		return fetchFromExchangeApi(`trade/${tradeId}/vessel_nomination`, {
			method: 'POST',
			body,
		});
	} catch (error) {
		return await Promise.reject(error);
	}
};

export const getVesselNominationHistory = async ({ tradeId }) => {
	return await fetchFromExchangeApi(`trade/${tradeId}/vessel_nomination/history`, {
		method: 'GET',
	});
};

export const createShippingTender = async ({ tradeId, body }) => {
	try {
		await shippingTenderCreateSchema.validate(body);
		return fetchFromExchangeApi(`trade/${tradeId}/bl_nomination`, {
			method: 'POST',
			body,
		});
	} catch (error) {
		return await Promise.reject(error);
	}
};

export const getFuturesContractDetails = async tradeId => {
	return await fetchFromExchangeApi(`trade/${tradeId}/futures_contract_details`, {
		method: 'GET',
	});
};

export const deleteFuturesContractDetails = async ({ tradeId, contractId }) => {
	return await fetchFromExchangeApi(`trade/${tradeId}/futures_contract_details/${contractId}`, {
		method: 'DELETE',
	});
};

export const updateFuturesContractDetailsStatus = async ({ tradeId, contractId, body }) => {
	try {
		return fetchFromExchangeApi(`trade/${tradeId}/futures_contract_details/${contractId}`, {
			method: 'PUT',
			body,
		});
	} catch (error) {
		return await Promise.reject(error);
	}
};

export const createFuturesContractDetails = async ({ tradeId, body }) => {
	try {
		return fetchFromExchangeApi(`trade/${tradeId}/futures_contract_details`, {
			method: 'POST',
			body,
		});
	} catch (error) {
		return await Promise.reject(error);
	}
};

export const getFuturesContractDetailsStatistics = async tradeId => {
	return await fetchFromExchangeApi(`trade/${tradeId}/futures_contract_details/statistics`, {
		method: 'GET',
	});
};

export const createEtaDischargePort = async ({ tradeId, body }) => {
	try {
		await etaDischargePortCreateSchema.validate(body);
		return fetchFromExchangeApi(`trade/${tradeId}/bl_nomination_eta_discharge_port`, {
			method: 'POST',
			body,
		});
	} catch (error) {
		return await Promise.reject(error);
	}
};

export const adminMergeTradesIntoString = async tradeIdsStrings => {
	return await fetchFromExchangeApi(`initial_strings`, {
		method: 'POST',
		body: {
			dry_run: false,
			trade_ids_strings: [tradeIdsStrings],
		},
	});
};

export const adminBlockDocumentBypass = async tradeIds => {
	return await fetchFromExchangeApi(`block_document_bypass`, {
		method: 'POST',
		body: {
			dry_run: false,
			trade_ids: tradeIds,
		},
	});
};

export const adminCreateFinalString = async strings => {
	return await fetchFromExchangeApi(`final_strings`, {
		method: 'POST',
		body: {
			strings: [strings],
			dry_run: false,
		},
	});
};

export const listMyNewTrades = async () => {
	try {
		return await fetchFromExchangeApi('list_my_new_trades', {
			method: 'GET',
			mapStatusToResult: map404ToEmptyList,
		});
	} catch (error) {
		return await Promise.reject(error);
	}
};

export const addUsersToTrade = ({ tradeId, users }) => {
	return fetchFromExchangeApi(`trade/${tradeId}/users`, {
		method: 'POST',
		body: { user_ids: users },
	});
};

export const getTradeUsers = ({ tradeId, ...query }) => {
	return fetchFromExchangeApi(`trade/${tradeId}/users`, {
		method: 'GET',
		query,
		mapStatusToResult: map404ToEmptyObject,
	});
};

export const removeUserFromTrade = ({ tradeId, traderId }) => {
	return fetchFromExchangeApi(`trade/${tradeId}/users/${traderId}`, {
		method: 'DELETE',
	});
};

export const exportTrades = ({ query }) => {
	const params = query ? '?' + qs.stringify(query) : '';

	window.open(`${API_URL}/v1/trades/export${params}`);
};

export const sendTradesViaEmail = async ({ query }) => {
	return await fetchFromExchangeApi(`trades/export`, {
		method: 'GET',
		query,
	});
};

export const createStringContact = async ({ tradeId, contact_user_id }) => {
	return await fetchFromExchangeApi(`trade/${tradeId}/string/contacts`, {
		method: 'POST',
		body: {
			contact_user_id,
		},
	});
};

export const sendTradeActivityLogViaEmail = async ({ tradeId, query }) => {
	return await fetchFromExchangeApi(`trade/${tradeId}/activity_log/export?send_email=true`, {
		method: 'GET',
		query,
	});
};

export const downloadTradeActivityLog = ({ tradeId }) =>
	window.open(`${API_URL}/v1/trade/${tradeId}/activity_log/export`);
