/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { HeaderType } from '../helpers/useViewOrderHeader';
import { getHeaderTheme } from 'src/components/Drawer/DrawerHeader/helpers';
import { DrawerHeader } from 'src/components/Drawer/DrawerHeader/styled';
import { DrawerContent, DrawerFooter } from 'src/components/Drawer/styled';
import {
	ActionButtons,
	DrawerContentWrapper,
	OrderCreatorShipmentSectionContainer,
	TopContent,
} from '../styled';
import * as Styled from './styled';

export const ViewOrderDrawerSkeleton = () => {
	return (
		<>
			<DrawerHeader className="drawer-header" theme={getHeaderTheme(HeaderType.Default)} />
			<DrawerContent>
				<DrawerContentWrapper>
					<TopContent>
						<Styled.SkeletonGrid>
							<Styled.SkeletonLineGrid>
								<Styled.SkeletonLine />
								<Styled.ShortSkeletonLine />
							</Styled.SkeletonLineGrid>
							<Styled.SkeletonPill />
						</Styled.SkeletonGrid>
						<OrderCreatorShipmentSectionContainer>
							<Styled.BigSkeletonBox />
							<Styled.BigSkeletonBox />
						</OrderCreatorShipmentSectionContainer>
					</TopContent>
				</DrawerContentWrapper>
			</DrawerContent>
			<DrawerFooter>
				<div />
				<ActionButtons>
					<Styled.SkeletonButton />
					<Styled.SkeletonButton />
				</ActionButtons>
			</DrawerFooter>
		</>
	);
};
