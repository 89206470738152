/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useEffect, useCallback } from 'react';
import styled from 'styled-components';
import {
	HighligthCell,
	HighligthLabel,
	RowCell,
	PriceColorsCell,
	ColoredQuoteTypeCell,
} from './Cell';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import {
	OrderType,
	PriceType,
	tCurrency,
	tInspection,
	tOrderType,
	tPaymentTooltip,
	tPriceType,
	tQuoteType,
	PaperInstruments,
	ContractType,
	tCalendarSpreadPayment,
	Environment,
	SpreadPriceFormat,
} from 'src/constants/contract';
import { formatFuturesContractMonthToLetterCode } from 'src/_helpers/date';
import { formatTolerance } from 'src/_helpers';
import {
	formatDateRange,
	formatDistance,
	millisecondsRemaining,
	parseDateStringAsUTC,
} from 'src/_helpers/date';
import { OrderStatus, tOrderStatus } from 'src/constants/orderStatus';
import {
	CounterStatus,
	tCounterStatus,
	tableStatusRowStyle,
	validityStatusCheck,
} from 'src/constants/counterStatus';
import { TradeStep, SimplifiedTradeStep } from 'src/constants/tradeStatus';
import { tPhysicalTradeStep } from 'src/constants/physicalTrade';
import { useUpdateEvery } from 'src/_helpers/useUpdateEvery';
import { useSelector } from 'react-redux';
import { getUserId } from 'src/_store/selectors';
import { formatPortName } from 'src/_helpers/port';
import { isExpired } from 'src/_helpers/date';
import { useUpdateAtTime } from 'src/_helpers/useUpdateAtTime';
import { OverflowText, OverflowArray } from 'src/components/Tooltip/OverflowText';
import { formatPriceWithoutCurrency } from 'src/_helpers/price';
import { UserStatus } from '../UserStatus/UserStatus';
import { TradePosition } from 'src/constants/tradebook';
import { Tooltip, TooltipContent, TooltipTrigger } from 'src/components/Tooltip/Tooltip';
import { OTCType } from 'src/containers/TeamsDashboard/constants';
import { useUnseenActivitiesContext } from 'src/containers/Dashboard/Footer/MyTabsContext';
import { OriginsListMode, formatOriginsList } from 'src/_helpers/origins';
import clsx from 'clsx';
import {
	formatProductAndFuturesCode,
	findSelectedFuturesContractObject,
} from 'src/_helpers/futuresContracts';
import { calculatePayCashDirection } from 'src/_helpers/spread';
import { OTCLabel } from '../OTCLabel/OTCLabel';
import { OverflowTextWithLabel } from '../Tooltip/OverflowTextWithLabel';
import { DashEmpty } from '../DashEmpty/DashEmpty';
import { useMultipleOrderActions } from 'src/containers/Dashboard/MultipleOrderActions/MultipleOrderActions';
import { isOrderActive } from 'src/_helpers/order';

export const Product = ({ item, ...props }) => {
	return (
		<HighligthCell {...props} className="product-cell">
			<OverflowText>{item.product?.name}</OverflowText>
		</HighligthCell>
	);
};

export const Status = ({ item, ...props }) => {
	const { t } = useTranslation();

	const status =
		item.is_traded && item.status === OrderStatus.Terminated
			? OrderStatus.NotAvailable
			: item.status;

	return (
		<RowCell {...props}>
			<StatusWrapper>
				<StatusDot
					$status={item.status}
					$isTraded={item.is_traded}
					$validity={item.validity}
				/>
				<OverflowText>{tOrderStatus(t, status, item.validity)}</OverflowText>
			</StatusWrapper>
		</RowCell>
	);
};

export const PhysicalTradeStep = ({ item, ...props }) => {
	const { t } = useTranslation();

	return (
		<RowCell {...props}>
			<OverflowText>{tPhysicalTradeStep(t, item.trade_step)}</OverflowText>
		</RowCell>
	);
};

export const TradeStatus = ({ item, ...props }) => {
	const { t } = useTranslation();

	const tradeStep = t('settlement');

	return (
		<RowCell {...props}>
			<OverflowText>{tradeStep}</OverflowText>
		</RowCell>
	);
};

export const ClosedAt = ({ item: order, ...props }) => {
	return <RowCell {...props}>{moment(order.updated_at).format('ll')}</RowCell>;
};

export const UpdatedAt = ({ item: order, ...props }) => {
	const { t } = useTranslation();

	const updatedDate = order.is_order ? order.version_created_at : order.updated_at;

	return (
		<RowCell {...props}>
			{formatDistance({
				t,
				startDate: new Date(updatedDate),
				endDate: new Date(),
				short: true,
			})}
		</RowCell>
	);
};

export const CreatedAt = ({ item: order, ...props }) => {
	const hasValidCreatedAt =
		order.version_created_at && new Date(order.version_created_at).getFullYear() > 2000;
	const value = hasValidCreatedAt ? order.version_created_at : order.created_at;

	return (
		<RowCell {...props}>
			<OverflowText>{value ? moment(value).format('ll') : '---'}</OverflowText>
		</RowCell>
	);
};

export const Inco = ({ item: order, ...props }) => {
	return (
		<IncoCell {...props}>
			<Tooltip placement="bottom-start">
				<TooltipTrigger>{order?.inco?.name}</TooltipTrigger>
				<TooltipContent>{order?.inco?.description}</TooltipContent>
			</Tooltip>
		</IncoCell>
	);
};

export const LoadingPorts = ({ item: order, ...props }) => {
	const [primaryPort, ...otherPorts] = order.ports_loading || [];

	return (
		<RowCell {...props}>
			{primaryPort?.name || '---'}
			<HighligthLabel title={otherPorts.map(port => port.name).join(', ')}>
				{otherPorts.length ? ` +${otherPorts.length}` : ''}
			</HighligthLabel>
		</RowCell>
	);
};

export const PrimaryPorts = ({ item: order, ...props }) => {
	const portNames = order?.primary_ports?.map(port => port?.name).filter(Boolean);

	return (
		<RowCell {...props}>
			{!portNames || portNames.length === 0 ? '---' : <OverflowArray texts={portNames} />}
		</RowCell>
	);
};
export const PrimaryPort = ({ item: order, ...props }) => {
	const port = order.primary_port || order.primary_ports?.[0];

	return (
		<RowCell {...props}>
			<OverflowText>{port ? formatPortName(port) : '---'}</OverflowText>
		</RowCell>
	);
};

export const DischargePorts = ({ item: order, ...props }) => {
	const [primaryDischargePort, ...otherDischargePorts] = order.ports_discharging || [];

	return (
		<RowCell {...props}>
			{primaryDischargePort?.name}
			<HighligthLabel title={otherDischargePorts.map(port => port.name).join(', ')}>
				{otherDischargePorts.length ? ` +${otherDischargePorts.length}` : ''}
			</HighligthLabel>
		</RowCell>
	);
};

export const TotalVolume = ({ item: order, ...props }) => {
	return (
		<RowCell {...props} textAlign="right">
			{order.volume ? (
				<NumberFormat thousandSeparator displayType="text" value={order.volume} />
			) : (
				'---'
			)}
		</RowCell>
	);
};

export const BuyVolume = ({ item: order, ...props }) => {
	return (
		<RowCell {...props} textAlign="right">
			{order.order_type === OrderType.Buy && (
				<HighligthLabel>
					<NumberFormat thousandSeparator displayType="text" value={order.volume} />
				</HighligthLabel>
			)}
		</RowCell>
	);
};
export const SellVolume = ({ item: order, ...props }) => {
	return (
		<RowCell {...props} textAlign="right">
			{order.order_type === OrderType.Sell && (
				<HighligthLabel>
					<NumberFormat thousandSeparator displayType="text" value={order.volume} />
				</HighligthLabel>
			)}
		</RowCell>
	);
};

export const VolumePerMt = ({ item: order, ...props }) => {
	return order.volume && order._id ? (
		<HighligthCell {...props} textAlign="left" className="column-volume-per-mt">
			<OverflowText>
				<NumberFormat thousandSeparator displayType="text" value={order.volume} />
			</OverflowText>
		</HighligthCell>
	) : (
		<RowCell {...props} textAlign="left" className="column-volume-per-mt">
			<span>---</span>
		</RowCell>
	);
};

export const TradeVolumePerMt = ({ item: trade, ...props }) => {
	const userId = useSelector(getUserId);
	const isUserSeller = trade.seller_user_id === userId;
	return (
		<RowCell {...props} textAlign="left" className="column-volume-per-mt">
			<span
				style={{
					color: `var(--${isUserSeller ? 'sell' : 'buy'})`,
				}}
			>
				<OverflowText>
					<StyledVolume thousandSeparator displayType="text" value={trade.volume} />
				</OverflowText>
			</span>
		</RowCell>
	);
};

export const Currency = ({ item: order, ...props }) => {
	const { t } = useTranslation();

	return <RowCell {...props}>{tCurrency(t, order.currency)}</RowCell>;
};

export const KYCPricePerMt = ({ item: order, ...props }) => {
	const pricePerMt = order?.price ? formatPriceWithoutCurrency(order.price) : '---';

	return (
		<RowCell {...props} textAlign="left">
			{pricePerMt}
		</RowCell>
	);
};

export const NegotiationPriceCell = props => {
	return <PricePerMt {...props} ignorePayCash />;
};

export const FirstLegPriceCell = ({ item: order, ...props }) => {
	return (
		<RowCell {...props}>
			<OverflowText>
				<DashEmpty
					value={formatPriceWithoutCurrency(order.spread_details?.first_leg_price)}
				/>
			</OverflowText>
		</RowCell>
	);
};

export const PricePerMt = ({ item: order, ignorePayCash, defaultColor, ...props }) => {
	const { t } = useTranslation();

	const isBuyOrder = order?.order_type === OrderType.Buy;

	if (order?.has_price === false) {
		return (
			<PriceColorsCell {...props} $isBid={isBuyOrder}>
				---
			</PriceColorsCell>
		);
	}

	let pricePerMt = formatPriceWithoutCurrency(order.price);

	if (order.instrument === PaperInstruments.Spread) {
		if (!ignorePayCash && order?.spread_details?.price_format === SpreadPriceFormat.PayCash) {
			const payCashDirection = calculatePayCashDirection(isBuyOrder, order.price);
			pricePerMt = `${tCalendarSpreadPayment(
				t,
				payCashDirection
			)} ${formatPriceWithoutCurrency(pricePerMt)}`.replace('-', '');
		} else if (ignorePayCash) {
			pricePerMt = pricePerMt.toString().replace('-', '');
		} else {
			pricePerMt = order.price > 0 ? `+${pricePerMt}` : pricePerMt;
		}
	}

	return defaultColor ? (
		<RowCell {...props} textAlign="left">
			<OverflowText>{pricePerMt}</OverflowText>
		</RowCell>
	) : (
		<PriceColorsCell
			{...props}
			$isBid={isBuyOrder}
			textAlign="left"
			className={`price-color-${isBuyOrder ? 'buy' : 'sell'}`}
		>
			<OverflowText>{pricePerMt}</OverflowText>
		</PriceColorsCell>
	);
};

export const TradePrice = ({ item: trade, defaultColor, ...props }) => {
	const isLong = trade?.position === TradePosition.Long;
	const price = formatPriceWithoutCurrency(trade.price);

	return defaultColor ? (
		<RowCell {...props} textAlign="left">
			{price}
		</RowCell>
	) : (
		<PriceColorsCell
			{...props}
			$isBid={isLong}
			textAlign="left"
			className={`price-color-${isLong ? 'buy' : 'sell'}`}
		>
			{price}
		</PriceColorsCell>
	);
};

export const Quote = ({ item: order, ...props }) => {
	const { t } = useTranslation();

	return <RowCell {...props}>{tOrderType(t, order.order_type)}</RowCell>;
};

export const QuoteType = ({ item: order, $isMotherOrder, otcMarker, ...props }) => {
	const { t } = useTranslation();

	const shouldDisplayOTCLabel = otcMarker && order.environment === Environment.OTC;

	const firmIndicativeText = $isMotherOrder ? '---' : tQuoteType(t, order.is_indicative);

	return (
		<ColoredQuoteTypeCell
			{...props}
			$isFirm={!order.is_indicative}
			$isBid={order.order_type === OrderType.Buy}
			className={clsx(props.className, 'quote-type-cell')}
		>
			<OverflowTextWithLabel
				text={firmIndicativeText}
				label={shouldDisplayOTCLabel && OTCLabel}
			/>
		</ColoredQuoteTypeCell>
	);
};

export const NegotiationQuoteType = ({ item, ...props }) => {
	const { t } = useTranslation();

	return (
		<ColoredQuoteTypeCell
			{...props}
			$isFirm={!item.is_indicative}
			$isBid={item.order_type === OrderType.Buy}
			className={clsx(props.className, 'quote-type-cell')}
		>
			<OverflowText>{tQuoteType(t, item.is_indicative, ContractType.COUNTER)}</OverflowText>
		</ColoredQuoteTypeCell>
	);
};

export const PriceTypeCell = ({ item: order, ...props }) => {
	const { t } = useTranslation();

	return <RowCell {...props}>{tPriceType(t, order.price_type)}</RowCell>;
};

export const FuturesMarketCell = ({ item: order, ...props }) => (
	<RowCell {...props}>
		<OverflowText>{order.futures_contract || '---'}</OverflowText>
	</RowCell>
);

export const FuturesCell = ({ item, ...props }) => {
	const futuresContract = findSelectedFuturesContractObject(item);

	const defaultProductAndFuturesCode =
		futuresContract &&
		formatProductAndFuturesCode(futuresContract.product_code, item.futures_contract_date);

	const value =
		item.price_type === PriceType.Flat || item.has_price === false
			? '---'
			: item.instrument === PaperInstruments.Spread
			? `${defaultProductAndFuturesCode}/${formatFuturesContractMonthToLetterCode(
					item.spread_details.futures_contract_date
			  )}`
			: defaultProductAndFuturesCode;

	return (
		<RowCell {...props}>
			<OverflowText>{value}</OverflowText>
		</RowCell>
	);
};

export const FuturesContract = ({ item: order, ...props }) => {
	return <RowCell {...props}>{order.futures_contract ? order.futures_contract : '---'}</RowCell>;
};

export const FuturesContractDate = ({ item: order, ...props }) => {
	return (
		<RowCell {...props}>
			<OverflowText>
				{order.futures_contract_date
					? moment(order.futures_contract_date).format('MMM YYYY')
					: '---'}
			</OverflowText>
		</RowCell>
	);
};

export const TradebookShipmentCell = ({ item: trade, ...props }) => {
	const hasShipmentDefined =
		trade && trade.delivery_date_from && trade.delivery_date_to && trade.delivery_mode;

	if (!hasShipmentDefined) {
		return <RowCell {...props}>---</RowCell>;
	}

	const shipmentDate = formatDateRange({
		startDate: parseDateStringAsUTC(trade.delivery_date_from),
		endDate: parseDateStringAsUTC(trade.delivery_date_to),
		format: trade.delivery_mode,
	});

	return (
		<RowCell {...props}>
			<OverflowText>
				<span>{shipmentDate}</span>
			</OverflowText>
		</RowCell>
	);
};

export const TradebookTradeDateCell = ({ item: trade, ...props }) => {
	const date = trade.created_at ? moment.utc(trade.created_at).format('ll') : '---';

	return (
		<RowCell {...props}>
			<OverflowText>{date}</OverflowText>
		</RowCell>
	);
};

export const DeliveryDate = ({ item: order, ...props }) => {
	return (
		<RowCell {...props}>
			{order && order.delivery_date_from && order.delivery_date_to && order.delivery_mode ? (
				<OverflowText>
					{formatDateRange({
						startDate: parseDateStringAsUTC(order.delivery_date_from),
						endDate: parseDateStringAsUTC(order.delivery_date_to),
						format: order.delivery_mode,
					})}
				</OverflowText>
			) : (
				'---'
			)}
		</RowCell>
	);
};

export const Payment = ({ item: order, tooltipTestId, ...props }) => {
	const { t } = useTranslation();

	const paymentTranslation = !!order?.payment && tPaymentTooltip(t, order.payment);

	return (
		<RowCell {...props}>
			{order?.payment ? (
				!paymentTranslation ? (
					order.payment
				) : (
					<Tooltip placement="bottom">
						<TooltipTrigger>{order.payment}</TooltipTrigger>
						<TooltipContent tooltipTestId={tooltipTestId}>
							{paymentTranslation}
						</TooltipContent>
					</Tooltip>
				)
			) : (
				'---'
			)}
		</RowCell>
	);
};

export const ExpirationCell = ({ item: order, ...props }) => (
	<RowCell {...props} className={clsx(props.className, 'relative')}>
		<OverflowText>
			{order.closing_date ? moment(order.closing_date).format('ll') : '---'}
		</OverflowText>
	</RowCell>
);

export const Runs = ({ item, ...props }) =>
	item?.runs ? (
		<HighligthCell {...props} className="runs-cell">
			{item?.runs}
		</HighligthCell>
	) : (
		<RowCell {...props}>---</RowCell>
	);

export const CounterStatusCell = ({ item: counter, ...props }) => {
	const { t } = useTranslation();

	const isTraded = (counter.status === CounterStatus.Confirmed && !!counter.trade_id) || false;

	const hasJustExpired = useUpdateAtTime(counter.validity);

	const status =
		(hasJustExpired && counter.status === CounterStatus.Active) ||
		validityStatusCheck(counter.validity, counter.status)
			? CounterStatus.Expired
			: counter.status;

	return (
		<StyledStatusRowCell
			$status={status}
			$validity={counter.validity}
			$type={counter.analytics ? 'read' : 'unread'}
			{...props}
		>
			{tCounterStatus(t, status, isTraded, counter.validity)}
		</StyledStatusRowCell>
	);
};

const defaultSecondsToUpdate = 10;

export const ValidityCell = ({ item: counter, ...props }) => {
	const { t } = useTranslation();
	const { decreaseNegotiationsCount } = useUnseenActivitiesContext();

	const isTraded = !!(counter.status === CounterStatus.Confirmed && counter.trade_id);
	const msToExpire = isTraded ? 0 : millisecondsRemaining(counter.validity);
	const secondsToUpdate = msToExpire > 2 * defaultSecondsToUpdate ? defaultSecondsToUpdate : 0;

	useUpdateEvery(secondsToUpdate * 1000);

	const hasJustExpired = useUpdateAtTime(counter.validity);

	useEffect(() => {
		if (hasJustExpired) {
			decreaseNegotiationsCount();
		}
	}, [decreaseNegotiationsCount, hasJustExpired]);

	if (isTraded) {
		return <RowCell {...props}>---</RowCell>;
	}

	if (hasJustExpired || counter.status === CounterStatus.Terminated) {
		return <RowCell {...props}>{t('expired')}</RowCell>;
	}

	return (
		<RowCell {...props} className={clsx(props.className, 'relative')}>
			<OverflowText>
				{counter.validity
					? formatDistance({
							t,
							startDate: new Date(),
							endDate: new Date(counter.validity),
							expiredKey: 'expired',
					  })
					: '---'}
			</OverflowText>
		</RowCell>
	);
};

export const SilentValidityCell = ({ item: order, ...props }) => {
	const { removeSelectionKeys, isOrderInSelectionMap } = useMultipleOrderActions();

	const orderId = order._key;
	const isActiveOrder = isOrderActive(order);

	const { t } = useTranslation();

	const checkOrderValidity = useCallback(() => {
		const isActiveOrder = isOrderActive(order);

		if (!isActiveOrder && isOrderInSelectionMap(orderId)) {
			removeSelectionKeys([orderId]);
		}
	}, [isOrderInSelectionMap, order, orderId, removeSelectionKeys]);

	const msToExpire = isActiveOrder ? millisecondsRemaining(order.validity) : 0;

	useUpdateEvery(msToExpire);

	useEffect(() => checkOrderValidity(), [checkOrderValidity, msToExpire]);

	if (order.status === OrderStatus.Terminated) {
		return <RowCell {...props}>{t('closed')}</RowCell>;
	}

	if (order.status === OrderStatus.Expired) {
		return <RowCell {...props}>{t('expired')}</RowCell>;
	}

	if (order.status === OrderStatus.NotAvailable) {
		return <RowCell {...props}>{t('traded')}</RowCell>;
	}

	return (
		<RowCell {...props}>
			{order.validity
				? formatDistance({
						t,
						startDate: new Date(),
						endDate: new Date(order.validity),
						expiredKey: 'expired',
				  })
				: '---'}
		</RowCell>
	);
};

export const SentReceived = ({ item: order, ...props }) => {
	const { t } = useTranslation();

	return (
		<RowCell {...props}>
			<OverflowText>
				{order.otc_type === OTCType.Sent ? t('sent') : t('received')}
			</OverflowText>
		</RowCell>
	);
};

export const IsCountered = ({ item: order, ...props }) => {
	const { t } = useTranslation();

	return (
		<RowCell {...props}>
			<OverflowText>{order.is_countered ? t('yes') : t('no')}</OverflowText>
		</RowCell>
	);
};

export const Grade = ({ item: order, ...props }) => {
	const { t } = useTranslation();

	return (
		<RowCell {...props}>
			<OverflowText>{order.grade?.is_custom ? t('custom') : order.grade?.name}</OverflowText>
		</RowCell>
	);
};

export const TeamCompany = ({ item: order, ...props }) => {
	const { t } = useTranslation();

	return (
		<RowCell {...props}>
			<OverflowText>{order.company?.name || t('hidden')}</OverflowText>
		</RowCell>
	);
};

export const MyOrderTraderName = ({ item: order, ...props }) => {
	return (
		<RowCell {...props}>
			<OverflowText>{order.order_owner_principal?.user?.name || '---'}</OverflowText>
		</RowCell>
	);
};

export const TradePrincipalCompany = ({ item: order, ...props }) => {
	const { t } = useTranslation();

	const company = order.my_principal?.company;

	return (
		<RowCell {...props}>
			{company ? <OverflowText>{company.name}</OverflowText> : t('hidden')}
		</RowCell>
	);
};

export const OrderPrincipalCompany = ({ item: order, ...props }) => {
	const company = order.order_owner_principal?.company || {};

	return (
		<RowCell {...props} className="principal-cell">
			{company.name ? <OverflowText>{company.name}</OverflowText> : <span>---</span>}
		</RowCell>
	);
};

export const Company = ({ item: order, ...props }) => {
	const { t } = useTranslation();

	const getCompanyName = () => {
		if (!order.order_owner_principal?.user) {
			return <HiddenCell>{t('hidden')}</HiddenCell>;
		}

		return <span>{order.order_owner_principal?.company?.name || '---'}</span>;
	};

	return (
		<RowCell {...props}>
			<OverflowText>{getCompanyName()}</OverflowText>
		</RowCell>
	);
};

export const TraderName = ({ item: order, ...props }) => {
	const { t } = useTranslation();

	const getTraderName = () => {
		if (order.hidden === true) {
			return <HiddenCell>{t('hidden')}</HiddenCell>;
		}

		return <span>{order.user?.name || '---'}</span>;
	};

	return (
		<RowCell {...props}>
			<OverflowText>{getTraderName()}</OverflowText>
		</RowCell>
	);
};

export const NameVisibility = ({ item: order, ...props }) => {
	const { t } = useTranslation();

	const isHiddenName =
		order.order_owner_principal_hidden !== undefined
			? order.order_owner_principal_hidden
			: order.hidden;

	return (
		<RowCell {...props}>
			<OverflowText>
				{isHiddenName ? (
					<HiddenCell>{t('name_visibility_hidden')}</HiddenCell>
				) : (
					t('name_visibility_visible')
				)}
			</OverflowText>
		</RowCell>
	);
};

export const User = ({ item: order, ...props }) => {
	const { t } = useTranslation();

	return (
		<HighligthCell {...props}>
			<OverflowText>{order.user?.name || t('hidden')}</OverflowText>
		</HighligthCell>
	);
};

export const OTCTraders = ({ item: order, ...props }) => {
	const { t } = useTranslation();

	const traderNames =
		order.otc_type === OTCType.Sent
			? order.user?.name || t('hidden')
			: (order.recipients_list || []).map(person => person.name).filter(Boolean);

	return (
		<HighligthCell {...props}>
			{Array.isArray(traderNames) ? (
				<OverflowArray texts={traderNames} />
			) : (
				<OverflowText>{traderNames}</OverflowText>
			)}
		</HighligthCell>
	);
};

export const OTCTeams = ({ item: order, ...props }) => {
	const { t } = useTranslation();

	const teamNames =
		order.otc_type === OTCType.Received
			? order.company?.name || t('hidden')
			: (order.recipients_list || []).reduce((list, person) => {
					const company = person.company?.name;

					if (company && !list.includes(company)) {
						list.push(company);
					}

					return list;
			  }, []);

	return (
		<RowCell {...props}>
			{Array.isArray(teamNames) ? (
				<OverflowArray texts={teamNames} />
			) : (
				<OverflowText>{teamNames}</OverflowText>
			)}
		</RowCell>
	);
};

export const Trader = ({ item: order, ...props }) => {
	return (
		<HighligthCell {...props}>
			<OverflowText>{order.trader?.name}</OverflowText>
		</HighligthCell>
	);
};

export const MyNegotiationsCounterpartyTraderCell = ({ item: order, ...props }) => {
	const userId = useSelector(getUserId);

	const user =
		userId === order.order_user_id
			? order.counter_order_user
			: order.order_owner_principal?.user;

	return (
		<RowCell {...props}>
			<OverflowText>{user?.name || '---'}</OverflowText>
		</RowCell>
	);
};

export const MyNegotiationsCounterpartyCell = ({ item, ...props }) => {
	const { t } = useTranslation();
	const userId = useSelector(getUserId);

	const company =
		userId === item.order_user_id
			? item.counter_order_user_company
			: item.order_owner_principal.company;

	return (
		<RowCell {...props} className="counterparty-cell">
			{company?.name ? <OverflowText>{company.name}</OverflowText> : t('hidden')}
		</RowCell>
	);
};

export const Counterparty = ({ item: order, ...props }) => {
	return (
		<RowCell {...props}>
			<OverflowText>
				<span>{order.counterparty_principal?.company?.name || '---'}</span>
			</OverflowText>
		</RowCell>
	);
};

export const CounterpartyTraderName = ({ item: order, ...props }) => {
	return (
		<RowCell {...props}>
			<OverflowText>{order.counterparty_principal?.user?.name || '---'}</OverflowText>
		</RowCell>
	);
};

export const CounterpartyCompany = ({ item: order, ...props }) => {
	return (
		<RowCell {...props}>
			<OverflowText>{order.counter_order_user_company?.name || '---'}</OverflowText>
		</RowCell>
	);
};

export const UserCell = ({ user = {}, ...props }) => {
	const MAX_NAME_LENGTH = 20;

	let userName = `${user.first_name} ${user.last_name}`;
	if (userName.length > MAX_NAME_LENGTH) {
		userName = `${user.first_name[0]}. ${user.last_name}`;
	}

	return (
		<RowCell style={{ display: 'flex' }} {...props}>
			<StyledUserCellContainer>
				<OverflowText>{userName}</OverflowText>
				<UserStatus status={user?.status} />
			</StyledUserCellContainer>
		</RowCell>
	);
};

export const ToleranceCell = ({ item, ...props }) => {
	return <RowCell {...props}>{formatTolerance(item.tolerance)}</RowCell>;
};

export const InspectionCell = ({ item, ...props }) => {
	const [t] = useTranslation();
	return (
		<RowCell {...props}>{item?.inspection ? tInspection(t, item?.inspection) : '---'}</RowCell>
	);
};

export const PositionCell = ({ item: trade, ...props }) => {
	const { t } = useTranslation();

	return <RowCell {...props}>{t(`position_${trade.position}`)}</RowCell>;
};

export const OriginCell = ({ item: order, ...props }) => {
	const { t } = useTranslation();

	return (
		<RowCell {...props}>
			<OverflowText>
				{!order.origin || order.origin.length === 0
					? '---'
					: formatOriginsList(order.origin, 'name', OriginsListMode.tooltip, t)}
			</OverflowText>
		</RowCell>
	);
};

const getStatusColor = (status, isTraded = false, validity) => {
	if (status === OrderStatus.Terminated || status === OrderStatus.Rejected) {
		return 'var(--red-400)';
	}

	if (
		status === OrderStatus.NotAvailable ||
		status === OrderStatus.Expired ||
		isExpired(validity)
	) {
		return 'var(--neutral-600)';
	}

	if (isTraded) {
		return 'var(--green-400)';
	}

	switch (status) {
		case OrderStatus.Accepted:
		case TradeStep.Signatures:
		case TradeStep.ShippingAdvice:
		case TradeStep.Completion:
		case TradeStep.Close:
		case TradeStep.VesselNomination:
		case SimplifiedTradeStep.Intention:
		case SimplifiedTradeStep.PreShipmentStart:
		case SimplifiedTradeStep.PostShipmentStart:
			return 'var(--green-400)';
		case TradeStep.Closed:
			return 'var(--neutral-300)';
		case OrderStatus.Confirmed:
		case OrderStatus.Traded:
			return 'var(--yellow-400)';
		default:
			return 'transparent';
	}
};

const StatusWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const StatusDot = styled.div`
	flex-shrink: 0;
	border-radius: 50%;
	width: 6px;
	height: 6px;
	margin-right: 8px;
	background: ${({ $status, $isTraded, $validity }) =>
		getStatusColor($status, $isTraded, $validity)};
`;

const StyledVolume = styled(NumberFormat)`
	color: var(--text-color-default);
`;

const StyledUserCellContainer = styled.div`
	display: flex;
	align-items: center;
`;

const StyledStatusRowCell = styled(RowCell)`
	color: ${({ $status, $type, $validity }) =>
		tableStatusRowStyle($status, $type, $validity).color || 'inherit'};
	background: ${({ $status, $type, $validity }) =>
		tableStatusRowStyle($status, $type, $validity).background || 'inherit'};
`;

export const HiddenCell = styled.span`
	color: var(--neutral-400) !important;
`;

const IncoCell = styled(RowCell)`
	span {
		display: block;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;

export const BlankColumn = props => <RowCell {...props} style={{ paddingRight: 0 }} />;
