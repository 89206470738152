/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useState, useEffect, useCallback } from 'react';
import clsx from 'clsx';
import { PeriodHeader } from '../Header';
import {
	generateQuarters,
	isMiddleQuarter,
	isReverseQuarterSelection,
	getQuarterLabel,
} from './helpers';
import { classNames } from './MonthRange';
import * as Styled from './styled';

export const QuartersRange = ({
	startDate,
	endDate,
	onDatesChange,
	allowMultipleQuarters = false,
	ResetIcon,
	shouldResetSelection = false,
	isQuarterDisabled,
}) => {
	const quarters = generateQuarters();

	const [selectingStartingQuarter, setSelectingStartingQuarter] = useState(true);

	const [startQuarter, setStartQuarter] = useState(startDate);

	const [endQuarter, setEndQuarter] = useState(endDate?.endOf('month')); // to add missing 999 millisecond when component receives value

	const getClassNames = useCallback(
		quarter => {
			return clsx({
				[classNames.start]:
					startQuarter &&
					startQuarter.toISOString() === quarter.value.quarterStart.toISOString(),
				[classNames.middle]:
					startQuarter &&
					endQuarter &&
					isMiddleQuarter(startQuarter, endQuarter, quarter),
				[classNames.end]:
					endQuarter &&
					endQuarter.toISOString() === quarter.value.quarterEnd.toISOString(),

				[classNames.blocked]:
					isQuarterDisabled?.(quarter.value.quarterEnd) || quarter.outdated,
			});
		},
		[endQuarter, isQuarterDisabled, startQuarter]
	);

	useEffect(() => {
		if (startQuarter && endQuarter) {
			onDatesChange({
				startDate: startQuarter.clone().utc(),
				endDate: endQuarter.clone().utc(),
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [startQuarter, endQuarter]);

	const selectQuarter = quarter => {
		const { value } = quarter;

		if (!allowMultipleQuarters) {
			setStartQuarter(value.quarterStart);
			setEndQuarter(value.quarterEnd);
		} else if (selectingStartingQuarter) {
			setStartQuarter(value.quarterStart);
			setEndQuarter(value.quarterEnd);
			setSelectingStartingQuarter(!selectingStartingQuarter);
		} else {
			if (isReverseQuarterSelection(startQuarter, quarter)) {
				const newQuarterEnd = startQuarter
					.clone()
					.add(1, 'quarter')
					.subtract(1, 'days')
					.endOf('day');

				setStartQuarter(value.quarterStart);
				setEndQuarter(newQuarterEnd);
			} else {
				setEndQuarter(value.quarterEnd);
			}

			setSelectingStartingQuarter(!selectingStartingQuarter);
		}
	};

	const handleClear = useCallback(() => {
		setStartQuarter(null);
		setEndQuarter(null);
		setSelectingStartingQuarter(true);
		onDatesChange({
			startDate: null,
			endDate: null,
		});
	}, [onDatesChange]);

	useEffect(() => {
		if (shouldResetSelection) {
			handleClear();
		}
	}, [handleClear, shouldResetSelection]);

	return (
		<>
			<PeriodHeader
				startDate={startQuarter && getQuarterLabel(startQuarter)}
				endDate={endQuarter && getQuarterLabel(endQuarter)}
				onClear={handleClear}
				ResetIcon={ResetIcon}
			/>
			<Styled.Section className="months-range">
				<Styled.MonthContainer>
					{quarters.map((quarter, index) => (
						<Styled.MonthButton
							type="button"
							key={index}
							onClick={() => selectQuarter(quarter)}
							className={getClassNames(quarter)}
						>
							{quarter.label}
						</Styled.MonthButton>
					))}
				</Styled.MonthContainer>
			</Styled.Section>
		</>
	);
};
