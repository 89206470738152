/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useState, useRef, useLayoutEffect, useEffect, useCallback } from 'react';
import Marquee from 'react-fast-marquee';
import { notificationsQuery } from 'src/_api';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { NotificationsTapeItem, Title, Description, ListItemLink } from './NotificationsTapeItem';
import { useMarkAsReadMutation } from './model';
import { useTranslation } from 'react-i18next';
import { EventBusEvent } from 'src/components/EventBus/events';
import { useSubscribe } from 'src/shared/useSubscribe';
import { useNotificationUpdater } from './useNotificationUpdater';
import { useFeatureFlags } from 'src/featureFlags/FeatureFlagsContext';
import { FlagNames } from 'src/constants/flags';

const checkMarqueeShouldPlay = list => {
	if (list) {
		return list.offsetWidth > window.innerWidth;
	} else {
		return false;
	}
};

export const NotificationsTape = () => {
	const [data, setData] = useState([]);
	const [shouldPlay, setShouldPlay] = useState(false);
	const [stopAnimation, setStopAnimation] = useState(false);
	const containerRef = useRef(null);
	const notificationsTapeRef = useRef(null);

	const featureFlagContext = useFeatureFlags();
	const eventstreamEnabled = featureFlagContext.isFlagEnabled(FlagNames.Eventstream);

	useNotificationUpdater({ enabled: !eventstreamEnabled });

	const { isFetched } = useQuery(['notifications', { read: false }], notificationsQuery, {
		cacheTime: 0,
		onSuccess: result => {
			setData(result.items);
		},
	});
	const { mutate } = useMarkAsReadMutation();

	useEffect(() => {
		if (isFetched && data.length) {
			setShouldPlay(checkMarqueeShouldPlay(notificationsTapeRef.current));
		}
	}, [data.length, isFetched]);

	useLayoutEffect(() => {
		const handler = () => {
			setShouldPlay(checkMarqueeShouldPlay(notificationsTapeRef.current));
		};

		window.addEventListener('resize', handler);

		return () => {
			window.removeEventListener('resize', handler);
		};
	}, []);

	useLayoutEffect(() => {
		const list = notificationsTapeRef.current;

		if (!shouldPlay && list && list.getBoundingClientRect()?.left !== 0) {
			setStopAnimation(true);
		} else if (stopAnimation) {
			setStopAnimation(false);
		}
	}, [shouldPlay, stopAnimation]);

	const newNotificationCallback = useCallback(message => {
		setData(data => {
			const notifications = [message, ...data].slice(0, 10);
			const uniqueNotifications = [
				...new Map(notifications.map(item => [item._id, item])).values(),
			];
			return uniqueNotifications;
		});
	}, []);

	useSubscribe(EventBusEvent.NewNotification, newNotificationCallback);

	const cleanupNotification = key => {
		setData(data.filter(n => n._key !== key));
	};

	return (
		<NotificationsTapeWrapper ref={containerRef}>
			{isFetched && data.length === 0 && <NotificationsTapeEmptyItem />}

			<StyledMarquee
				gradientColor={[7, 9, 21]}
				gradientWidth={75}
				pauseOnHover
				speed={40}
				delay={1}
				gradient={shouldPlay}
				play={shouldPlay}
				$stop={stopAnimation}
			>
				<HorizontalList ref={notificationsTapeRef}>
					{data.map(item => (
						<NotificationsTapeItem
							item={item}
							key={item._key}
							onNavigation={() => {
								mutate([item._key], {
									onSuccess: () => cleanupNotification(item._key),
								});
							}}
						/>
					))}
				</HorizontalList>
			</StyledMarquee>
		</NotificationsTapeWrapper>
	);
};

const StyledMarquee = styled(Marquee)`
	z-index: 0;

	${({ $stop }) =>
		$stop &&
		`
		.marquee {
			animation: none;
		}
	`}
`;

const HorizontalList = styled.ul`
	display: flex;
`;

export function NotificationsTapeEmptyItem() {
	const { t } = useTranslation();
	return (
		<EmptyStateItem as="div">
			<Title>{t('no_notifications')}</Title>
			<Description>{t('you_dont_have_notifications')}</Description>
		</EmptyStateItem>
	);
}

const EmptyStateItem = styled(ListItemLink)`
	pointer-events: none;
	padding-left: 22px;
`;

export const NotificationsTapeWrapper = styled.div`
	height: var(--app-notifications-bar-height);
	flex-shrink: 0;
`;
