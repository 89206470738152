/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';

export const OrdersCreatedMessage = styled.div`
	display: flex;
	gap: 16px;
	align-items: center;
	white-space: nowrap;

	button {
		background: var(--primary-800);
		padding: 0 32px;
	}
`;
