/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useCallback, useState } from 'react';
import { useSubscribe } from 'src/shared/useSubscribe';
import { EventBusEvent } from 'src/components/EventBus/events';
import { ReactComponent as ChatIcon } from 'src/assets/icons/chat_icon.svg';
import { MenuItemWithIndicator } from './MenuItemWithIndicator';
import { getUserId } from 'src/_store/selectors';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import {
	ConversationType,
	EmptyUnreadState,
	TopicToConversationTypeMap,
	chatUnreadConversationsQueryName,
} from 'src/chat/constants';
import { getUnreadConversationsCount } from 'src/chat/api/conversations';
import { defaultNotificationsSettings } from 'src/constants/notifications';

export const ChatIndicator = () => {
	const [unread, setUnread] = useState(EmptyUnreadState);
	const [isChatVisible, setChatVisibility] = useState(false);
	const currentUserId = useSelector(getUserId);
	const [activeConversationId, setActiveConversationId] = useState(null);

	useSubscribe(EventBusEvent.ChatVisibilityStatus, data => {
		setChatVisibility(data.isVisible || false);
	});

	useSubscribe(EventBusEvent.ChatMarkConversationsAsRead, data => {
		const conversationIds = data.conversationIds.map(id => id.split('/').at(-1));

		setUnread(current => ({
			...current,
			[data.conversationType]: current[data.conversationType].filter(
				id => !conversationIds.includes(id)
			),
		}));
	});

	useSubscribe(EventBusEvent.ChatUpdateActiveConversationId, ({ conversationId }) => {
		setActiveConversationId(conversationId);
	});

	useQuery([chatUnreadConversationsQueryName, currentUserId], getUnreadConversationsCount, {
		...defaultNotificationsSettings,
		onSuccess: data => {
			const unread = { ...EmptyUnreadState };
			if (data?.unread_instant_conversations_ids) {
				unread[ConversationType.Instant] = [...data.unread_instant_conversations_ids];
			}
			if (data?.unread_execution_conversations_ids) {
				unread[ConversationType.Execution] = [...data.unread_execution_conversations_ids];
			}
			if (data?.unread_kyc_conversations_ids) {
				unread[ConversationType.KYC] = [...data.unread_kyc_conversations_ids];
			}
			setUnread(unread);
		},
	});

	const chatNewMessageCallback = useCallback(
		({ message, conversation }) => {
			if (message.created_by !== currentUserId) {
				const conversationType = TopicToConversationTypeMap[conversation.type];

				const shouldIgnoreMessage =
					unread[conversationType].includes(conversation.id) ||
					(isChatVisible && activeConversationId === conversation.id);

				if (!shouldIgnoreMessage) {
					setUnread(current => ({
						...current,
						[conversationType]: [...current[conversationType], conversation.id],
					}));
				}
			}
		},
		[activeConversationId, currentUserId, isChatVisible, unread]
	);

	useSubscribe(EventBusEvent.ChatNewMessage, chatNewMessageCallback);

	const totalUnreadCount =
		unread[ConversationType.Instant].length +
		unread[ConversationType.Execution].length +
		unread[ConversationType.KYC].length;

	return (
		<MenuItemWithIndicator
			count={totalUnreadCount}
			isLoading={false}
			isActive={isChatVisible}
			IconComponent={ChatIcon}
		/>
	);
};
