/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { H400, M100, M50 } from 'src/components/Typography/fonts';
import { useUpdateEvery } from 'src/_helpers/useUpdateEvery';
import { useLocation } from 'react-router-dom';
import { BlockNavigationLink } from 'src/containers/BlockNavigationProvider/BlockNavigationLink';
import { colorsByKind, useNotification } from './model';
import { Trans } from 'react-i18next';
import moment from 'moment';
import { useQueryClient } from 'react-query';
import { useMarket } from 'src/_routes/useActiveMarket';

export const NotificationListItem = ({ item, onNavigation }) => {
	const { created_at, read } = item;
	const {
		kind,
		Icon,
		title,
		url,
		state,
		translationKey,
		queries,
		text,
		textHighlighted,
		onClickFallback,
		textIntro,
		...info
	} = useNotification(item);

	const { pathname } = useLocation();
	const queryClient = useQueryClient();
	const [activeMarket, setActiveMarket] = useMarket();

	const onClick = useCallback(
		ev => {
			if (typeof onClickFallback === 'function') {
				onClickFallback();
			}

			if (state?.market && state.market !== activeMarket) {
				setActiveMarket(state.market);
			}

			onNavigation(ev, item);

			// if we're already at given page - just invalidate notifications
			if (pathname === url) {
				queryClient.invalidateQueries(['notifications']);
			}
			if (queries) {
				queries.forEach(query => queryClient.invalidateQueries(query));
			}
		},
		[
			pathname,
			url,
			onNavigation,
			item,
			queryClient,
			queries,
			activeMarket,
			setActiveMarket,
			state?.market,
			onClickFallback,
		]
	);

	if (kind === 'todo') {
		return null;
	}

	return (
		<li>
			<ListItemLink
				to={url ? url : '#'}
				state={state || {}}
				onClick={onClick}
				data-test={`notification-${item._key}`}
				data-read={read}
				data-type={item.type}
			>
				<IconContainer read={read} kind={kind}>
					<Icon width="22px" height="22px" />
				</IconContainer>
				<ListItemContent>
					<Title>{title}</Title>
					<div>
						{translationKey ? (
							<Description>
								<Trans i18nKey={translationKey} values={{ ...info }}>
									<HighlightedText />
								</Trans>
								{text && (
									<>
										<br />
										<Description>{text}</Description>
									</>
								)}
							</Description>
						) : (
							<>
								{textIntro && <Description>{textIntro}&nbsp;</Description>}
								{textHighlighted && <M100>{textHighlighted},&nbsp;</M100>}
								<Description>{text}</Description>
							</>
						)}
					</div>
					<CreatedAt createdAt={created_at} />
				</ListItemContent>
			</ListItemLink>
		</li>
	);
};

const Title = styled(H400)`
	margin-bottom: 4px;
`;

const Description = styled(M100)`
	color: var(--text-color-secondary);
`;

const HighlightedText = styled(M100)`
	color: #ffffff;
`;

export const CreatedAt = ({ createdAt, ...props }) => {
	useUpdateEvery(60 * 1000);

	return <CreatedAtText {...props}>{moment(createdAt).fromNow()}</CreatedAtText>;
};

const CreatedAtText = styled(M50)`
	color: var(--text-color-secondary);
`;

const IconContainer = styled.div`
	flex-shrink: 0;
	border-radius: 50%;
	color: ${({ kind }) => colorsByKind[kind]?.color};
	background-color: ${({ kind }) => colorsByKind[kind]?.background};

	${({ read }) =>
		read &&
		css`
			background-color: var(--neutral-500);
			color: var(--neutral-300);
		`}
	width: 40px;
	height: 40px;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 16px;
`;

const ListItemContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
`;

const ListItemLink = styled(BlockNavigationLink)`
	display: flex;
	padding: 12px 32px;

	&:hover {
		color: inherit;
		background-color: rgba(var(--neutral-600-rgb), 0.5);

		${Title} {
			text-decoration: underline;
		}
	}
`;
