/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getUserId } from 'src/_store/selectors';
import { ReactComponent as EyeIcon } from 'src/assets/icons/eye.svg';
import { OverflowText } from 'src/components/Tooltip/OverflowText';
import * as Styled from './styled';

export const CounterViews = ({ counter }) => {
	const { t } = useTranslation();
	const userId = useSelector(getUserId);

	const seenTime = counter.counterparty_viewed_at
		? moment(counter.counterparty_viewed_at).fromNow(true)
		: null;
	const user =
		counter.order_user_id === ''
			? null
			: counter.order_user_id === userId
			? counter.counter_order_user
			: counter.order_user;

	const counterpartySeenKey = seenTime
		? user
			? 'seen_time_ago_by_user'
			: 'seen_time_ago'
		: user
		? 'not_seen_yet_by_user'
		: 'not_seen_yet';

	return (
		<Styled.ViewCount data-test="counterparty-seen-info" $fixedWidth>
			<EyeIcon />
			<OverflowText>
				{t(counterpartySeenKey, {
					time: seenTime,
					user: `${user?.first_name} ${user?.last_name}`,
				})}
			</OverflowText>
		</Styled.ViewCount>
	);
};
