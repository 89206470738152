/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useSellerBuyerSummary } from 'src/_helpers/order';
import { useOrderDetails } from 'src/_helpers/useOrderDetails';
import { OverflowText } from 'src/components/Tooltip/OverflowText';
import { parseDateStringAsUTC, formatDateRange, getNumberOfMonths } from 'src/_helpers/date';
import { formatPrice } from 'src/_helpers/price';
import { tPriceUnit } from 'src/constants/product';
import { VolumeDisplay } from 'src/components/Volume';
import { PreAdvice, PaperInstruments, PaperPorts } from 'src/constants/contract';
import { useAllPaperPresets } from 'src/components/SearchHeader/useAllPaperPresets';
import * as Styled from './styled';

export const useAcceptOutrightSummary = (order, PrincipalSelectorComponent) => {
	const { t } = useTranslation();

	const { isSellOrder, isBasisPrice } = useOrderDetails(order);

	const { seller, buyer, areBrokersFromTheSameCompany } = useSellerBuyerSummary(order);

	const shouldShowBuyerPrincipalComponent = !!PrincipalSelectorComponent && isSellOrder;
	const shouldShowSellerPrincipalComponent = !!PrincipalSelectorComponent && !isSellOrder;

	const shouldDisplayFuturesContractDate = isBasisPrice && order.futures_contract_date;

	const isParanaguaOutright =
		order.instrument === PaperInstruments.Outright &&
		order.primary_ports?.[0]._key === PaperPorts.PARANAGUA;

	const deliveryMonths = getNumberOfMonths(order.delivery_date_from, order.delivery_date_to);

	const volumeLabel =
		order.runs || (isParanaguaOutright && deliveryMonths > 1)
			? t('total_quantity')
			: t('quantity');

	const paperPresets = useAllPaperPresets();

	const orderPreset = paperPresets.find(preset => preset._key === order.preset_id);

	return [
		{
			label: t('seller'),
			value: shouldShowSellerPrincipalComponent ? (
				PrincipalSelectorComponent
			) : (
				<Styled.CompanyNameContainer>
					<OverflowText>{seller.principal}</OverflowText>
				</Styled.CompanyNameContainer>
			),
		},
		{
			label: t('buyer'),
			value: shouldShowBuyerPrincipalComponent ? (
				PrincipalSelectorComponent
			) : (
				<Styled.CompanyNameContainer>
					<OverflowText>{buyer.principal}</OverflowText>
				</Styled.CompanyNameContainer>
			),
		},
		seller.broker && {
			label: seller.broker_label,
			value: (
				<Styled.CompanyNameContainer>
					<OverflowText>{seller.broker}</OverflowText>
				</Styled.CompanyNameContainer>
			),
		},
		buyer.broker &&
			!areBrokersFromTheSameCompany && {
				label: buyer.broker_label,
				value: (
					<Styled.CompanyNameContainer>
						<OverflowText>{buyer.broker}</OverflowText>
					</Styled.CompanyNameContainer>
				),
			},
		{
			label: t('contract'),
			value: orderPreset.name,
		},
		{
			label: volumeLabel,
			value: <VolumeDisplay as="span" value={order.volume} />,
		},
		shouldDisplayFuturesContractDate && {
			label: t('futures_contract'),
			value: `${order.futures_contract} ${moment(order.futures_contract_date).format(
				'MMM YYYY'
			)}`,
		},
		{
			label: tPriceUnit(t, order.price_unit),
			value: formatPrice(order.price),
		},
		{
			label: t('shipment'),
			value: formatDateRange({
				format: order.delivery_mode,
				startDate: parseDateStringAsUTC(order.delivery_date_from),
				endDate: parseDateStringAsUTC(order.delivery_date_to),
			}),
		},
		order.pre_advice === PreAdvice.Short && {
			label: t('pre_advice'),
			value:
				t(`pre_advice_${order.pre_advice}`) +
				' ' +
				t('days', { count: order.pre_advice_days }),
			data_test: 'pre-advice',
		},
		order.pre_advice === PreAdvice.ToSuitVessel && {
			label: t('pre_advice_to_suit_mv'),
			value: (
				<Styled.OverflowedValue>
					<OverflowText>{order.pre_advice_vessel_name}</OverflowText>
				</Styled.OverflowedValue>
			),
			data_test: 'pre-advice',
		},
	].filter(Boolean);
};
