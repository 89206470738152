/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import * as Styled from './styled';

export const CounterEditedMessage = ({ counter }) => {
	const { t } = useTranslation();

	const wasCounterEdited = !!counter.edited_from;

	if (!wasCounterEdited) {
		return null;
	}

	const time = moment(counter.created_at).fromNow(true);

	return (
		<Styled.Edited data-test="counter-edited-info">
			{t('edited_time_ago', { time })}
		</Styled.Edited>
	);
};
