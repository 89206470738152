/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled, { css, keyframes } from 'styled-components';
import { ReactComponent as PlusIconComponent } from 'src/assets/icons/plus.svg';
import { ReactComponent as MinusIconComponent } from 'src/assets/icons/minus.svg';
import { ReactComponent as ChevronIconComponent } from 'src/assets/icons/chevron16x16.svg';
import { StrokeButton as StrokeButtonComponent } from '../../../Buttons/StrokeButton.js';
import { media } from 'src/_helpers/media-queries.js';
import { H600 } from 'src/components/Typography/fonts.js';

export const SectionWrapper = styled.div`
	display: flex;
	flex-direction: column;

	& + & {
		margin-top: 16px;
	}
`;

export const SectionMeta = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 7px;
	width: 100%;
	height: 36px;

	${media.xl`
		margin-bottom: 16px;
		height: 40px;
	`}
`;

export const Title = styled(H600)`
	display: flex;
	align-items: center;
	gap: 8px;
`;

const icon = css`
	width: 14px;
	height: 14px;
	color: var(--text-color-default);
`;

export const PlusIcon = styled(PlusIconComponent)`
	${icon}
`;

export const MinusIcon = styled(MinusIconComponent)`
	${icon}
`;

export const ExpandIcon = styled(ChevronIconComponent)`
	color: var(--text-color-default);
`;

export const CollapseIcon = styled(ChevronIconComponent)`
	color: var(--text-color-default);
	transform: rotate(180deg);
`;

export const StrokeButton = styled(StrokeButtonComponent)`
	width: 40px;
	height: 40px;
	border: 1px solid var(--neutral-600);
	z-index: 2;

	&:focus {
		border: 1px solid var(--neutral-600);
	}

	&:focus:hover {
		border: 1px solid var(--primary-200);
	}
`;

export const FieldContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	gap: 12px;
	row-gap: 12px;

	${media.xl`
		gap: 16px;
		row-gap: 16px;
	`}
`;

const reveal = keyframes`
	from { opacity: 0; }
	to { opacity: 1; }
`;

export const Child = styled.div`
	animation: ${reveal} 300ms linear;

	grid-column: ${props => props.$fullWidth && `1 / span 3;`};
`;
