/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { HeaderType } from '../constants';

export const getHeaderTheme = type => {
	switch (type) {
		case HeaderType.Info:
			return {
				background: 'var(--primary-800)',
				color: 'var(--white)',
			};
		case HeaderType.Success:
			return {
				background: 'var(--green-400)',
				color: 'var(--primary-1000)',
			};
		case HeaderType.Warning:
			return {
				background: 'var(--red-400)',
				color: 'var(--white)',
			};
		case HeaderType.MyOrder:
			return {
				background:
					'linear-gradient(180deg, var(--purple-200) -552%, var(--neutral-900) 100%)',
				color: 'var(--white)',
			};
		default:
			return {
				background:
					'linear-gradient(180deg, var(--neutral-300) -552%, var(--neutral-900) 100%)',
				color: 'var(--white)',
			};
	}
};
