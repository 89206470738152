/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

export const WebsocketChannel = {
	Private: 'private', // user:userId
	Presence: 'status',
	NewExchangeOrder: 'new_exchange_order', // Note: Legacy part: channel and event type have the same names
	ExchangeUpdates: 'exchange:updates', // Note: Supports 'withdrawn_order' & 'edited_exchange_order' event types
};

const PublicWebsocketEvent = {
	NewExchangeOrder: 'new_exchange_order',
	WithdrawnOrder: 'withdrawn_order',
	EditedExchangeOrder: 'edited_exchange_order',
	NewTradePrice: 'new_trade_price',
};

const PrivateWebsocketEvent = {
	NewOtcOrder: 'new_otc_order',
	NewNotification: 'new_notification',
	NotificationsRead: 'notifications_read',
	NewCounter: 'new_counter',
	ChatNewMessage: 'chat_new_message',
	NewTrade: 'new_trade',
	ContactAdded: 'new_contact',
	ContactRemoved: 'removed_contact',
	EditedOTCOrder: 'edited_otc_order',
	WithdrawnOrder: 'withdrawn_order',
};

const ChatEvent = {
	ChatOpen: 'chat_open',
	ChatClose: 'chat_close',
	ChatToggle: 'chat_toggle',
	ChatMarkConversationsAsRead: 'chat_mark_conversations_as_read',
	ChatVisibilityStatus: 'chat_visibility_status',
	ChatUpdateActiveConversationId: 'chat_update_active_conversation_id',
};

const PlatformEvent = {
	UpdateUserPresenceList: 'update_user_presence_list',
	InviteUser: 'invite_user',
	WithdrawnOrderWithNegotiations: 'withdrawn_order_with_negotiations',
	UpdateDashboard: 'update_dashboard',
	NegotiationUpdated: 'negotiation_updated',
	OrderUpdated: 'order_updated',
	EditedOrder: 'edited_order',
	NewSidebarActivities: 'new_sidebar_activities',
	MyTabsUpdated: 'my_tabs_updated',
	MyOrdersUpdated: 'my_orders_updated',
	UpdateChart: 'update_chart',
};

export const EventBusEvent = {
	...PublicWebsocketEvent,
	...PrivateWebsocketEvent,
	...ChatEvent,
	...PlatformEvent,
};
