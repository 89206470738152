/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import moment from 'moment';
import { OrderType } from 'src/constants/contract';
import { useOrderDetails } from './useOrderDetails';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from './useCurrentUser';
import { UserRole } from 'src/constants/user';
import { OrderStatus } from 'src/constants/orderStatus';

export const getCounterpartyLabel = (t, order) => {
	const isMyOrder = order.is_my_order;
	const orderType = order.order_type;

	if (!isMyOrder) {
		return orderType === OrderType.Sell ? t('seller') : t('buyer');
	}

	return orderType !== OrderType.Sell ? t('seller') : t('buyer');
};

export const useSellerBuyerSummary = (order, counters = []) => {
	const { t } = useTranslation();
	const { isSellOrder, isBrokerOrder } = useOrderDetails(order);

	const { company: currentUserCompany, user: currentUser } = useCurrentUser();
	const currentUserCompanyName = currentUserCompany.name;
	const isBrokerUser = currentUser.role === UserRole.Broker;

	const orderWithCounters = [order, ...counters];
	const lastCounter = orderWithCounters.at(-1);

	const orderOwnerPrincipal = lastCounter.order_owner_principal;
	const orderOwnerPrincipalCompanyName = orderOwnerPrincipal.company?.name || '---';
	const isOrderOwnerPrincipalHidden = orderOwnerPrincipal.hidden;
	const orderOwnerPrincipalCompanyDisplayName = isOrderOwnerPrincipalHidden
		? t('hidden')
		: orderOwnerPrincipalCompanyName;

	const counterpartyCompanyName =
		lastCounter.counter_order_user_company?.name || currentUserCompanyName;

	const isInAcceptOrderMode = !counters.length;

	const getSellerPrincipalCompany = () => {
		if (isSellOrder) {
			return orderOwnerPrincipalCompanyDisplayName;
		}

		return isBrokerUser && isInAcceptOrderMode ? null : counterpartyCompanyName;
	};

	const getBuyerPrincipalCompany = () => {
		if (!isSellOrder) {
			return orderOwnerPrincipalCompanyDisplayName;
		}

		return isBrokerUser && isInAcceptOrderMode ? null : counterpartyCompanyName;
	};

	const getSellerBrokerCompany = () => {
		if (!isSellOrder) {
			return isBrokerUser && isInAcceptOrderMode ? currentUserCompanyName : null;
		}

		return !isBrokerOrder ? null : order.company?.name;
	};

	const getBuyerBrokerCompany = () => {
		if (isSellOrder) {
			return isBrokerUser && isInAcceptOrderMode ? currentUserCompanyName : null;
		}

		return !isBrokerOrder ? null : order.company?.name;
	};

	const sellerBrokerCompany = getSellerBrokerCompany();
	const buyerBrokerCompany = getBuyerBrokerCompany();

	const bothBrokersDefined = sellerBrokerCompany && buyerBrokerCompany;

	const areBrokersFromTheSameCompany =
		bothBrokersDefined && sellerBrokerCompany === buyerBrokerCompany;

	const getBrokerLabel = type => {
		let label = t('broker');

		if (!areBrokersFromTheSameCompany) {
			label += ` (${type})`;
		}

		return label;
	};

	const seller = {
		principal: getSellerPrincipalCompany(),
		broker: sellerBrokerCompany,
		broker_label: sellerBrokerCompany && getBrokerLabel(t('seller')),
	};

	const buyer = {
		principal: getBuyerPrincipalCompany(),
		broker: buyerBrokerCompany,
		broker_label: buyerBrokerCompany && getBrokerLabel(t('buyer')),
	};

	return {
		seller,
		buyer,
		areBrokersFromTheSameCompany,
	};
};

export const getOrderNegotiationTradeLink = (t, order = {}, isCounter) => {
	const locationOrigin = window.location.origin;

	const {
		_key: orderId,
		order_id: counterOrderId,
		environment: orderEnvironment,
		first_counter_id: negotiationId,
		trade_id: tradeId,
	} = order;

	if (isCounter) {
		if (tradeId) {
			return {
				label: t('trade_id'),
				id: tradeId,
				link: `${locationOrigin}/execution/trade/${tradeId}`,
			};
		}

		return {
			label: t('negotiation_id'),
			id: negotiationId,
			link: `${locationOrigin}/?orderEnvironment=${orderEnvironment}&orderId=${counterOrderId}&negotiationId=${negotiationId}`,
		};
	}

	return {
		label: t('order_id'),
		id: orderId,
		link: `${locationOrigin}/?orderEnvironment=${orderEnvironment}&orderId=${orderId}`,
	};
};

export const isOrderActive = order => {
	const isRowExpired = moment(new Date()).isAfter(new Date(order.validity), 'milliseconds');
	const isActiveOrder = order.status === OrderStatus.Active && !isRowExpired;

	return isActiveOrder;
};

export const getMonthlyQuantity = (months, totalQuantity, t) => {
	if (!totalQuantity) {
		return `--- ${t('mt')}`;
	}

	const value = new Intl.NumberFormat('en').format(parseFloat(totalQuantity / months).toFixed(2));

	return `${value} ${t('mt')}`;
};
