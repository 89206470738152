/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useSelector } from 'react-redux';
import { CounterStatus } from 'src/constants/counterStatus';
import { isExpired } from 'src/_helpers/date';
import { getUserId } from 'src/_store/selectors';
import { IncoType, Market } from 'src/constants/contract';
import { PKPG_PRODUCT_ID } from 'src/constants/product';

export const useCounterFooter = ({ counter, isLastCounter }) => {
	const userId = useSelector(getUserId);
	const isMyCounter = counter.user_id === userId;

	const { status: counterStatus, is_indicative: isCounterIndicative } = counter;

	const isTraded = isLastCounter && counterStatus === CounterStatus.Confirmed && counter.trade_id;

	const isNotAvailable = counterStatus === CounterStatus.NotAvailable;
	const isTerminated = counterStatus === CounterStatus.Terminated;
	const isCounterInEditableAndCounterableStatus = [
		CounterStatus.Active,
		CounterStatus.Expired,
	].includes(counterStatus);

	const canEdit = isLastCounter && isCounterInEditableAndCounterableStatus && isMyCounter;

	const canCounter =
		isLastCounter && !isMyCounter && !isTraded && isCounterInEditableAndCounterableStatus;

	const canAccept =
		isLastCounter &&
		!isMyCounter &&
		!isCounterIndicative &&
		!isExpired(counter.validity) &&
		!isTerminated &&
		!isNotAvailable &&
		!isTraded;

	const canFirmUp = isCounterIndicative && canCounter;

	const isRenduCounter = isLastCounter && counter.inco_id === IncoType.RENDU;
	const isPKPGOrder =
		isLastCounter && counter.product_id === PKPG_PRODUCT_ID && counter.market === Market.Paper;

	return {
		canAccept,
		canCounter,
		canFirmUp,
		isTraded,
		isNotAvailable,
		isPKPGOrder,
		isRenduCounter,
		canEdit,
	};
};
