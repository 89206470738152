/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { isValidElement } from 'react';
import styled from 'styled-components';
import { t100, h600 } from 'src/components/Typography';
import { OverflowText } from 'src/components/Tooltip/OverflowText';

export const SummaryRow = ({ title, value, dataTest }) => (
	<Row>
		<Title>{title}</Title>
		<Value {...(dataTest ? { 'data-test': dataTest } : {})}>
			{isValidElement(value) ? value : <OverflowText>{value}</OverflowText>}
		</Value>
	</Row>
);

const Row = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 16px;
`;

const Title = styled.div`
	color: var(--neutral-300);
	${t100}
`;

const Value = styled.div`
	color: var(--text-color-default);
	max-width: 250px;
	${h600}
`;
