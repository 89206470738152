/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useTradeString } from 'src/_api/queries';
import { adminBlockDocumentBypass } from 'src/_api/trades.api';
import { useToast } from 'src/components/Toast';
import { TextInput } from 'src/designSystem/Form/TextInput/TextInput';
import { mapIdsToArray, getListOfValidTradeIds } from './helpers';
import * as Styled from './styled';

export const AdminPaperInitialString = ({ tradeId }) => {
	const [tradeIds, setTradeIds] = useState('');

	const { addToast } = useToast();
	const queryClient = useQueryClient();

	const { data = [] } = useTradeString(
		{
			trade_id: tradeId,
		},
		{
			onSuccess: data => {
				const allTradeIds = getListOfValidTradeIds(data);
				setTradeIds(allTradeIds.join(','));
			},
		}
	);

	const handleInputChange = e => setTradeIds(e.target.value);

	const { mutate } = useMutation(adminBlockDocumentBypass, {
		onSuccess: async () => {
			await queryClient.invalidateQueries(['trade', tradeId]);
			await queryClient.invalidateQueries(['trade-string', tradeId]);
		},
		onError: error => {
			addToast({
				kind: 'error',
				message: error.message,
			});
		},
	});

	const allTradesDefined = mapIdsToArray(tradeIds).length === data.length - 1;

	const handleButtonClick = () => {
		mutate(mapIdsToArray(tradeIds));
	};

	return (
		<div>
			<Styled.Header>Please enter all trade ids</Styled.Header>
			<TextInput value={tradeIds} onChange={handleInputChange} />
			<Styled.PrimaryButton
				fixedPadding
				data-test="button-move-initial-string"
				onClick={handleButtonClick}
				disabled={!allTradesDefined}
			>
				Block document bypass
			</Styled.PrimaryButton>
			{!allTradesDefined && (
				<Styled.Error>Please provide all {data.length - 1} trade ids</Styled.Error>
			)}
		</div>
	);
};
