/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { CalendarSpreadPayment } from 'src/constants/contract';
import { formatFloatNumberToPriceFormat } from './price';

// Used for mapping form values when creating orders and counters
export const calculateSpreadPrice = (isBuyer, spreadPayment, settlementPriceAbsoluteValue) => {
	const sign = isBuyer === (spreadPayment === CalendarSpreadPayment.Pay) ? 1 : -1;

	return sign * settlementPriceAbsoluteValue;
};

// Price coming here might be positive, negative or 0
export const calculatePayCashDirection = (isBuyer, price) => {
	if (isPriceZero(price)) {
		return CalendarSpreadPayment.Even;
	}

	return isBuyer === price > 0 ? CalendarSpreadPayment.Pay : CalendarSpreadPayment.Cash;
};

export const isPriceZero = price => parseFloat(price) === 0;

export const legShipmentLabel = (legNumber = 0) => {
	return legNumber === 0 ? 'first_leg_shipment' : 'second_leg_shipment';
};

export const flatBasisSpreadPriceFormat = (price, isBasisPrice, currencyAndUnit, futuresCode) => {
	const isPriceDefined = price !== undefined && price !== '';
	const displayPrice = isPriceDefined ? formatFloatNumberToPriceFormat(parseFloat(price)) : '---';

	if (!isBasisPrice) {
		return `${displayPrice} ${currencyAndUnit}`;
	}

	return `${displayPrice} ${futuresCode}`;
};
