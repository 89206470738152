/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, {
	createContext,
	useContext,
	useState,
	useCallback,
	useEffect,
	useMemo,
	useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useMutation, useQueryClient } from 'react-query';
import { useActiveMarket } from 'src/_routes/useActiveMarket';
import { editMultipleOrders } from 'src/_api/orders.api';
import { useToast } from 'src/components/Toast';
import { ChangesLimit, FieldsApiMap, FieldApiValuesMap, FieldNames } from './constants';
import { mapValidityOptionToISOString } from './helpers';
import { useBlockNavigationContext } from 'src/containers/BlockNavigationProvider/BlockNavigationProvider';
import { QuoteType, SpreadPriceFormat } from 'src/constants/contract';
import { isDotSeparatedString } from 'src/_helpers/string';
import { mapPathToNestedObject } from 'src/_helpers/object';
import { isLegPriceInRange } from 'src/constants/priceValidation';

const EditOrdersContext = createContext({
	isEditMode: false,
	canEdit: true,
	setCanEdit: () => {},
	isSubmittingChanges: false,
	canChange: () => true,
	hasErrors: false,
	changes: {},
	getChanges: orderId => {},
	getMeta: orderId => {},
	submitEditChanges: () => {},
	setEditMode: () => {},
	changeOrder: (orderId, fieldName, value) => {},
	registerDefaultValue: (orderId, fieldName, value) => {},
	registerOrderMeta: (orderId, value) => {},
	justEditedOrderIds: [],
});

export const EditOrdersProvider = ({ children }) => {
	const [isEditMode, internalSetEditMode] = useState(false);
	const [changes, setChanges] = useState(new Map());
	const [defaultValues, setDefaultValues] = useState(new Map());
	const [orderMeta, setOrderMeta] = useState(new Map());
	const [justEditedOrderIds, setJustEditedOrderIds] = useState([]);
	const [canEdit, setCanEdit] = useState(false);

	const hideJustEditedTimeoutRef = useRef();

	const { t } = useTranslation();

	const queryClient = useQueryClient();

	const { addToast } = useToast();

	const market = useActiveMarket();

	const { pathname } = useLocation();

	const { blockNavigation, isNavigationBlocked, unblockNavigation } = useBlockNavigationContext();

	useEffect(() => {
		if (justEditedOrderIds.length && !hideJustEditedTimeoutRef.current) {
			hideJustEditedTimeoutRef.current = setTimeout(() => {
				hideJustEditedTimeoutRef.current = null;
				setJustEditedOrderIds([]);
			}, 3000);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [justEditedOrderIds.length, hideJustEditedTimeoutRef.current]);

	const updateJustEditedOrderIds = list => {
		if (hideJustEditedTimeoutRef.current) {
			clearTimeout(hideJustEditedTimeoutRef.current);
			hideJustEditedTimeoutRef.current = null;
		}
		setJustEditedOrderIds(list);
	};

	// Exit edit mode when the market or pathname changes
	useEffect(() => {
		setEditMode(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [market, pathname]);

	const setEditMode = useCallback(
		state => {
			if (state === false && changes.size) {
				setChanges(new Map());
				internalSetEditMode(state);
			} else {
				if (state) {
					setJustEditedOrderIds([]);
				}
				internalSetEditMode(state);
			}
		},
		[changes.size]
	);

	useEffect(() => {
		if (isEditMode) {
			if (!isNavigationBlocked && changes.size) {
				blockNavigation('edit_orders', () => {
					setEditMode(false);
				});
			}
		}
	}, [blockNavigation, changes.size, isEditMode, isNavigationBlocked, setEditMode]);

	const { mutate, isLoading } = useMutation(editMultipleOrders, {
		onSettled: async (result, error, params) => {
			const orderCount = params.length;
			const updatedCount = result?.edited_orders_number;

			setEditMode(false);
			unblockNavigation();

			if (Array.isArray(result?.edited_orders_ids)) {
				setJustEditedOrderIds(result?.edited_orders_ids);
			}

			if (error || updatedCount === 0) {
				addToast({
					message: t('order_update_error', { count: orderCount }),
					kind: 'error',
				});
			} else if (updatedCount === orderCount) {
				addToast({
					message: t('order_has_been_updated', { count: orderCount }),
					kind: 'info',
				});
			} else if (updatedCount) {
				addToast({
					message: t('some_orders_were_updated', {
						successCount: updatedCount,
						totalCount: orderCount,
						errorCount: orderCount - updatedCount,
					}),
					kind: 'warning',
				});
			}

			await queryClient.invalidateQueries(['my-orders']);
		},
	});

	const registerDefaultValue = useCallback((orderId, fieldName, value) => {
		setDefaultValues(prevState => {
			const newState = new Map(prevState);
			const orderValue = {
				...(newState.get(orderId) || {}),
				[fieldName]: value,
			};
			newState.set(orderId, orderValue);
			return newState;
		});
	}, []);

	const registerOrderMeta = useCallback((orderId, meta) => {
		setOrderMeta(prevState => {
			const newState = new Map(prevState);
			newState.set(orderId, meta);
			return newState;
		});
	}, []);

	const changeOrder = useCallback(
		(orderId, fieldName, value) => {
			const defaultValue = defaultValues.get(orderId)?.[fieldName];

			setChanges(prevState => {
				const newState = new Map(prevState);
				const orderValue = {
					...(newState.get(orderId) || {}),
				};

				if (defaultValue !== value) {
					orderValue[fieldName] = value;
					newState.set(orderId, orderValue);
				} else {
					delete orderValue[fieldName];

					if (Object.keys(orderValue).length === 0) {
						newState.delete(orderId);
					} else {
						newState.set(orderId, orderValue);
					}
				}
				return newState;
			});
		},
		[defaultValues]
	);

	const getChanges = useCallback(
		(orderId, fieldName) => {
			if (fieldName) {
				return changes.get(orderId)?.[fieldName];
			}

			return changes.get(orderId);
		},
		[changes]
	);

	const getMeta = useCallback(
		orderId => {
			return orderMeta.get(orderId);
		},
		[orderMeta]
	);

	const submitEditChanges = useCallback(() => {
		const request = Array.from(changes).map(([orderId, orderChanges]) => {
			const meta = orderMeta.get(orderId);
			const res = {
				order_id: orderId,
				version: meta.version,
				status: meta.status,
				update_mask: [],
			};

			Object.keys(orderChanges).forEach(key => {
				let value = orderChanges[key];
				const fieldName = FieldsApiMap[key];
				const fieldValueMap = FieldApiValuesMap[key];

				if (
					key === FieldNames.Price &&
					meta.spreadPriceFormat === SpreadPriceFormat.PayCash &&
					meta.negativePrice
				) {
					value = -1 * value;
				}

				res.update_mask.push(fieldName);

				if (isDotSeparatedString(fieldName)) {
					const valueToBeSent = mapPathToNestedObject(fieldName, value);

					Object.assign(res, valueToBeSent);
				} else if (fieldValueMap) {
					res[fieldName] =
						typeof fieldValueMap === 'function'
							? fieldValueMap(value)
							: fieldValueMap[value];
				} else {
					res[fieldName] = value;
				}
			});

			res.validity = mapValidityOptionToISOString(orderChanges[FieldNames.Validity]);
			res.update_mask.push('validity');

			return res;
		});

		mutate(request);
	}, [changes, mutate, orderMeta]);

	const canChange = useCallback(
		orderId => {
			const hasLimitExceeded = changes.size >= ChangesLimit;
			const isOrderAlreadyEdited = !!changes.get(orderId);

			return hasLimitExceeded ? isOrderAlreadyEdited : true;
		},
		[changes]
	);

	const hasErrors = useMemo(() => {
		let hasIncorrectValue = false;

		changes.forEach((values, orderId) => {
			const orderDefaultValues = defaultValues.get(orderId);

			const nullValues = Object.entries(values).filter(([key, value]) => {
				return value === null && orderDefaultValues[key];
			});

			const meta = orderMeta.get(orderId);

			const quoteType = values.quote_type || orderDefaultValues?.quote_type;
			const firstLegPriceValue = values[FieldNames.FirstLegPrice];
			const firstLegPrice =
				firstLegPriceValue || orderDefaultValues?.[FieldNames.FirstLegPrice];
			const volume = values.volume || orderDefaultValues?.volume;
			const runs = values.runs || orderDefaultValues?.runs;

			const runsError = meta?.hasRunsSupport && quoteType === QuoteType.Firm && !runs;

			const payCashError =
				meta?.spreadPriceFormat === SpreadPriceFormat.PayCash && values.price === 0;

			const firmPayCashSpreadError =
				quoteType === QuoteType.Firm &&
				meta?.spreadPriceFormat === SpreadPriceFormat.PayCash &&
				(!volume || !firstLegPrice);

			const firmStandardSpreadError =
				quoteType === QuoteType.Firm &&
				meta?.spreadPriceFormat === SpreadPriceFormat.Spread &&
				(!runs || !firstLegPrice);

			const firmOutrightError =
				quoteType === QuoteType.Firm && !meta?.hasRunsSupport && !volume;

			const firstLegPriceError =
				firstLegPriceValue &&
				!isLegPriceInRange({ price_type: meta?.priceType }, firstLegPriceValue);

			if (
				nullValues.length ||
				firmPayCashSpreadError ||
				firmStandardSpreadError ||
				firmOutrightError ||
				runsError ||
				payCashError ||
				firstLegPriceError
			) {
				hasIncorrectValue = true;
			}
		});

		return hasIncorrectValue;
	}, [changes, orderMeta, defaultValues]);

	return (
		<EditOrdersContext.Provider
			value={{
				canChange,
				changes,
				changeOrder,
				registerDefaultValue,
				registerOrderMeta,
				submitEditChanges,
				isEditMode,
				setEditMode,
				getChanges,
				getMeta,
				hasErrors,
				isSubmittingChanges: isLoading,
				justEditedOrderIds,
				canEdit,
				setCanEdit,
				updateJustEditedOrderIds,
			}}
		>
			{children}
		</EditOrdersContext.Provider>
	);
};

export const useEditOrdersContext = () => useContext(EditOrdersContext);
