/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
const { useViewOrderDrawerContext } = require('../ViewOrderDrawerProvider');
const { isExpired, formatDistance } = require('src/_helpers/date');
const { OrderStatus } = require('src/constants/orderStatus');
const { CounterStatus } = require('src/constants/counterStatus');

export const useOrderFooterExpirationDate = (order, negotiations, t) => {
	const { hasOrderExpired } = useViewOrderDrawerContext();

	const { status, validity } = order;

	const isOrderExpired = isExpired(validity) || hasOrderExpired || status === OrderStatus.Expired;

	const isLastCounterConfirmed =
		negotiations.at(-1) && negotiations.at(-1).status === CounterStatus.Confirmed;

	if (status === OrderStatus.Terminated || isLastCounterConfirmed) {
		return { label: '', value: '' };
	}

	if (status === OrderStatus.NotAvailable) {
		return {
			label: t('valid_for'),
			value: '---',
		};
	}

	if (isOrderExpired) {
		return {
			label: t('expired'),
			value: formatDistance({
				t,
				startDate: new Date(),
				endDate: validity,
				fullText: true,
				ago: true,
			}),
		};
	}

	return {
		label: t('valid_for'),
		value: formatDistance({ t, startDate: new Date(), endDate: validity, fullText: true }),
	};
};
