/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { formatValidity, isExpired, formatDistance } from 'src/_helpers/date';
import { DrawerFooter } from 'src/components/Drawer/Drawer';
import { Tooltip, TooltipContent, TooltipTrigger } from '../../Tooltip/Tooltip';
import { useCounterFooter } from './useCounterFooter';
import { CounterStatus } from 'src/constants/counterStatus';
import { TradedCounterButtons } from './TradedCounterButtons';
import { CounterButtons } from './CounterButtons';
import { ActionButtons } from '../components/common';
import { useUpdateAtTime } from 'src/_helpers/useUpdateAtTime';
import { H400 } from 'src/components/Typography';
import * as Styled from 'src/components/ViewOrderDrawer/styled';

export const NegotiationViewFooter = ({
	isLastCounter,
	counter,
	order,
	negotiation,
	setCounterForm,
}) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	const { isTraded } = useCounterFooter({
		counter,
		isLastCounter,
	});

	const isOriginalOrder = counter._key === order._key;

	const buttonsProps = {
		order,
		counter,
		isLastCounter,
		setCounterForm,
		negotiation,
	};

	const isCounterExpired =
		isExpired(negotiation.validity) || counter.status === CounterStatus.Expired;

	const counterValidity = formatDistance({
		t,
		startDate: new Date(),
		endDate: negotiation.validity,
		fullText: true,
		ago: !!isCounterExpired,
	});

	const hasJustExpired = useUpdateAtTime(negotiation.validity);

	useEffect(() => {
		if (hasJustExpired) {
			queryClient.invalidateQueries(['order_with_counters', order._key, negotiation._key]);
		}
	}, [hasJustExpired, queryClient, order._key, negotiation._key]);

	return (
		<DrawerFooter>
			<Styled.SectionGridContent>
				<>
					{isOriginalOrder
						? null
						: isLastCounter &&
						  [CounterStatus.Active, CounterStatus.Expired].includes(
								counter.status
						  ) && (
								<>
									<Styled.SectionGridLabel>
										{isCounterExpired ? t('expired') : t('valid_for')}
									</Styled.SectionGridLabel>
									<H400 data-test="valid-for">
										<Tooltip>
											<TooltipTrigger>{counterValidity}</TooltipTrigger>
											<TooltipContent>
												{formatValidity(counter.validity, t)}
											</TooltipContent>
										</Tooltip>
									</H400>
								</>
						  )}
				</>
			</Styled.SectionGridContent>
			<ActionButtons>
				{isTraded ? (
					<TradedCounterButtons {...buttonsProps} />
				) : (
					<CounterButtons {...buttonsProps} />
				)}
			</ActionButtons>
		</DrawerFooter>
	);
};
