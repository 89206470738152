/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectWithTooltip } from 'src/designSystem/Form/SelectWithTooltip/SelectWithTooltip';
import { MaterialMultiSelectInput } from 'src/designSystem/Form/MaterialInput/MaterialMultiSelectInput';
import * as Styled from './styled';

export const PrincipalSelectorDropdown = ({ options, principalId, setPrincipalId, required }) => {
	const { t } = useTranslation();

	useEffect(() => {
		return () => setPrincipalId(null);
	}, [setPrincipalId]);

	const label = required ? `${t('principal')} *` : t('principal');

	return (
		<Styled.Wrapper className="principal-selector-dropdown">
			{/* MaterialMultiSelectInput is used here as a wrapper; there is not multi selection here */}
			<MaterialMultiSelectInput
				selectComponent={SelectWithTooltip}
				placeholder={label}
				label={label}
				onChange={setPrincipalId}
				id="principal-selector"
				options={options}
				value={principalId}
				clearable
			/>
		</Styled.Wrapper>
	);
};
