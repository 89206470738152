/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import { media } from 'src/_helpers';
import styled from 'styled-components';

export const ViewsWrapper = styled.div`
	margin-top: 12px;
	display: grid;
	grid-template-columns: 1fr minmax(153px, auto);
	grid-gap: 18px;
	width: 468px;

	${media.ll`
		width: 520px;
	`}

	${media.xl`
		width: 602px;
	`};

	${({ $isVisible }) =>
		$isVisible &&
		`
		margin-top: 10px;
		margin-bottom: 8px;
		height: 18px;
  `}
`;

export const Edited = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
	font-size: 12px;
	line-height: 18px;
	color: var(--text-color-secondary);
`;

export const ViewCount = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
	font-size: 12px;
	line-height: 18px;
	color: var(--text-color-secondary);
	overflow: hidden;

	& svg {
		flex-shrink: 0;
	}

	${Edited} {
		&:before {
			content: '•';
		}
	}
`;

export const PayCashInfo = styled.div`
	color: var(--neutral-300);
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
	text-align: right;
`;

export const LeftSideInfo = styled.div`
	display: flex;
	overflow: hidden;
	gap: 4px;
	color: var(--text-color-secondary);

	& > div {
		white-space: nowrap;

		&:not(:last-child) {
			flex-shrink: 1;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		&:last-child {
			flex-shrink: 0;
		}
	}
`;
