/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { Fragment, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useTradeString } from 'src/_api/queries';
import { adminCreateFinalString } from 'src/_api/trades.api';
import { useToast } from 'src/components/Toast';
import { TextInput } from 'src/designSystem/Form/TextInput/TextInput';
import { TextArea } from 'src/designSystem/Form/TextArea/TextArea';
import { mapIdsToArray, getListOfValidTradeIds } from './helpers';
import * as Styled from './styled';

export const AdminPaperFinalString = ({ tradeId }) => {
	const [tradeIds, setTradeIds] = useState('');
	const [tradesConfirmed, setTradesConfirmed] = useState(false);
	const [sellerSettlement, setSellerSettlement] = useState({});
	const [buyerSettlement, setBuyerSettlement] = useState({});

	const { addToast } = useToast();
	const queryClient = useQueryClient();

	const { data = [] } = useTradeString(
		{
			trade_id: tradeId,
		},
		{
			onSuccess: data => {
				const allTradeIds = getListOfValidTradeIds(data);
				setTradeIds(allTradeIds.join(','));
			},
		}
	);

	const { mutate } = useMutation(adminCreateFinalString, {
		onSuccess: async () => {
			await queryClient.invalidateQueries(['trade', tradeId]);
			await queryClient.invalidateQueries(['trade-string', tradeId]);
		},
		onError: error => {
			addToast({
				kind: 'error',
				message: error.message,
			});
		},
	});

	const tradeIdsArray = mapIdsToArray(tradeIds);
	const allTradesDefined = tradeIdsArray.length === data.length - 1;

	const updateSellerSettlement = (value, id) => {
		setSellerSettlement(oldValue => ({
			...oldValue,
			[id]: value,
		}));
	};

	const updateBuyerSettlement = (value, id) => {
		setBuyerSettlement(oldValue => ({
			...oldValue,
			[id]: value,
		}));
	};

	const handleButtonClick = () => {
		const strings = tradeIdsArray.map(id => ({
			trade_id: id,
			seller_settlement_suggestions_cn: sellerSettlement[id] || '',
			seller_settlement_suggestions_en: sellerSettlement[id] || '',
			buyer_settlement_suggestions_cn: buyerSettlement[id] || '',
			buyer_settlement_suggestions_en: buyerSettlement[id] || '',
		}));

		mutate(strings);
	};

	const revealSettlementInfo = () => setTradesConfirmed(true);

	const handleInputChange = e => setTradeIds(e.target.value);

	return (
		<div>
			{allTradesDefined && tradesConfirmed ? (
				<>
					<Styled.Header>Define settlement info</Styled.Header>
					{tradeIdsArray.map((id, index) => (
						<Fragment key={id}>
							<Styled.Header>Trade #{id}</Styled.Header>
							{index === 0 && (
								<>
									<Styled.Info>Seller settlement</Styled.Info>
									<TextArea
										value={sellerSettlement[id]}
										onChange={e => updateSellerSettlement(e.target.value, id)}
									/>
								</>
							)}
							<Styled.Info>Buyer settlement</Styled.Info>
							<TextArea
								value={buyerSettlement[id]}
								onChange={e => updateBuyerSettlement(e.target.value, id)}
							/>
						</Fragment>
					))}
					<Styled.PrimaryButton
						fixedPadding
						data-test="button-move-final-string"
						onClick={handleButtonClick}
					>
						Run
					</Styled.PrimaryButton>
				</>
			) : (
				<>
					<Styled.Header>Please enter all trade ids</Styled.Header>
					<TextInput value={tradeIds} onChange={handleInputChange} />
					<Styled.PrimaryButton
						fixedPadding
						onClick={revealSettlementInfo}
						disabled={!allTradesDefined}
					>
						OK
					</Styled.PrimaryButton>
				</>
			)}
		</div>
	);
};
