/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { DrawerHeader } from 'src/components/Drawer/DrawerHeader/DrawerHeader';
import { useViewOrderDrawerContext } from '../ViewOrderDrawerProvider';
import { HeaderType } from 'src/components/Drawer/constants';

export const NegotiationViewHeader = ({
	headerType,
	headerMessage,
	headerSubtitle,
	order,
	negotiation,
	isLastCounter,
}) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	const {
		newNegotiationActivities,
		setOrderAndNegotiationUpToDate,
		counterEdited,
	} = useViewOrderDrawerContext();

	const onRefreshCallback = async () => {
		setOrderAndNegotiationUpToDate();
		await queryClient.invalidateQueries([
			'order_with_counters',
			order._key,
			negotiation.first_counter_id,
		]);
		await queryClient.invalidateQueries(['my-unseen-negotiations']);
		await queryClient.invalidateQueries(['order', order._key]);
	};

	if (isLastCounter && counterEdited) {
		return (
			<DrawerHeader
				type={HeaderType.Info}
				message={t('counter_was_updated')}
				onRefresh={onRefreshCallback}
			/>
		);
	}

	if (newNegotiationActivities) {
		return (
			<DrawerHeader
				type={HeaderType.Info}
				message={t('negotiation_was_updated')}
				onRefresh={onRefreshCallback}
			/>
		);
	}

	return <DrawerHeader type={headerType} message={headerMessage} subtitle={headerSubtitle} />;
};
