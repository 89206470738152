/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useState, useEffect, useCallback } from 'react';
import clsx from 'clsx';
import { PeriodHeader } from '../Header';
import {
	parseDate,
	isOutOfRange,
	generate12MonthsFromDate,
	getNextYear,
	getPrevYear,
	getYearsFromArray,
	isDateInThePast,
	getInitialMonthDisplayed,
} from './helpers';
import * as Styled from './styled';

export const classNames = {
	start: 'selected selected__0',
	middle: 'selected selected__1',
	end: 'selected selected__2',
	blocked: 'blocked',
};

export const MonthRange = ({
	startDate,
	endDate,
	onDatesChange,
	cutOffDate,
	isMonthDisabled,
	ResetIcon,
	allowPastDates,
	allowMultipleMonths = true,
	shouldResetSelection = false,
}) => {
	const [firstMonthDisplayed, setFirstMonthDisplayed] = useState(
		getInitialMonthDisplayed(startDate)
	);
	const months = generate12MonthsFromDate(firstMonthDisplayed);

	const [selectingStartingMonth, setSelectingStartingMonth] = useState(true);

	const [startMonth, setStartMonth] = useState(parseDate(startDate));
	const [endMonth, setEndMonth] = useState(parseDate(endDate));

	const getClassNames = useCallback(
		month => {
			return clsx({
				[classNames.start]: startMonth != null && startMonth.value === month.value,
				[classNames.middle]:
					startMonth != null &&
					endMonth != null &&
					startMonth.value < month.value &&
					endMonth.value > month.value,
				[classNames.end]: endMonth != null && endMonth.value === month.value,
				[classNames.blocked]:
					(allowPastDates === false && isDateInThePast(month.date)) ||
					isMonthDisabled?.(month.date) ||
					isOutOfRange(month.value, cutOffDate),
			});
		},
		[startMonth, endMonth, allowPastDates, isMonthDisabled, cutOffDate]
	);

	const handlePrevButtonClick = useCallback(
		() => setFirstMonthDisplayed(date => getPrevYear(date)),
		[]
	);

	const handleNextButtonClick = useCallback(
		() => setFirstMonthDisplayed(date => getNextYear(date)),
		[]
	);

	const handleClear = useCallback(() => {
		setEndMonth(null);
		setSelectingStartingMonth(true);
		setStartMonth(null);
		onDatesChange({
			startDate: null,
			endDate: null,
		});
	}, [setEndMonth, setSelectingStartingMonth, setStartMonth, onDatesChange]);

	useEffect(() => {
		if (shouldResetSelection) {
			handleClear();
		}
	}, [handleClear, shouldResetSelection]);

	useEffect(() => {
		if (startMonth != null && endMonth != null) {
			const start = startMonth.date.clone().utc(0).startOf('month');
			const end = endMonth.date.clone().utc(0).endOf('month');

			onDatesChange({
				startDate: start,
				endDate: end,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [startMonth, endMonth]);

	const selectMonth = useCallback(
		month => {
			if (selectingStartingMonth) {
				setStartMonth(month);
				setEndMonth(month);
				if (allowMultipleMonths) {
					setSelectingStartingMonth(!selectingStartingMonth);
				}
			} else if (month.value >= startMonth.value) {
				setEndMonth(month);
				if (allowMultipleMonths) {
					setSelectingStartingMonth(!selectingStartingMonth);
				}
			} else {
				setStartMonth(month);
			}
		},
		[startMonth, allowMultipleMonths, selectingStartingMonth]
	);

	const title = getYearsFromArray(months);

	return (
		<>
			<PeriodHeader
				startDate={startDate?.format('MMM YYYY')}
				endDate={endDate?.format('MMM YYYY')}
				onClear={handleClear}
				ResetIcon={ResetIcon}
			/>
			<Styled.Section className="months-range">
				<Styled.Nav>
					<Styled.NavPrevButton onClick={handlePrevButtonClick} />
					<p>{title}</p>
					<Styled.NavNextButton onClick={handleNextButtonClick} />
				</Styled.Nav>
				<Styled.MonthContainer>
					{months.map(month => (
						<Styled.MonthButton
							type="button"
							key={month.value}
							onClick={() => selectMonth(month)}
							className={getClassNames(month)}
						>
							{month.label}
						</Styled.MonthButton>
					))}
				</Styled.MonthContainer>
			</Styled.Section>
		</>
	);
};
