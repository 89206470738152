/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useCreatePhysicalOrderForm } from './useCreatePhysicalOrderForm';
import { DrawerContextKeys, useDrawerContext } from '../../Drawer/DrawerContext';
import { QuoteSection } from './sections/QuoteSection/QuoteSection';
import { CreateOrderDrawerFooter } from '../CreateOrderDrawerFooter';
import { SpecificationsSection } from './sections/SpecificationsSection/SpecificationsSection';
import { ShipmentSection } from './sections/ShipmentSection/ShipmentSection';
import { AdditionalInfoSection } from './sections/AdditionalInfoSection/AdditionalInfoSection';
import { PricingSection } from './sections/PricingSection/PricingSection';
import { SectionSeparator } from '../components/SectionSeparator/SectionSeparator';
import { OrderPreviewContent } from '../components/OrderPreview/OrderPreviewContent';
import { OrderPreviewFooter } from '../components/OrderPreview/OrderPreviewFooter';
import * as Styled from '../styled';
import clsx from 'clsx';

export const CreatePhysicalOrderDrawerContent = ({ formValues }) => {
	const { formMethods, isSubmitting, onSubmit, progress } = useCreatePhysicalOrderForm(
		formValues
	);

	const navigate = useNavigate();

	const {
		[DrawerContextKeys.createDrawer]: {
			orderPreviewData,
			visibility: [, { close }],
		},
	} = useDrawerContext();

	const isPreviewMode = !!orderPreviewData;

	const handleCloseEditedOrder = () => {
		close();
		navigate(`/?orderEnvironment=${formValues.environment}&orderId=${formValues._key}`);
	};

	return (
		<FormProvider {...formMethods}>
			<Styled.Form onSubmit={onSubmit}>
				<Styled.FormContent className={clsx({ preview: isPreviewMode })}>
					{isPreviewMode ? (
						<OrderPreviewContent />
					) : (
						<>
							<QuoteSection />
							<SectionSeparator />
							<SpecificationsSection />
							<SectionSeparator />
							<PricingSection />
							<SectionSeparator />
							<ShipmentSection />
							<SectionSeparator />
							<AdditionalInfoSection />
						</>
					)}
				</Styled.FormContent>
				{isPreviewMode ? (
					<OrderPreviewFooter onSubmit={onSubmit} isSubmitting={isSubmitting} />
				) : (
					<CreateOrderDrawerFooter
						onSubmit={onSubmit}
						progress={progress}
						onCancel={handleCloseEditedOrder}
						isSubmitting={isSubmitting}
					/>
				)}
			</Styled.Form>
		</FormProvider>
	);
};
