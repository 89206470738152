/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { media } from 'src/_helpers/media-queries';
import { h600, t100 } from 'src/components/Typography';
import { NameContainer } from 'src/components/UserStatus/UserStatus';
import { ReactComponent as Info } from 'src/assets/icons/info.svg';

export const Name = styled.span`
	${h600}
	padding-top: 0px;

	${media.xl`
		padding-top: 5px;
	`}

	&:hover {
		color: currentColor;
	}

	${({ $noTopPadding }) => $noTopPadding && `padding-top: 0 !important;`}
`;

export const UserInfo = styled.div`
	${t100}
	color: var(--neutral-300);
	display: flex;
	align-items: center;
`;

export const CompanyCountryCode = styled.div`
	&::before {
		content: '•';
		margin-right: 4px;
	}
`;

export const UserDetails = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-height: 52px;
	min-height: 34px;
	margin-left: 10px;
	max-width: 154px;
	gap: 1px;

	${media.ll`
		max-width: 178px;
	`}

	${media.xl`
		max-width: 201px;
		max-height: 62px;
		margin-left: 12px;
		gap: 4px;
	`}
`;

export const UserDetailsContainer = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
`;

export const UserNameContainer = styled.div`
	max-width: 124px;

	${media.ll`
		max-width: 150px;
	`}

	${media.xl`
		max-width: 168px;
	`}

	&:hover {
		color: var(--neutral-200);
	}
`;

export const CompanyContainer = styled(NameContainer)`
	&.user-company-container {
		gap: 4px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
`;

export const InfoIcon = styled(Info)`
	width: 20px;
	height: 20px;
	color: var(--neutral-300);
	flex-shrink: 0;
`;

export const PrincipalUser = styled.div`
	display: flex;
	gap: 4px;
	width: 100%;

	.content {
		max-width: 90px;

		${media.ll`
			max-width: 110px;
		`}

		${media.xl`
			max-width: 130px;
		`}
	}

	${({ $fullWidth }) =>
		$fullWidth &&
		`
			.content {
				max-width: 100%;
			}
	`}

	.separator {
		max-width: 4px;
	}
`;
