/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import { CounterStatus } from 'src/constants/counterStatus';
import { OrderStatus } from 'src/constants/orderStatus';
import { Environment } from 'src/constants/contract';
import { isExpired } from 'src/_helpers/date';
import { useViewOrderDrawerContext } from '../ViewOrderDrawerProvider';

// Note: Counterparty can have only one negotiation within an order, but passed as an array for the consistency with owner negotiations
export const useCounterpartyViewOrderFooterButtons = (order, negotiations = []) => {
	const { hasOrderExpired, orderEdited, orderWithdrawn } = useViewOrderDrawerContext();
	const { validity, _key: orderId, environment, status, is_indicative } = order;

	const negotiation = negotiations[0] || null;

	const isOrderExpired = isExpired(validity) || hasOrderExpired || status === OrderStatus.Expired;

	const canAccept =
		!is_indicative &&
		![OrderStatus.Terminated, OrderStatus.NotAvailable].includes(status) &&
		!isOrderExpired &&
		(!negotiation || negotiation?.status !== CounterStatus.Confirmed);

	const canCounter =
		!negotiation &&
		!isOrderExpired &&
		![OrderStatus.Terminated, OrderStatus.NotAvailable].includes(status);

	const canFirmUp = canCounter && is_indicative;

	const goToCounterLink = negotiation
		? `/?orderEnvironment=${environment}&orderId=${orderId}&negotiationId=${negotiation.first_counter_id}`
		: null;

	const hasTradedNegotiationInOTC =
		environment === Environment.OTC && negotiation?.status === CounterStatus.Confirmed;

	return {
		goToCounterLink,
		canCounter,
		canAccept,
		canFirmUp,
		hasOrderUpdated: orderEdited || orderWithdrawn,
		hasTradedNegotiationInOTC,
	};
};
