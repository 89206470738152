/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useEmit } from 'src/shared/useEmit';
import { OTCButton } from './OTCButton';
import { ROUTES } from 'src/constants/routes';
import { EventBusEvent } from 'src/components/EventBus/events';
import { MessageButton } from '../UserRowActions/MessageButton';
import { SecondaryButton } from 'src/components/Buttons/SecondaryButton';
import { RequestKYCButton } from 'src/containers/KYC/components/RequestKYCButton/RequestKYCButton';
import { AddToContactsButton } from '../UserRowActions/AddToContactsButton';
import { AcceptInvitationButton } from '../UserRowActions/AcceptInvitationButton';
import { UserType } from 'src/constants/userNetwork';
import { useMixedNavigate } from 'src/shared/useAppNavigate';
import * as Styled from './styled';

export const UserProfileActions = ({ user, type, onButtonClick, canShowOTC, close }) => {
	const { t } = useTranslation();
	const navigate = useMixedNavigate();

	const emit = useEmit();

	const handleEditProfile = () => {
		close();
		onButtonClick?.();
		emit(EventBusEvent.ChatClose);
		navigate(ROUTES.myProfileEdit);
	};

	return (
		<Styled.Actions data-test="actions">
			<Styled.ButtonsContainer>
				{type !== UserType.Me && (
					<OTCButton
						userIds={[user.id]}
						onClick={onButtonClick}
						disabled={!canShowOTC}
						close={close}
					/>
				)}
				<RequestKYCButton
					responderUserId={user.id}
					responderUserCompanyId={user.companyId}
					counterStyle
					close={close}
				/>
			</Styled.ButtonsContainer>
			<Styled.ButtonsContainer>
				{type === UserType.Me ? (
					<SecondaryButton darker onClick={handleEditProfile}>
						{t('edit_profile')}
					</SecondaryButton>
				) : type === UserType.Contacts ? (
					<MessageButton userId={user.id} onClick={onButtonClick} className="message" />
				) : user.invitationSent ? (
					<Styled.InvitationSpan data-test="pending-invitation-button">
						{t('pending')}...
					</Styled.InvitationSpan>
				) : user.invitationReceived ? (
					<AcceptInvitationButton
						inviteId={user.inviteId}
						userId={user.id}
						onClick={onButtonClick}
					/>
				) : (
					<AddToContactsButton userId={user.id} onAddToContactsClick={onButtonClick} />
				)}
			</Styled.ButtonsContainer>
		</Styled.Actions>
	);
};
