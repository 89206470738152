/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { MaterialDateInput } from 'src/designSystem/Form/MaterialInput/MaterialDateInput';
import { MaterialSelectInput } from 'src/designSystem/Form/MaterialInput/MaterialSelectInput';
import { getShipmentCutOffDate, getNumberOfMonths, Dateformat } from 'src/_helpers/date';
import { useWatchPaperFields } from '../../../useWatchPaperFields';
import { MaterialNumberInput } from 'src/designSystem/Form/MaterialInput/MaterialNumberInput';
import { tCurrency, QuoteType } from 'src/constants/contract';
import { useOutrightSection } from './useOutrightSection';
import { toPath } from 'src/_helpers';
import * as Styled from '../styled';

export const OutrightRow = ({ path }) => {
	const { t } = useTranslation();

	const { setValue, trigger } = useFormContext();

	const { priceValue, orderTypeValue, deliveryValue } = useWatchPaperFields(path);

	const { quote, priceType, futuresMonth, currency, unit, meta } = useOutrightSection({ path });

	const {
		isBasisPriceTypeSelected,
		shouldDisplayPriceType,
		shouldDisplayQuantityField,
		shouldDisplayFuturesMonths,
		hasRunsSupport,
	} = meta;

	const formattedCurrencyUnit = (
		<>
			<Styled.CurrencyUnitSeparator />
			<span>{`${tCurrency(t, currency)}/${t(`price_unit_short_${unit}`, {
				lng: 'en',
			})}`}</span>
		</>
	);

	const deliveryMonths = deliveryValue.startDate
		? getNumberOfMonths(deliveryValue.startDate, deliveryValue.endDate)
		: 1;

	const volumeLabel = deliveryMonths > 1 ? t('total_quantity') : t('quantity');

	return (
		<>
			<Controller
				mandatory
				name={toPath(path, 'type')}
				render={({ field, fieldState: { error } }) => (
					<MaterialSelectInput
						required
						error={!!error}
						data-test={field.name}
						label={t('quote')}
						options={quote.options}
						disabled={!quote.ready}
						{...field}
					/>
				)}
			/>
			<Controller
				mandatory
				name={toPath(path, 'delivery')}
				defaultValue={null}
				render={({ field, fieldState: { error } }) => (
					<Styled.CalendarWrapper>
						<MaterialDateInput
							{...field}
							required
							id={field.name}
							data-test={field.name}
							label={t('shipment')}
							error={!!error}
							cutOffDate={getShipmentCutOffDate()}
							onChange={value => {
								field.onChange(value);
								setValue('shipmentChanged', true);
							}}
							enableDaysSelection={meta.allowExactDelivery}
							enableQuarterPeriodSelection={hasRunsSupport}
							{...(hasRunsSupport && { defaultDateFormat: Dateformat.Quarters })}
						/>
					</Styled.CalendarWrapper>
				)}
			/>
			{shouldDisplayQuantityField && (
				<Controller
					mandatory={orderTypeValue === QuoteType.Firm}
					name={toPath(path, 'volume')}
					render={({ field, fieldState: { error } }) => (
						<MaterialNumberInput
							required={orderTypeValue === QuoteType.Firm}
							error={!!error}
							data-test={field.name}
							id={field.name}
							label={volumeLabel}
							fixedDecimalScale={false}
							parameters={t('price_unit_short_metric_ton')}
							isAllowed={value => value.floatValue !== 0}
							{...field}
						/>
					)}
				/>
			)}

			<Controller
				name={toPath(path, 'price')}
				mandatory
				render={({ field, fieldState: { error } }) => (
					<MaterialNumberInput
						error={!!error}
						data-test="priceCurrencyUnit"
						label={t('price')}
						required
						allowNegative={isBasisPriceTypeSelected}
						parameters={formattedCurrencyUnit}
						decimalScale={isBasisPriceTypeSelected ? 3 : 2}
						{...field}
						onChange={value => {
							field.onChange(value);
							if (value !== undefined) {
								trigger(toPath(path, 'price'));
							}
						}}
					/>
				)}
			/>
			{hasRunsSupport && (
				<Controller
					mandatory={orderTypeValue === QuoteType.Firm}
					name={toPath(path, 'runs')}
					defaultValue={null}
					render={({ field, fieldState: { error } }) => (
						<MaterialNumberInput
							{...field}
							required={orderTypeValue === QuoteType.Firm}
							id={field.name}
							data-test={field.name}
							label={t('runs')}
							error={!!error}
							fixedDecimalScale
							decimalScale={0}
							allowNegative={false}
							isAllowed={({ formattedValue }) => formattedValue !== '0'}
						/>
					)}
				/>
			)}
			{shouldDisplayPriceType && (
				<Controller
					name={toPath(path, 'priceType')}
					mandatory
					render={({ field, fieldState: { error } }) => (
						<MaterialSelectInput
							error={!!error}
							data-test={field.name}
							label={t('price_type')}
							options={priceType.options}
							disabled={!priceType.ready}
							required
							{...field}
							onChange={value => {
								field.onChange(value);
								if (priceValue !== undefined) {
									trigger(toPath(path, 'price'));
								}
							}}
						/>
					)}
				/>
			)}
			{shouldDisplayFuturesMonths && (
				<Controller
					name={toPath(path, 'futuresMonth')}
					mandatory
					render={({ field, fieldState: { error } }) => (
						<MaterialSelectInput
							required
							error={!!error}
							data-test={field.name}
							label={t('futures_month')}
							options={futuresMonth.options}
							disabled={!futuresMonth.ready}
							{...field}
						/>
					)}
				/>
			)}
		</>
	);
};
