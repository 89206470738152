/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNegotiationView } from './useNegotiationView';
import { SecondaryButton } from 'src/components/Buttons/SecondaryButton';
import { OrderType, PaperInstruments } from 'src/constants/contract';
import { ViewOrderDrawerContent } from '../ViewOrderDrawerContent';
import { NegotiationViewFooter } from './NegotiationViewFooter';
import { CounterStatus } from 'src/constants/counterStatus';
import { NegotiationViewHeader } from './NegotiationViewHeader';
import { ViewOrderDrawerSkeleton } from '../ViewOrderDrawerSkeleton/ViewOrderDrawerSkeleton';
import { useOrderDetails } from 'src/_helpers/useOrderDetails';
import * as Styled from './styled';

export const NegotiationView = ({ order, negotiation, setCounterForm, activeNegotiationID }) => {
	const { t } = useTranslation();

	const {
		hasPreviousCounter,
		setPreviousCounter,
		hasNextCounter,
		setNextCounter,
		counterIndex,
		currentCounter,
		hasActiveCounters,
		counterChanges,
		headerType,
		headerMessage,
		headerSubtitle,
		isViewNotReady,
		countersLength,
	} = useNegotiationView({
		orderID: order._key,
		environment: order.environment,
		activeNegotiationID,
	});

	const isNegotiationTraded = negotiation.status === CounterStatus.Confirmed;

	const { isFirmOrder } = useOrderDetails(order);

	const shouldSkipNavigationButtons = isNegotiationTraded && isFirmOrder && countersLength === 1;

	if (isViewNotReady) {
		return <ViewOrderDrawerSkeleton />;
	}

	const previousButtonMessage =
		counterIndex === 1
			? currentCounter.instrument === PaperInstruments.Spread
				? t('original_spread')
				: currentCounter.order_type === OrderType.Buy
				? t('original_bid')
				: t('original_offer')
			: isNegotiationTraded && !hasNextCounter
			? t('negotiations')
			: `${t('counter')} #${counterIndex - 1}`;

	const getNextButtonMessage = counterIndex => {
		if (isNegotiationTraded && counterIndex === countersLength - 1) {
			return t('trade');
		}

		return `${t('counter')} #${counterIndex + 1}`;
	};

	return (
		<>
			<NegotiationViewHeader
				headerType={headerType}
				headerMessage={headerMessage}
				headerSubtitle={headerSubtitle}
				order={order}
				negotiation={negotiation}
				isLastCounter={!hasNextCounter}
			/>
			<Styled.Negotiation>
				<ViewOrderDrawerContent
					order={currentCounter}
					changes={counterChanges}
					isLastCounter={!hasNextCounter}
				/>
				{!shouldSkipNavigationButtons && (
					<Styled.Buttons>
						{hasPreviousCounter ? (
							<SecondaryButton darker onClick={setPreviousCounter}>
								<Styled.ArrowLeft />
								{previousButtonMessage}
							</SecondaryButton>
						) : (
							<div className="button_placeholder" />
						)}
						{hasNextCounter ? (
							<SecondaryButton darker onClick={setNextCounter}>
								{getNextButtonMessage(counterIndex)}
								<Styled.ArrowRight />
							</SecondaryButton>
						) : (
							<div className="button_placeholder" />
						)}
					</Styled.Buttons>
				)}
			</Styled.Negotiation>
			<NegotiationViewFooter
				isLastCounter={!hasNextCounter}
				hasActiveCounters={hasActiveCounters}
				counter={currentCounter}
				negotiation={negotiation}
				order={order}
				setCounterForm={setCounterForm}
			/>
		</>
	);
};
