/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { DrawerContextKeys, useDrawerContext } from 'src/components/Drawer/DrawerContext';
import { OrderCreatorShipmentSection } from 'src/components/ViewOrderDrawer/Content/OrderCreatorShipmentSection/OrderCreatorShipmentSection';
import { PaperKeyDetailsSection } from 'src/components/ViewOrderDrawer/Content/KeyDetailsSection/PaperKeyDetailsSection';
import { ContractAndPricingSection } from 'src/components/ViewOrderDrawer/Content/Paper/ContractAndPricingSection/ContractAndPricingSection';
import { PaperSpecification } from '../../paper/sections/ContractAndPricingSection/PaperSpecification/PaperSpecification';
import { CounterpartiesSection } from 'src/components/ViewOrderDrawer/Content/CounterpartiesSection/CounterpartiesSection';
import { PaperInstruments } from 'src/constants/contract';
import { AdditionalInformationSection } from 'src/components/ViewOrderDrawer/Content/Paper/AdditionalInformationSection/AdditionalInformationSection';
import * as Styled from 'src/components/ViewOrderDrawer/styled';

export const PaperOrderPreviewContent = () => {
	const {
		[DrawerContextKeys.createDrawer]: { orderPreviewData, orderPreviewIndex },
	} = useDrawerContext();

	const order = orderPreviewData[orderPreviewIndex];

	return (
		<>
			<Styled.TopContent $isPreviewMode>
				<PaperKeyDetailsSection order={order} />
			</Styled.TopContent>
			<Styled.ScrollableContent>
				<OrderCreatorShipmentSection order={order} skipShipmentType />
				<ContractAndPricingSection order={order} />
				{!!order.recipients_list?.length && (
					<>
						<Styled.Divider />
						<CounterpartiesSection order={order} showSeenBy={false} />
					</>
				)}
				{order.instrument !== PaperInstruments.Spread && (
					<>
						{order.additional_terms?.[0] && (
							<>
								<Styled.Divider />
								<AdditionalInformationSection
									order={order}
									showCreationDate={false}
								/>
							</>
						)}
					</>
				)}
				<Styled.Divider />
				<PaperSpecification specs={order.grade?.gradespecs || null} />
			</Styled.ScrollableContent>
		</>
	);
};
