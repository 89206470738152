/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getUserId } from 'src/_store/selectors';
import { tLegQuote, OrderType } from 'src/constants/contract';
import { getSpreadMultiMonths } from 'src/_helpers/date';
import * as Styled from '../styled';

export const MonthSpreadPill = ({ order }) => {
	const currentUserId = useSelector(getUserId);
	const { t } = useTranslation();

	const isMyOrder = order.order_user_id === currentUserId;
	const isTraded = !!order.trade_id;

	const [firstLegtMonth, secondLegMonth] = getSpreadMultiMonths(
		order.firstLegMonth,
		order.secondLegMonth
	);

	let firstLegType = order.counter_order_type || order.order_type;

	if (isTraded) {
		if (isMyOrder) {
			firstLegType = order.order_type;
		} else {
			firstLegType = order.order_type === OrderType.Buy ? OrderType.Sell : OrderType.Buy;
		}
	}

	const firstLegQuote = tLegQuote(t, firstLegType);
	const secondLegQuote =
		firstLegType === OrderType.Buy ? tLegQuote(t, OrderType.Sell) : tLegQuote(t, OrderType.Buy);

	return (
		<Styled.SpreadPill data-test="month-spread-pill">
			<div data-test="first-leg">
				<span>{firstLegQuote}</span>
				<span>{firstLegtMonth}</span>
			</div>
			<div className="separator">×</div>
			<div data-test="second-leg">
				<span>{secondLegQuote}</span>
				<span>{secondLegMonth}</span>
			</div>
		</Styled.SpreadPill>
	);
};
