/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useSelector } from 'react-redux';
import { getUserId } from 'src/_store/selectors';
import { OrderViews } from './OrderViews';
import { CounterViews } from './CounterViews';
import { OrderEditedMessage } from './OrderEditedMessage';
import { SpreadPriceFormat } from 'src/constants/contract';
import { PayCashInfo } from './PayCashInfo';
import { CounterEditedMessage } from './CounterEditedMessage';
import * as Styled from './styled';

export const TopBarInfo = ({ order, isCounter, isLastCounter, isTraded }) => {
	const userId = useSelector(getUserId);

	const isPreview = order.is_order_preview || order.is_counter_preview;

	const shouldShowOrderAnalytics = !isPreview && (order.is_my_order || order.is_my_team_order);
	const isMyCounter = order.user_id === userId;
	const shouldShowCounterAnalytics =
		!isPreview && isCounter && isLastCounter && isMyCounter && !isTraded;

	const isEdited = !!order.version && order.version !== 1;

	const isPayCash = order.spread_details?.price_format === SpreadPriceFormat.PayCash;

	const EditedContent = () => (isEdited ? <OrderEditedMessage order={order} /> : null);

	const shouldShowCounterEdited = order.edited_from && isLastCounter && !isTraded;

	return (
		<Styled.ViewsWrapper
			$isVisible={
				shouldShowOrderAnalytics ||
				shouldShowCounterAnalytics ||
				shouldShowCounterEdited ||
				isEdited ||
				isPayCash
			}
		>
			<Styled.LeftSideInfo>
				{shouldShowOrderAnalytics ? (
					<OrderViews order={order}>
						<EditedContent />
					</OrderViews>
				) : (
					<EditedContent />
				)}
				{shouldShowCounterAnalytics && (
					<>
						<CounterViews counter={order} />
						{shouldShowCounterEdited && (
							<>
								• <CounterEditedMessage counter={order} />
							</>
						)}
					</>
				)}
			</Styled.LeftSideInfo>
			{isPayCash && <PayCashInfo order={order} />}
		</Styled.ViewsWrapper>
	);
};
