/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { Fragment, forwardRef } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Menu } from '@headlessui/react';
import { OverflowText } from 'src/components/Tooltip/OverflowText';
import { TextInput } from '../TextInput/TextInput';
import * as Styled from './styled';

export const SelectWithTooltip = ({
	options = [],
	error = false,
	value = null,
	onChange = () => {},
	dataTest,
	OptionsWrapper = Styled.OptionsWrapper,
	clearable,
}) => {
	const { t } = useTranslation();
	const canBeCleared = clearable && !!value;

	const handleClear = event => {
		onChange('');
		event.stopPropagation();
	};

	return (
		<Styled.Wrapper data-test={dataTest} className={clsx({ clearable: canBeCleared })}>
			<Menu as={Styled.ContentWrapper} className="content-wrapper">
				{({ open }) => {
					return (
						<>
							<Menu.Button
								as={forwardRef((props, ref) => {
									return (
										<Styled.InputWrapper {...props}>
											<TextInput
												className="combobox-text-input"
												error={error}
												ref={ref}
											/>

											{value && (
												<>
													<label>
														<OverflowText placement="top">
															{options.find(
																option => option.value === value
															)?.text || ''}
														</OverflowText>
													</label>
													{canBeCleared && (
														<Styled.ClearIcon onClick={handleClear} />
													)}
												</>
											)}
											{!value && (
												<Styled.ExpandIcon
													className={clsx('expand-icon', { open })}
												/>
											)}
										</Styled.InputWrapper>
									);
								})}
							/>
							<OptionsWrapper className="options-container">
								<Menu.Items className="options">
									{options.length === 0 && (
										<Menu.Item as={Styled.DisabledItem} disabled>
											{t('no_results_found')}
										</Menu.Item>
									)}
									{options.map((option, index) => (
										<Menu.Item
											as={Fragment}
											key={`option-${index}`}
											disabled={option.disabled}
										>
											{() => {
												return (
													<Styled.OptionWrapper
														className={clsx({
															selected: option.value === value,
														})}
														data-test={`option-${index}`}
														onClick={() => onChange(option.value)}
													>
														<OverflowText>
															<span>{option.text}</span>
														</OverflowText>
													</Styled.OptionWrapper>
												);
											}}
										</Menu.Item>
									))}
								</Menu.Items>
							</OptionsWrapper>
						</>
					);
				}}
			</Menu>
		</Styled.Wrapper>
	);
};
